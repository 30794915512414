import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { MetaHelmet, Sidebar } from "../../components";
import { metaSEO } from "../../../../services";

const Dashboard = () => {
    const location = useLocation();
    const id = location.pathname.startsWith('/dashboard') ? 'dashboard' : 'donation';
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getMeta = metaSEO(lang);
    const path = location.pathname;
    const meta = getMeta.find(item => path === item.url);

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        };
    }, []);

    return (
        <>
            {id === 'donation' && (
                <MetaHelmet
                    url={meta.url}
                    title={meta.title}
                    description={meta.description}
                    keywords={meta.keywords}
                    image={meta.image}
                />
            )}
            <main style={{ position: 'relative' }}>
                <Sidebar id={id} />
                <Outlet />
            </main>
        </>
    )
}

export default Dashboard;