import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AppRouter } from './config';
import { AuthProvider, RoleProvider, TokenProvider, getUserLocation } from './services';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './global.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

let root;
const renderApp = () => {
  if (!root) {
    root = ReactDOM.createRoot(document.getElementById('root'));
  }
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <HelmetProvider>
          <TokenProvider>
            <AuthProvider>
              <RoleProvider>
                <Routes>
                  <Route path="/*" element={<AppRouter />} />
                </Routes>
              </RoleProvider>
            </AuthProvider>
          </TokenProvider>
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
  reportWebVitals();
};

(async () => {
  const language = sessionStorage.getItem('gfdp-lang');
  if (!language) {
    const userLocation = await getUserLocation();
    const newLang = userLocation === 'ID' ? 'id' : 'en';
    sessionStorage.setItem("gfdp-lang", newLang);
    renderApp();
  }
  renderApp();
})();