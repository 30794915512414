import style from './button.module.scss';

const Transparent = ({ type, children, onClick, className }) => {
  return (
    <button type={type} className={`${style.transparent} ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Transparent;