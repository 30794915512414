import { checkJwt } from "../../../services";

export async function login(email, password, remember) {
    const token = await checkJwt();
    const formRequest = {
        email: email,
        password: password
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/oauth`, {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: new URLSearchParams(formRequest)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const user = responseData.result[0];

        if (!responseData.authenticated) {
            return { authenticated: responseData.authenticated, role: null };
        }
    
        if (!['volunteer', 'donor'].includes(user.privilege)) {
            return { authenticated: responseData.authenticated, role: null };
        }
    
        storeAuth(user, remember);
        authDispatchEvents();
    
        return { authenticated: responseData.authenticated, role: user.privilege };
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export function logout() {
    // Cookies.remove('varcookie');
    removeSessionData('gfdp-login');
    removeSessionData('gfdp-privilege');
    removeSessionData('gfdp-profile');
    removeSessionData('gfdp-first');
    removeSessionData('gfdp-badges');
    authDispatchEvents();
}

export function authDispatchEvents() {
    const authChangeEvent = new Event('auth-change');
    const roleChangeEvent = new Event('role-change');
    window.dispatchEvent(authChangeEvent);
    window.dispatchEvent(roleChangeEvent);
}

function removeSessionData(key) {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
}

function storeAuth(user, remember) {
    const storage = remember ? localStorage : sessionStorage;
    storage.setItem('gfdp-login', true);
    storage.setItem('gfdp-privilege', user.privilege);
    storage.setItem('gfdp-profile', JSON.stringify(user.profile[0]));
    storage.setItem('gfdp-first', user.update_password);
}