import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useRole } from '../../services';

const RequireRole = ({allowedRole}) => {
    const { role } = useRole();
    const location = useLocation();

    return (
        allowedRole === role ?
            <Outlet />
            :
            <Navigate to="/oops/401" state={{ from: location }} replace />
    );
}

export default RequireRole;