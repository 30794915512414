import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { contentTranslate } from '../../services';
import { ButtonPrimary, Input, Check, signup } from '../../features';
import style from './volunteer-signup.module.scss';

const VolunteerSignup = () => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        gender: '',
        dob: '',
        state: '',
        city: '',
        job: '',
        institution: '',
        hobby: '',
        affiliation: '',
        motivation: '',
        instagram: ''
    });
    const [isFirstnameValid, setIsFirstnameValid] = useState(null);
    const [isLastnameValid, setIsLastnameValid] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [isPhoneValid, setIsPhoneValid] = useState(null);
    const [isSexEmpty, setIsSexEmpty] = useState(null);
    const [isAgreeChecked, setIsAgreeChecked] = useState(null);
    const [invalidFirstnameMsg, setInvalidFirstnameMsg] = useState('');
    const [invalidLastnameMsg, setInvalidLastnameMsg] = useState('');
    const [invalidEmailMsg, setInvalidEmailMsg] = useState('');
    const [invalidPhoneMsg, setInvalidPhoneMsg] = useState('');
    const [emptySexMsg, setEmptySexMsg] = useState('');
    const [uncheckedMsg, setUncheckedMsg] = useState('');
    const [check, setCheck] = useState(false);
    const getContent = contentTranslate(lang);
    const volunteer = getContent.volunteer;

    const handleLangChangeEvent = useCallback(() => {
        const newLang = sessionStorage.getItem('gfdp-lang');
        setLang(newLang);
    }, []);

    useEffect(() => {
        const loader = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            clearTimeout(loader);
        };
    }, [isLoading, handleLangChangeEvent]);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleFirstname = (event) => {
        const firstname = event.target.value;
        setForm({ ...form, first_name: firstname });
        if (firstname === '') {
            setIsFirstnameValid(false);
            return;
        }
        setIsFirstnameValid(true);
    };

    const handleLastname = (event) => {
        const lastname = event.target.value;
        setForm({ ...form, last_name: lastname });
        if (lastname === '') {
            setIsLastnameValid(false);
            return;
        }
        setIsLastnameValid(true);
    };

    const handleEmail = (event) => {
        const email = event.target.value;
        setForm({ ...form, email: email });
        if (email === '') {
            setIsEmailValid(false);
            setInvalidEmailMsg(volunteer.form.invalid.emptyEmail);
            return;
        }
        if (validateEmail(email)) {
            setIsEmailValid(true);
            return;
        }
        setIsEmailValid(false);
        setInvalidEmailMsg(volunteer.form.invalid.invalidEmail);
    };

    const handlePhone = (event) => {
        const phone = event.target.value;
        setForm({ ...form, phone: phone });
        if (phone === '') {
            setIsPhoneValid(false);
            return;
        }
        setIsPhoneValid(true);
        setInvalidPhoneMsg('');
    };

    const handleSex = (event) => {
        const sex = event.target.value;
        setForm({ ...form, gender: sex });
        if (sex === '') {
            setIsSexEmpty(true);
            return;
        }
        setIsSexEmpty(false);
    };

    const handleCheck = () => {
        setCheck(!check);
        const agree = !check;
        if (agree) {
            setIsAgreeChecked(true);
            return;
        }
        setIsAgreeChecked(false);
        setUncheckedMsg(volunteer.form.invalid.agree);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isFirstnameValid && isLastnameValid && isEmailValid && isPhoneValid && !isSexEmpty && isAgreeChecked) {
            setIsLoading(true);
            try {
                const success = await signup(form);
                if (success) {
                    navigate('/volunteer/registered', { replace: true });
                    return;
                }
                navigate('/oops', { replace: true });
            } catch (error) {
                navigate('/oops/500', { replace: true });
            } finally {
                setIsLoading(false);
            }
            return;
        }
        if (isFirstnameValid === null) {
            setIsFirstnameValid(false);
            setInvalidFirstnameMsg(volunteer.form.invalid.firstname);
        }
        if (isLastnameValid === null) {
            setIsLastnameValid(false);
            setInvalidLastnameMsg(volunteer.form.invalid.lastname);
        }
        if (isEmailValid === null) {
            setIsEmailValid(false);
            setInvalidEmailMsg(volunteer.form.invalid.emptyEmail);
        }
        if (isPhoneValid === null) {
            setIsPhoneValid(false);
            setInvalidPhoneMsg(volunteer.form.invalid.phone);
        }
        if (isSexEmpty === null) {
            setIsSexEmpty(true);
            setEmptySexMsg(volunteer.form.invalid.sex);
        }
        if (isAgreeChecked === null) {
            setIsAgreeChecked(false);
            setUncheckedMsg(volunteer.form.invalid.agree);
        }
    };

    return (
        <div className={`base-gap ${style.signup}`}>
            <h4>{volunteer.form.title}</h4>
            <form className={style['volunteer-form']} onSubmit={handleSubmit} noValidate>
                <div className={style['form-section']}>
                    <h6>{volunteer.form.sectionTitle_1}</h6>
                    <div className={style['input-wrapper']}>
                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="firstname">{volunteer.form.label.firstname}</label>
                                <Input type="text" id="firstname" name="firstname" value={form.first_name} className={`form-control ${isFirstnameValid === false ? 'is-invalid' : ''}`} placeholder={volunteer.form.placeholder.firstname} onChange={handleFirstname} />
                                <span className="invalid-feedback">{invalidFirstnameMsg}</span>
                            </div>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="lastname">{volunteer.form.label.lastname}</label>
                                <Input type="text" id="lastname" name="lastname" value={form.last_name} className={`form-control ${isLastnameValid === false ? 'is-invalid' : ''}`} placeholder={volunteer.form.placeholder.lastname} onChange={handleLastname} />
                                <span className="invalid-feedback">{invalidLastnameMsg}</span>
                            </div>
                        </div>
                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="email">{volunteer.form.label.email}</label>
                                <Input type="email" id="email" name="email" value={form.email} className={`form-control ${isEmailValid === false ? 'is-invalid' : ''}`} placeholder={volunteer.form.placeholder.email} onChange={handleEmail} />
                                <span className="invalid-feedback">{invalidEmailMsg}</span>
                            </div>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="phone">{volunteer.form.label.phone}</label>
                                <div className="input-group">
                                    <span className={`input-group-text ${style.custom}`}>+62</span>
                                    <Input type="number" id="phone" name="phone" value={form.phone} className={`form-control ${style['input-other']} ${isPhoneValid === false ? 'is-invalid' : ''}`} placeholder={volunteer.form.placeholder.phone} onChange={handlePhone} />
                                    {invalidPhoneMsg !== '' ? (
                                        <span className="invalid-feedback">{invalidPhoneMsg}</span>
                                    ) : (
                                        <span className={`text-muted ${style.note}`}>{volunteer.form.note}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style['form-section']}>
                    <h6>{volunteer.form.sectionTitle_2}</h6>
                    <div className={style['input-wrapper']}>
                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="selectsex">{volunteer.form.label.sex}</label>
                                <select className={`form-select form-control ${style['custom-select']} ${isSexEmpty === true ? 'is-invalid' : ''}`} id="selectsex" name="selectsex" value={form.gender} onChange={handleSex}>
                                    <option value={''}>{volunteer.form.placeholder.sex}</option>
                                    <option value={'Laki-laki'}>{volunteer.form.placeholder.m}</option>
                                    <option value={'Perempuan'}>{volunteer.form.placeholder.f}</option>
                                </select>
                                <span className="invalid-feedback">{emptySexMsg}</span>
                            </div>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="birthdate">{volunteer.form.label.birth}</label>
                                <Input type="date" id="birthdate" name="birthdate" className={`form-control`} placeholder={volunteer.form.placeholder.birth} value={form.dob} onChange={e => setForm({ ...form, dob: e.target.value })} />
                            </div>
                        </div>

                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="province">{volunteer.form.label.province}</label>
                                <Input type="text" id="province" name="province" className={`form-control`} placeholder={volunteer.form.placeholder.province} value={form.state} onChange={e => setForm({ ...form, state: e.target.value })} />
                            </div>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="city">{volunteer.form.label.city}</label>
                                <Input type="text" id="city" name="city" className={`form-control`} placeholder={volunteer.form.placeholder.city}
                                    value={form.city} onChange={e => setForm({ ...form, city: e.target.value })} />
                            </div>
                        </div>

                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="occupation">{volunteer.form.label.occupation}</label>
                                <Input type="text" id="occupation" name="occupation" className={`form-control`} placeholder={volunteer.form.placeholder.occupation} value={form.job} onChange={e => setForm({ ...form, job: e.target.value })} />
                            </div>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="institution">{volunteer.form.label.institution}</label>
                                <Input type="text" id="institution" name="institution" className={`form-control`} placeholder={volunteer.form.placeholder.institution} value={form.institution} onChange={e => setForm({ ...form, institution: e.target.value })} />
                            </div>
                        </div>

                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="hobby">{volunteer.form.label.hobby}</label>
                                <Input type="text" id="hobby" name="hobby" className={`form-control`} placeholder={volunteer.form.placeholder.hobby} value={form.hobby} onChange={e => setForm({ ...form, hobby: e.target.value })} />
                            </div>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="source">{volunteer.form.label.source}</label>
                                <Input type="text" id="source" name="source" className={`form-control`} placeholder={volunteer.form.placeholder.source} value={form.affiliation} onChange={e => setForm({ ...form, affiliation: e.target.value })} />
                            </div>
                        </div>

                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="motivation">{volunteer.form.label.motivation}</label>
                                <Input type="text" id="motivation" name="motivation" className={`form-control`} placeholder={volunteer.form.placeholder.motivation} value={form.motivation} onChange={e => setForm({ ...form, motivation: e.target.value })} />
                            </div>
                        </div>

                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <div className={`form-check ${style.check}`}>
                                    <Check id="confirm" name="confirm" type="checkbox" className={`form-check-input ${style['check-custom']} ${isAgreeChecked === false ? 'is-invalid' : ''}`} onChange={handleCheck} checked={check} />
                                    <label className="form-check-label" htmlFor="confirm">{volunteer.form.label.confirm}</label>
                                    <span className="invalid-feedback">{uncheckedMsg}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style['form-section']}>
                    <h6>{volunteer.form.sectionTitle_3}</h6>
                    <div className={style['input-wrapper']}>
                        <div className={style['input-section']}>
                            <div className={style['input-group']}>
                                <label className="form-label" htmlFor="instagram">{volunteer.form.label.instagram}</label>
                                <Input type="text" id="instagram" name="instagram" className={`form-control`} placeholder={volunteer.form.placeholder.instagram} value={form.instagram} onChange={e => setForm({ ...form, instagram: e.target.value })} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style['button-group']}>
                    <Link to='/volunteer' className={style['button-secondary']}>{volunteer.form.button.cancel}</Link>
                    <ButtonPrimary type="submit" className={`${isLoading ? 'temp-disabled' : ''}`}>
                        {isLoading ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            volunteer.form.button.signup
                        )}
                    </ButtonPrimary>
                </div>
            </form>
        </div>
    );
}

export default VolunteerSignup;