import { createContext, useState, useContext, useEffect } from 'react';
import { getProfile } from '../../features';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(getProfile());

    useEffect(() => {
        const handleAuthChange = () => {
            setAuth(getProfile());
        };
        window.addEventListener('auth-change', handleAuthChange);
        return () => {
            window.removeEventListener('auth-change', handleAuthChange);
        };
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}