import { useEffect, useState } from 'react';
import { contentTranslate, accentSVG } from '../../../../services';
import style from './contact.module.scss';

const Contact = ({ className }) => {
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const getAccent = accentSVG();

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };
    
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        };
    }, []);

    return (
        <div className={`${style['contacts-wrapper']} ${className}`}>
            <div className={style.header}>
                <h2>{getText.landing.contacts.title}</h2>
                <p>{getText.landing.contacts.subtitle}</p>
            </div>
            <div className={style['button-group']}>
                <a href="https://wa.me/6285173310223" target="_blank" rel="noreferrer"><span>{getAccent.contact.whatsapp}</span>WhatsApp</a>
                <a href="mailto:donorcare@greeneration.org"><span>{getAccent.contact.email}</span>Email</a>
            </div>
        </div>
    );
};

export default Contact;