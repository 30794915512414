import { checkJwt, getRecurring } from "../../../services";

export async function updateProfile(form, userType) {
    const token = await checkJwt();

    try {
        const formData = new FormData();

        if (form.avatar) {
            const newAva = await uploadAvatar(form.avatar, userType);
            formData.append('avatar', newAva);
        }

        appendIfDefined(formData, 'private_information', form.private_information);
        appendIfDefined(formData, 'optional_information', form.optional_information);

        if (userType !== 'donor') {
            formData.append('fullname', form.first_name + ' ' + form.last_name);
        }

        for (const key in form) {
            if (key === 'avatar' || key === 'private_information' || key === 'optional_information' || (userType !== 'donor' && key === 'fullname')) {
                continue;
            }
            formData.append(key, form[key]);
        }

        const endpoint = userType === 'donor' ? 'update' : 'vupdate';
        const response = await sendProfileUpdateRequest(formData, token, endpoint);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const newProfile = buildNewProfile(userType, responseData);

        updateSessionProfile(JSON.stringify(newProfile));

        return response.ok;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export async function updatePassword(password) {
    const token = await checkJwt();
    const updateRequest = {
        email: getProfile().email,
        password: password
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/oauth`, {
            method: 'PUT',
            headers: {
                'Authorization': token
            },
            body: new URLSearchParams(updateRequest)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        if (responseData.result) {
            updateSessionFirst(JSON.stringify(true));
        }

        return response.ok;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export async function updateBankAccount(form) {
    const token = await checkJwt();
    const formData = new FormData();
    formData.append('bank_account', JSON.stringify(form.bank_account));
    formData.append('email', getProfile().email);

    try {
        const endpoint = 'vupdate';
        const response = await sendProfileUpdateRequest(formData, token, endpoint);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();

        const newProfile = {
            ...getProfile(),
            bank_account: responseData.result.data.bank_account,
        };

        updateSessionProfile(JSON.stringify(newProfile));
        return response.ok;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export async function updateRecurring(amount) {
    const token = await checkJwt();
    const email = getProfile().email;
    const recurring = await getRecurring(email);

    const updateRequest = {
        email: email,
        payment_method: recurring.payment_method,
        id: recurring.pg_id,
        amount: amount
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/donations`, {
            method: 'PUT',
            headers: {
                'Authorization': token
            },
            body: new URLSearchParams(updateRequest)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.ok;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export async function updateReferral(referral) {
    const token = await checkJwt();
    const email = getProfile().email;
    const type = getUserType();
    const lock = getProfile().referral_lock;

    const updateRequest = {
        email: email,
        referral_code: referral,
        ref_type: type
    };

    if (lock === '1') {
        const result = 'locked';
        return result;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/users/referral`, {
            method: 'PUT',
            headers: {
                'Authorization': token
            },
            body: new URLSearchParams(updateRequest)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();

        if (!responseData.error) {
            const newProfile = {
                ...getProfile(),
                referral_code: referral,
                referral_lock: '1'
            };

            updateSessionProfile(JSON.stringify(newProfile));
        }

        return !responseData.error;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

async function uploadAvatar(file, userType) {
    const token = await checkJwt();

    const formData = new FormData();
    formData.append('avatar', file, file.name);
    formData.append('avatarName', file.name);
    formData.append('email', getProfile().email);

    try {
        const endpoint = userType === 'donor' ? 'update' : 'vupdate';
        const response = await sendProfileUpdateRequest(formData, token, endpoint);
        const responseData = await response.json();

        return responseData.result.data.avatar;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

async function sendProfileUpdateRequest(formData, token, endpoint) {
    return await fetch(`${process.env.REACT_APP_API_HOST}/v1/users/${endpoint}`, {
        method: 'POST',
        headers: {
            'Authorization': token
        },
        body: formData
    });
}

function appendIfDefined(formData, key, value) {
    if (value !== undefined) {
        formData.append(key, JSON.stringify(value));
    }
}

function buildNewProfile(userType, responseData) {
    const newProfile = {
        ...getProfile(),
        ...responseData.result.data,
    };

    if (userType !== 'donor') {
        newProfile.private_information = JSON.parse(responseData.result.data.private_information);
        newProfile.optional_information = JSON.parse(responseData.result.data.optional_information);
    }

    if (responseData.result.data.avatar) {
        newProfile.avatar = responseData.result.data.avatar;
    }

    return newProfile;
}

function updateSessionProfile(newProfile) {
    const lookUpSession = sessionStorage.getItem('gfdp-profile');
    if (lookUpSession) {
        sessionStorage.setItem('gfdp-profile', newProfile);
    } else {
        localStorage.setItem('gfdp-profile', newProfile);
    }
}

function updateSessionFirst(value) {
    const lookUpSession = sessionStorage.getItem('gfdp-first');
    if (lookUpSession) {
        sessionStorage.setItem('gfdp-first', value);
    } else {
        localStorage.setItem('gfdp-first', value);
    }
}

export function getProfile() {
    const getLocal = localStorage.getItem('gfdp-profile');
    const getSession = sessionStorage.getItem('gfdp-profile');
    if (getSession) {
        return JSON.parse(getSession);
    } else {
        return JSON.parse(getLocal);
    }
}

export function getFirst() {
    const getLocal = localStorage.getItem('gfdp-first');
    const getSession = sessionStorage.getItem('gfdp-first');
    if (getSession) {
        return JSON.parse(getSession);
    } else {
        return JSON.parse(getLocal);
    }
}

export function getUserType() {
    const getLocal = localStorage.getItem('gfdp-privilege');
    const getSession = sessionStorage.getItem('gfdp-privilege');
    if (getSession) {
        return getSession;
    } else {
        return getLocal;
    }
}