export const capitalize = (string) => {
    return string
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const slice = (string) => {
    const words = string.split(' ');
    return words[0];
};

export const shortenUrl = (url) => {
    const parts = url.split('/');
    return `.../${parts[parts.length - 1]}`;
};

export const formatIDR = (number) => {
    const formattedValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedValue;
};

export function getCurrentDate(lang) {
    const date = new Date();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long'
    };

    if (lang === 'id') {
        const dateFormatter = new Intl.DateTimeFormat('id-ID', options);
        const formattedDate = dateFormatter.format(date);
        return formattedDate;
    } else {
        const dateFormatter = new Intl.DateTimeFormat('en-US', options);
        const formattedDate = dateFormatter.format(date);
        return formattedDate;
    }
};