import { useEffect, useState } from 'react';
import { contentTranslate, accentSVG, useAuth, getCurrentDate, capitalize } from '../../services';
import { getProfile } from '../../features';
import style from './incentive.module.scss';

const IncentivePage = () => {
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [menu, setMenu] = useState(sessionStorage.getItem('menu'));
    const [tablet, setTablet] = useState(window.innerWidth <= 992);
    const { auth } = useAuth();
    const user = auth ? getProfile() : null;
    const fullname = user ? capitalize(user.fullname) : '';
    const getText = contentTranslate(lang);
    const text = getText.dashboard;
    const currentDate = getCurrentDate(lang);
    const getSVG = accentSVG();

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        const handleMenuChangeEvent = () => {
            setMenu(sessionStorage.getItem('menu'));
        };

        const handleWindowResize = () => {
            setTablet(window.innerWidth <= 992);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('menu-change', handleMenuChangeEvent);
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('menu-change', handleMenuChangeEvent);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className={`side-gap ${tablet ? style.overlay : ''} ${menu === 'close' ? style.close : ''}`}>
            <div className={style.logo}>{getSVG.logo.gfdp}</div>
            <div className={`${style.incentive} ${menu === 'close' ? style.wider : ''}`}>
                <div className={style.header}>
                    <p>{currentDate}</p>
                    <h3>{text.title} {fullname}</h3>
                </div>

                <div className={style.body}>
                    Coming soon
                </div>
            </div>
        </div>
    );
}

export default IncentivePage;