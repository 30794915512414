import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonPrimary, SuccessModal } from '../../features';
import { accentSVG, stopDonation } from '../../services';
import style from './success.module.scss';
import successImg from '../../assets/img/bg-thanks-web.png';
import boostAsset from '../../assets/img/medal-booster.gif';
import donationAsset from '../../assets/img/medal-donation.gif';

const SuccessPage = ({ successType }) => {
    const { type } = useParams();
    const navigate = useNavigate();
    const lang = sessionStorage.getItem('gfdp-lang');
    const getLocal = JSON.parse(localStorage.getItem('user-donation'));
    const user = getLocal ? getLocal.user : 'Username';
    const donation = getLocal ? getLocal.donation : null;
    const [modalShow, setModalShow] = useState(false);
    const [showResult, setShowResult] = useState(false);
    // const [isEmpty, setIsEmpty] = useState(false);
    const getSVG = accentSVG();

    const url = useParams();
    const code = url.param;

    const executeStop = useCallback(async (signal) => {
        const channel = null;
        try {
            const success = await stopDonation(code, channel, signal);
            if (success === 'success') {
                setShowResult(true);
                return;
            } else if (success === 'empty') {
                setShowResult(true);
                // setIsEmpty(true);
                return;
            }
        } catch (error) {
            navigate('/oops/500', { replace: true });
        }
    }, [code, navigate]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (successType === 'stop') {
            executeStop(signal);
        }

        return () => {
            controller.abort();
        };
    }, [successType, executeStop]);

    const handleBackClick = () => {
        if (getLocal) {
            localStorage.removeItem('user-donation');
        }
        navigate('/', { replace: true });
    };

    const successBG = {
        backgroundImage: `url('${successImg}')`
    };

    if (type) {
        successType = type;
    }

    if (donation) {
        successType = donation;
    }

    return (
        <div className={`vph ${style.success}`} style={successBG}>
            {successType === 'monthly' || successType === 'single' || successType === 'boost' ? (
                <div className={style.wrapper}>
                    {successType === 'boost' ? (
                        <img src={boostAsset} alt="medal" loading='lazy' />
                    ) : (
                        <img src={donationAsset} height='106' alt="medal" loading='lazy' />
                    )}

                    <h4 className={style['line-break']}>{lang === 'id' ? `Terima Kasih, ${user}!\nDonasimu untuk lingkungan telah diterima.` : `Thank You, ${user}!\nYour donation for the environment has been accepted.`}</h4>

                    {successType === 'monthly' ? (
                        <p>{lang === 'id' ? 'Kamu bisa memonitor progress donasimu di dashboard donatur. Silakan cek emailmu untuk mendapatkan akses ke login dashboard.' : 'You can track your donation progress on Donor Dashboard. Please check your email for the access.'}</p>
                    ) : (
                        <p>{lang === 'id' ? 'Setiap bulan, kamu akan mendapatkan newsletter tentang kegiatan kami yang dapat terlaksana berkat donasi darimu!' : 'Every month, you will receive newsletter about our programs — completed succesfully thanks your contribution!'}</p>
                    )}

                    <ButtonPrimary onClick={() => setModalShow(true)}>{lang === 'id' ? 'Bagikan Kontribusimu' : 'Share Your Contribution'} <span>{getSVG.success.share}</span></ButtonPrimary>
                    <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />

                    <div className={style.link} onClick={handleBackClick}>
                        <span className='me-1'>{getSVG.success.arrow}</span>
                        {lang === 'id' ? 'Kembali ke Halaman Utama' : 'Back to Main Page'}
                    </div>
                </div>
            ) : successType === 'volunteer' ? (
                <div className={style.wrapper}>
                    <h4 className={style['line-break']}>{lang === 'id' ? 'Terima Kasih\nPendaftaran Kamu Telah Kami Terima' : 'Thank You\nWe have received your registration'}</h4>
                    <p>{lang === 'id' ? 'Kami akan menghubungi kamu untuk informasi selanjutnya' : 'We will contact you for further information'}</p>

                    <div className={style.link} onClick={handleBackClick}>
                        <span className='me-1'>{getSVG.success.arrow}</span>
                        {lang === 'id' ? 'Kembali ke Halaman Utama' : 'Back to Main Page'}
                    </div>
                </div>
            ) : successType === 'stop' ? (
                <div className={style.wrapper}>
                    {showResult ? (
                        <>
                            {/* {isEmpty ? (
                                <h4 className={style['line-break']}>{lang === 'id' ? 'ID tidak ada.' : 'ID does not exist.'}</h4>
                            ) : (
                                <h4 className={style['line-break']}>{lang === 'id' ? 'Anda telah berhenti donasi bulanan.' : 'You have stopped the monthly donation.'}</h4>
                            )} */}

                            <h4 className={style['line-break']}>{lang === 'id' ? 'Anda telah berhenti donasi bulanan.' : 'You have stopped the monthly donation.'}</h4>

                            <div className={style.link} onClick={handleBackClick}>
                                <span className='me-1'>{getSVG.success.arrow}</span>
                                {lang === 'id' ? 'Kembali ke Halaman Utama' : 'Back to Main Page'}
                            </div>
                        </>
                    ) : (
                        <p>{lang === 'id' ? 'Mohon tunggu...' : 'Please wait...'}</p>
                    )}

                </div>
            ) : (
                <div className={style.wrapper}>
                    <h4>{lang === 'id' ? 'Sukses!' : 'Success!'}</h4>

                    <div className={style.link} onClick={handleBackClick}>
                        <span className='me-1'>{getSVG.success.arrow}</span>
                        {lang === 'id' ? 'Kembali ke Halaman Utama' : 'Back to Main Page'}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SuccessPage;