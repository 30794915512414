const landing = `${process.env.PUBLIC_URL}/meta/landing-page.webp`;
const sampan = `${process.env.PUBLIC_URL}/meta/sampan-harapan.webp`;
const anak = `${process.env.PUBLIC_URL}/meta/anak-pejuang-lingkungan.webp`;
const triseda = `${process.env.PUBLIC_URL}/meta/triseda-membawa-berkah.webp`;
const sampah = `${process.env.PUBLIC_URL}/meta/sampah-jadi-cita.webp`;
const report = `${process.env.PUBLIC_URL}/meta/report.webp`;
const monthly = `${process.env.PUBLIC_URL}/meta/recurring.webp`;
const single = `${process.env.PUBLIC_URL}/meta/single.webp`;
const volunteer = `${process.env.PUBLIC_URL}/meta/Fundraiser-buddies.webp`;

export function metaSEO(lang) {
    const meta = {
        id: [
            {
                page: "Beranda",
                url: "/",
                keywords: "Green Fund Digital Philanthropy",
                title: "Green Fund Digital Philanthropy | Donasi Lingkungan Digital",
                description: "Green Fund Digital Philanthropy adalah platform donasi lingkungan digital Indonesia. Bergotong royong bersama masyarakat melawan perubahan iklim.",
                image: landing
            },
            {
                page: "Campaign Sampan Harapan",
                url: "/campaign/sampan-harapan",
                keywords: "Kampanye lingkungan, sampan harapan",
                title: "Sampan Harapan | Aksi GFDP Stop Polusi Sampah",
                description: "Sampan Harapan aksi nyata Green Fund Digital Philanthropy. Singkirkan sampah Sungai Citarum. Berkat kamu pelestari punya perahu yang layak.",
                image: sampan
            },
            {
                page: "Campaign Anak Pejuang Lingkungan",
                url: "/campaign/anak-pejuang-lingkungan",
                keywords: "Kampanye, anak pejuang lingkungan",
                title: "Aksi Lingkungan GFDP | Anak Pejuang Lingkungan",
                description: "Mereka ingin tetap sekolah supaya punya masa depan cerah. Bersama Green Fund Digital Philanthropy wujudkan impian anak pemulung!",
                image: anak
            },
            {
                page: "Campaign Triseda Membawa Berkah",
                url: "/campaign/triseda-membawa-berkah",
                keywords: "Kampanye, triseda membawa berkah, pak sukam",
                title: "Aksi Lingkungan GFDP | Triseda Membawa berkah",
                description: "Dukung aksi Pak Sukam untuk kelola sampah laut lewat kampanye Triseda Membawa Berkah di Green Fund Digital Philanthropy!",
                image: triseda
            },
            {
                page: "Campaign Sampah Jadi Cita",
                url: "/campaign/sampah-jadi-cita",
                keywords: "Kampanye, sampah jadi cita, pak sukam",
                title: "Aksi Lingkungan GFDP | Sampah Jadi Cita",
                description: "Sampah Jadi Cita selamatkan masa depan pendidikan anak pejuang lingkungan di Banyuwangi. Donasi sekarang!",
                image: sampah
            },
            {
                page: "Onboarding Donasi Bulanan",
                url: "/donation/monthly",
                keywords: "Donasi lingkungan, donasi bulanan",
                title: "Donasi Bulanan GFDP | Pulihkan Kerusakan Lingkungan",
                description: "Bergabung bersama 10 juta masyarakat indonesia berdonasi Rp.10.000! Green Fund Digital Philanthropy selamatkan bumi dari ambang kehancuran.",
                image: monthly
            },
            {
                page: "Onboarding Donasi Bulanan",
                url: "/donation/monthly/form/recurring",
                keywords: "Donasi lingkungan, donasi bulanan",
                title: "Donasi Bulanan GFDP | Pulihkan Kerusakan Lingkungan",
                description: "Bergabung bersama 10 juta masyarakat indonesia berdonasi Rp.10.000! Green Fund Digital Philanthropy selamatkan bumi dari ambang kehancuran.",
                image: monthly
            },
            {
                page: "Onboarding Donasi Bulanan",
                url: "/donation/monthly/form/reminder",
                keywords: "Donasi lingkungan, donasi bulanan",
                title: "Donasi Bulanan GFDP | Pulihkan Kerusakan Lingkungan",
                description: "Bergabung bersama 10 juta masyarakat indonesia berdonasi Rp.10.000! Green Fund Digital Philanthropy selamatkan bumi dari ambang kehancuran.",
                image: monthly
            },
            {
                page: "Onboarding Donasi Sekali",
                url: "/donation/single/form",
                keywords: "Donasi lingkungan",
                title: "Donasi GFDP | Solusi Digital Selamatkan Bumi",
                description: "Donasi lingkungan kapanpun dengan mudah lewat Green Fund Digital Philanthropy. Berdampak dan memberikan solusi nyata!",
                image: single
            },
            {
                page: "Onboarding Donasi Boost",
                url: "/donation/boost/form",
                keywords: "Donasi lingkungan",
                title: "Donasi GFDP | Solusi Digital Selamatkan Bumi",
                description: "Donasi lingkungan kapanpun dengan mudah lewat Green Fund Digital Philanthropy. Berdampak dan memberikan solusi nyata!",
                image: single
            },
            {
                page: "Volunteer",
                url: "/volunteer",
                keywords: "Fundraiser buddies",
                title: "Fundraiser Buddies | Jadi Pejuang Lingkungan GFDP",
                description: "Waktunya jadi pejuang kelestarian lingkungan dengan menjadi Fundraiser Buddies! Berkolaborasi dan belajar bersama expert lingkungan.",
                image: volunteer
            },
            {
                page: "Volunteer",
                url: "/volunteer/signup",
                keywords: "Fundraiser buddies",
                title: "Fundraiser Buddies | Jadi Pejuang Lingkungan GFDP",
                description: "Waktunya jadi pejuang kelestarian lingkungan dengan menjadi Fundraiser Buddies! Berkolaborasi dan belajar bersama expert lingkungan.",
                image: volunteer
            },
            {
                page: "Report",
                url: "/report",
                keywords: "Jejak Kebaikan GFDP, impact report GFDP",
                title: "Jejak Kebaikan GFDP",
                description: "Lihat buah kontribusi kamu untuk lingkungan pada laporan dampak Jejak Kebaikan Green Fund Digital Philanthropy.",
                image: report
            }
        ],
        en: [
            {
                page: "Beranda",
                url: "/",
                keywords: "Green Fund Digital Philanthropy",
                title: "Green Fund Digital Philanthropy | Donasi Lingkungan Digital",
                description: "Green Fund Digital Philanthropy adalah platform donasi lingkungan digital Indonesia. Bergotong royong bersama masyarakat melawan perubahan iklim.",
                image: landing
            },
            {
                page: "Campaign Sampan Harapan",
                url: "/campaign/sampan-harapan",
                keywords: "Kampanye lingkungan, sampan harapan",
                title: "Sampan Harapan | Aksi GFDP Stop Polusi Sampah",
                description: "Sampan Harapan aksi nyata Green Fund Digital Philanthropy. Singkirkan sampah Sungai Citarum. Berkat kamu pelestari punya perahu yang layak.",
                image: sampan
            },
            {
                page: "Campaign Anak Pejuang Lingkungan",
                url: "/campaign/anak-pejuang-lingkungan",
                keywords: "Kampanye, anak pejuang lingkungan",
                title: "Aksi Lingkungan GFDP | Anak Pejuang Lingkungan",
                description: "Mereka ingin tetap sekolah supaya punya masa depan cerah. Bersama Green Fund Digital Philanthropy wujudkan impian anak pemulung!",
                image: anak
            },
            {
                page: "Campaign Triseda Membawa berkah",
                url: "/campaign/triseda-membawa-berkah",
                keywords: "Kampanye, triseda membawa berkah, pak sukam",
                title: "Aksi Lingkungan GFDP | Triseda Membawa berkah",
                description: "Dukung aksi Pak Sukam untuk kelola sampah laut lewat kampanye Triseda Membawa Berkah di Green Fund Digital Philanthropy!",
                image: triseda
            },
            {
                page: "Campaign Sampah Jadi Cita",
                url: "/campaign/sampah-jadi-cita",
                keywords: "Kampanye, sampah jadi cita, pak sukam",
                title: "Aksi Lingkungan GFDP | Sampah Jadi Cita",
                description: "Sampah Jadi Cita selamatkan masa depan pendidikan anak pejuang lingkungan di Banyuwangi. Donasi sekarang!",
                image: sampah
            },
            {
                page: "Onboarding Donasi Bulanan",
                url: "/donation/monthly",
                keywords: "Donasi lingkungan, donasi bulanan",
                title: "Donasi Bulanan GFDP | Pulihkan Kerusakan Lingkungan",
                description: "Bergabung bersama 10 juta masyarakat indonesia berdonasi Rp.10.000! Green Fund Digital Philanthropy selamatkan bumi dari ambang kehancuran.",
                image: monthly
            },
            {
                page: "Onboarding Donasi Bulanan",
                url: "/donation/monthly/form/recurring",
                keywords: "Donasi lingkungan, donasi bulanan",
                title: "Donasi Bulanan GFDP | Pulihkan Kerusakan Lingkungan",
                description: "Bergabung bersama 10 juta masyarakat indonesia berdonasi Rp.10.000! Green Fund Digital Philanthropy selamatkan bumi dari ambang kehancuran.",
                image: monthly
            },
            {
                page: "Onboarding Donasi Bulanan",
                url: "/donation/monthly/form/reminder",
                keywords: "Donasi lingkungan, donasi bulanan",
                title: "Donasi Bulanan GFDP | Pulihkan Kerusakan Lingkungan",
                description: "Bergabung bersama 10 juta masyarakat indonesia berdonasi Rp.10.000! Green Fund Digital Philanthropy selamatkan bumi dari ambang kehancuran.",
                image: monthly
            },
            {
                page: "Onboarding Donasi Sekali",
                url: "/donation/single/form",
                keywords: "Donasi lingkungan",
                title: "Donasi GFDP | Solusi Digital Selamatkan Bumi",
                description: "Donasi lingkungan kapanpun dengan mudah lewat Green Fund Digital Philanthropy. Berdampak dan memberikan solusi nyata!",
                image: single
            },
            {
                page: "Onboarding Donasi Boost",
                url: "/donation/boost/form",
                keywords: "Donasi lingkungan",
                title: "Donasi GFDP | Solusi Digital Selamatkan Bumi",
                description: "Donasi lingkungan kapanpun dengan mudah lewat Green Fund Digital Philanthropy. Berdampak dan memberikan solusi nyata!",
                image: single
            },
            {
                page: "Volunteer",
                url: "/volunteer",
                keywords: "Fundraiser buddies",
                title: "Fundraiser Buddies | Jadi Pejuang Lingkungan GFDP",
                description: "Waktunya jadi pejuang kelestarian lingkungan dengan menjadi Fundraiser Buddies! Berkolaborasi dan belajar bersama expert lingkungan.",
                image: volunteer
            },
            {
                page: "Volunteer",
                url: "/volunteer/signup",
                keywords: "Fundraiser buddies",
                title: "Fundraiser Buddies | Jadi Pejuang Lingkungan GFDP",
                description: "Waktunya jadi pejuang kelestarian lingkungan dengan menjadi Fundraiser Buddies! Berkolaborasi dan belajar bersama expert lingkungan.",
                image: volunteer
            },
            {
                page: "Report",
                url: "/report",
                keywords: "Jejak Kebaikan GFDP, impact report GFDP",
                title: "Jejak Kebaikan GFDP",
                description: "Lihat buah kontribusi kamu untuk lingkungan pada laporan dampak Jejak Kebaikan Green Fund Digital Philanthropy.",
                image: report
            }
        ],
    };

    return meta[lang];
}