import { useEffect, useState } from 'react';
import { termsConditions } from '../../services';
import style from './terms.module.scss';

const TermsPage = () => {
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getContent = termsConditions(lang);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loader = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.scrollTo(0, 0);
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            clearTimeout(loader);
        };
    }, []);

    return (
        <div className={`base-gap ${style.terms}`}>
            {isLoading && (
                <div className='spinner-wrapper'>
                    <div className='loading-spinner'></div>
                </div>
            )}

            <div className={style.content}>{getContent}</div>
        </div>
    );
}

export default TermsPage;