import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { getSections, getImpacts, getCampaigns, accentSVG, contentTranslate, useAuth, mitraSlider } from '../../services';
import { DonationCard, Contact, TestimonialSlider } from '../../features';
import Slider from 'react-slick';
import style from './landing.module.scss';
import mitra_1 from '../../assets/img/mitra1.webp';
import mitra_2 from '../../assets/img/mitra2.webp';
import mitra_3 from '../../assets/img/mitra3.webp';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    const publicContent = process.env.REACT_APP_PUBLIC_CONTENT;
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const { auth } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 992);
    const [heroImg, setHeroImg] = useState('');
    const [heroTitle, setHeroTitle] = useState('');
    const [heroSubtitle, setHeroSubtitle] = useState('');
    const [impacts, setImpacts] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [openIndex, setOpenIndex] = useState(-1);
    const [wScroll, setWScroll] = useState(0);
    const getAccent = accentSVG();
    const getText = contentTranslate(lang);
    const steps = getText.landing.steps.content;
    const faqs = getText.landing.faq.content;
    const id = null;

    const handleLangChangeEvent = useCallback(() => {
        const newLang = sessionStorage.getItem('gfdp-lang');
        setLang(newLang);
    }, []);

    const fetchSections = useCallback((signal) => {
        getSections(lang, signal).then(data => {
            if (data && data.sectionHero) {
                setHeroImg(publicContent + data.sectionHero.img);
                setHeroTitle(data.sectionHero.textTitle);
                setHeroSubtitle(data.sectionHero.textSubTitle);
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [lang, publicContent]);

    const fetchImpacts = useCallback((signal) => {
        getImpacts(lang, signal).then(data => {
            if (data) {
                setImpacts(data);
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [lang]);

    const fetchCampaigns = useCallback((signal) => {
        getCampaigns(lang, id, signal).then(data => {
            if (data) {
                setCampaigns(data);
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [lang]);

    useLayoutEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchSections(signal);
    
      return () => {
        controller.abort();
      };
    }, [fetchSections]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const loader = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        if (!isLoading) {
            setTimeout(() => {
                setIsVisible(true);
            }, 100);
        }

        fetchImpacts(signal);
        fetchCampaigns(signal);

        const handleWindowResize = () => {
            setMobile(window.innerWidth <= 992);
        };

        const handleScroll = () => {
            setWScroll(window.scrollY);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('scroll', handleScroll);
            controller.abort();
            clearTimeout(loader);
        };

    }, [isLoading, handleLangChangeEvent, fetchImpacts, fetchCampaigns]);

    const heroStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${heroImg}')`,
        transform: mobile ? '' : `translateY(${wScroll * 0.5}px)`
    };

    const parallax = {
        position: 'relative',
        paddingBottom: '24px',
        backgroundColor: 'white'
    };

    const wrapper = {
        position: 'relative',
        overflow: 'hidden'
    };

    const stick = {
        transform: `translateY(${wScroll * -0.5}px)`
    };

    const delay = {
        transform: `translateY(${wScroll * -0.4}px)`
    };

    const toggleFAQ = (index) => {
        if (openIndex === index) {
            setOpenIndex(-1);
        } else {
            setOpenIndex(index);
        }
    };

    return (
        <div className={`base-gap ${style.landing}`}>
            {isLoading && (
                <div className='spinner-wrapper'>
                    <div className='loading-spinner'></div>
                </div>
            )}

            <section id='hero' style={mobile ? {} : parallax}>
                <div style={mobile ? {} : wrapper}>
                    <div className={style['hero-wrapper']} style={heroStyle}>
                        <span className={style['accent-hero-lg']} style={mobile ? {} : stick}>{getAccent.landing.hero.accent}</span>
                        <div className='wide-wrapper'>
                            <div className={style['inner-wrapper']} style={mobile ? {} : delay}>
                                <div className={`${style.left} ${auth ? 'w-100' : ''}`} style={mobile ? heroStyle : {}}>
                                    <span className={style['accent-hero-sm']}>{getAccent.landing.hero.accent}</span>
                                    {mobile ? (
                                        <span className={style['title-wrapper']}>
                                            <h1>{heroTitle}</h1>
                                            <p>{heroSubtitle}</p>
                                        </span>
                                    ) : (
                                        <div className={`${style['fade-up']} ${isVisible ? style.active : ''}`}>
                                            {!isLoading && isVisible && (
                                                <span className={style['title-wrapper']}>
                                                    <h1>{heroTitle}</h1>
                                                    <p>{heroSubtitle}</p>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {!auth && (
                                    <div className={`${style.right}`}>
                                        <div className={`${style['fade-up']} ${isVisible ? style.active : ''}`}>
                                            {!isLoading && isVisible && (
                                                <DonationCard />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='impacts'>
                <div className={style['impacts-wrapper']}>
                    {impacts.map((item, index) => (
                        <div className={style['impacts-content']} key={index}>
                            <div className={style.left}>
                                <span className={style['accent-impacts']}>{getAccent.landing.impacts.accent}</span>
                                <img className={style.image} src={publicContent + item.img} alt='impacts' loading='lazy' />
                            </div>
                            <div className={style.right}>
                                <div className={style.content}>
                                    <h2>{item.title}</h2>
                                    <p>{item.desc}</p>
                                </div>
                                <div className={style.benchmarks}>
                                    {Object.values(JSON.parse(item.benchmarks)).map((benchmark, benchmarkIndex) => (
                                        <div className={style.box} key={benchmarkIndex}>
                                            <div className={style['box-icon']}>{benchmark.icon}</div>
                                            <div className={style['box-desc']}>
                                                {benchmark.titles.trim() !== "" && <p>{benchmark.titles}</p>}
                                                <h4>{benchmark.number}</h4>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section id='ten'>
                <div className='wide-wrapper'>
                    <div className={style['ten-wrapper']}>
                        <div className={style.top}>
                            <div className={style.box}>
                                <div className={style.title}>
                                    <span>{getAccent.landing.ten.one}</span>
                                    <h4>{getText.landing.tripleten.tahun.title}</h4>
                                    <span>{getAccent.landing.ten.zero}</span>
                                </div>
                                <div className={style.subtitle}><p>{getText.landing.tripleten.tahun.subtitle}</p></div>
                            </div>
                            <div className={style.box}>
                                <div className={style.title}>
                                    <span>{getAccent.landing.ten.one}</span>
                                    <h4>{getText.landing.tripleten.juta.title}</h4>
                                    <span>{getAccent.landing.ten.zero}</span>
                                </div>
                                <div className={style.subtitle}><p>{getText.landing.tripleten.juta.subtitle}</p></div>
                            </div>
                            <div className={style.box}>
                                <div className={style.title}>
                                    <span>{getAccent.landing.ten.one}</span>
                                    <h4>{getText.landing.tripleten.ribu.title}</h4>
                                    <span>{getAccent.landing.ten.zero}</span>
                                </div>
                                <div className={style.subtitle}><p>{getText.landing.tripleten.ribu.subtitle}</p></div>
                            </div>
                        </div>
                        <div className={style.bottom}>
                            <p>{getText.landing.tripleten.description}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id='campaigns'>
                <div className={style['campaigns-wrapper']}>
                    <div className={style.header}>
                        <h2>{getText.landing.campaigns.title}</h2>
                        <p>{getText.landing.campaigns.subtitle}</p>
                    </div>
                    <div className={style.body}>
                        {campaigns.map((campaign, key) => (
                            <Link to={`campaign/${campaign.slug}`} className={style['campaign-card']} key={key}>
                                <div className={style['campaign-image']}>
                                    <img className={style.image} src={publicContent + campaign.image} alt='campaigns' loading='lazy' />
                                </div>
                                <div className={style.middle}>
                                    <h4 className={style['campaign-title']}>{campaign.title}</h4>
                                    <p className={style['campaign-subtitle']}>{campaign.subtitle}</p>
                                </div>
                                <div className={style['campaign-helplist']}>
                                    {campaign.contents.helpList.map((help, index) => (
                                        <div key={index} className={style['campaign-help']}>
                                            <span className={style['check-icon']}>{getAccent.landing.campaigns.check}</span>
                                            <h6>{help}</h6>
                                        </div>
                                    ))}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>

            <section id='testimony-impact' className={style['testimony-bg']}>
                <div className='wide-wrapper'>
                    <div className={style['testimony-wrapper']}>
                        <div className={style.header}>
                            <h2>{getText.landing.testiImpact.title}</h2>
                            <p>{getText.landing.testiImpact.subtitle}</p>
                        </div>
                        <div className={style.body}>
                            <TestimonialSlider id='impact' />
                        </div>
                    </div>
                </div>
            </section>

            <section id='donation-steps' className={style['steps-bg']}>
                <div className='wide-wrapper'>
                    <div className={style['steps-wrapper']}>
                        <div className={style.header}>
                            <h2>{getText.landing.steps.title}</h2>
                        </div>
                        <div className={style.body}>
                            <div className={style['card-wrapper']}>
                                {steps.map((step, key) => (
                                    <div className={style.steps} key={key}>
                                        <h4>0{key + 1}. {step.title}</h4>
                                        <p>{step.subtitle}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='benefits' className={style['benefits-bg']}>
                <div className='wide-wrapper'>
                    <div className={style['benefits-wrapper']}>
                        <div className={style.header}>
                            <h2>{getText.landing.benefits.title}</h2>
                            <p>{getText.landing.benefits.subtitle}</p>
                        </div>
                        <div className={style.body}>
                            <div className={style['box-wrapper']}>
                                <div className={style.box}>
                                    <h6>{getText.landing.benefits.content_a}</h6>
                                </div>
                                <div className={style.box}>
                                    <h6>{getText.landing.benefits.content_b}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className={style.accent}>{getAccent.landing.benefits.accent}</span>
            </section>

            <section id='testimony-donor' className={style['testimony-bg']}>
                <div className='wide-wrapper'>
                    <div className={style['testimony-wrapper']}>
                        <div className={style.header}>
                            <h2>{getText.landing.testiDonor.title}</h2>
                            <p>{getText.landing.testiDonor.subtitle}</p>
                        </div>
                        <div className={style.body}>
                            <TestimonialSlider id='donor' />
                        </div>
                    </div>
                </div>
            </section>

            <section id='mitra'>
                <div className='wide-wrapper'>
                    <div className={style['mitra-wrapper']}>
                        <div className={style.header}>
                            <h2>{getText.landing.mitra.title}</h2>
                        </div>
                        <div className={style.body}>
                            <Slider {...mitraSlider} className={`selector-landing ${style['logo-wrapper']}`}>
                                <div className={style.logo}><img src={mitra_1} alt='partnership' loading='lazy' /></div>
                                <div className={style.logo}><img src={mitra_2} alt='partnership' loading='lazy' /></div>
                                <div className={style.logo}><img src={mitra_3} alt='partnership' loading='lazy' /></div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            <section id='faq'>
                <div className='wide-wrapper'>
                    <div className={style['faq-wrapper']}>
                        <div className={style.header}>
                            <h2>{getText.landing.faq.title}</h2>
                        </div>
                        <div className={style.body}>
                            <ul className={style['faq-list']}>
                                {faqs.map((faq, index) => (
                                    <li className={`${style.faq} ${openIndex === index ? style.open : ''}`} key={index}>
                                        <div className={style.question} onClick={() => toggleFAQ(index)}>
                                            <h6>{faq.question}</h6>
                                            <span className={style.arrow}>{getAccent.landing.faq.arrow}</span>
                                        </div>
                                        <div className={style.answer}>
                                            {faq.answer}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section id='contacts' className={style['bg-contacts']}>
                <Contact />
            </section>
        </div>
    );
}

export default LandingPage;