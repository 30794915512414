import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { contentTranslate, getBadges } from '../../../../services';
import { ButtonPrimary } from '..';
import Modal from 'react-bootstrap/Modal';
import style from './modal.module.scss';

const BadgeModal = (props) => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const text = getText.dashboard.modal.badge;
    const [currentBadge, setCurrentBadge] = useState([]);

    const fetchBadges = useCallback((signal) => {
        getBadges(lang, signal).then(data => {
            if (data) {
                setCurrentBadge(data.currentBadges);
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [lang]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchBadges(signal);

        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            controller.abort();
        }
    }, [fetchBadges]);

    const handleView = () => {
        props.onHide();
        navigate('/dashboard/badges');
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={`${style.modal} ${style.badge}`}>
                <Modal.Header className={style.header} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {text.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={style.body}>
                    <div className={`${currentBadge.color} ${style['badge-wrapper']} ${style[`${currentBadge.color}`]}`}>
                        {currentBadge.page}
                    </div>
                    <p>{text.subtitle} <strong>{currentBadge.title}</strong></p>
                </Modal.Body>
                <Modal.Footer className={style.footer}>
                    <ButtonPrimary onClick={handleView}>{text.view}</ButtonPrimary>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default BadgeModal;