import { useEffect, useState } from 'react';
import { accentSVG, contentTranslate } from '../../services';
import { Document, Page, pdfjs } from 'react-pdf';
import { ButtonPrimary, ReportModal } from '../../features';
import style from './report.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ReportPage = () => {
    const pdfID = `${process.env.PUBLIC_URL}/pdf/ID-Impact-Report-GFDP-2023.pdf`;
    const pdfEN = `${process.env.PUBLIC_URL}/pdf/EN-Impact-Report-GFDP-2023.pdf`;
    const [pdfUrl, setPdfUrl] = useState(null);
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [isLoading, setIsLoading] = useState(true);
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const getAccent = accentSVG();
    const [reportShow, setReportShow] = useState(false);
    const getText = contentTranslate(lang);
    const text = getText.report;

    useEffect(() => {
        if (lang === 'id') {
            setPdfUrl(pdfID);
        } else {
            setPdfUrl(pdfEN);
        }

        const loader = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.scrollTo(0, 0);
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            clearTimeout(loader);
        }
    }, [lang, isLoading, pdfID, pdfEN]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        } else {
            setPageNumber(1);
        }
    };

    const prevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        } else {
            setPageNumber(numPages);
        }
    };

    return (
        <div className={`base-gap ${style.report}`}>
            {isLoading && (
                <div className='spinner-wrapper'>
                    <div className='loading-spinner'></div>
                </div>
            )}

            <section className={style['report-content']}>
                <div className={style.header}>
                    <h1>GFDP Impact Report 2022 - 2023</h1>
                    <p>{text.LP1}</p>
                </div>

                <div className={style.body}>
                    <div className={style.pdf}>
                        <span className={`${pageNumber === 1 ? 'd-none' : ''} ${style.arrow} ${style.prev}`} onClick={prevPage}>{getAccent.report.prev}</span>
                        <Document className={style.doc} file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page
                                className={style.doc}
                                pageNumber={pageNumber}
                                renderMode="canvas"
                                canvasRef={canvasRef => {
                                    if (canvasRef) {
                                        canvasRef.setAttribute('willReadFrequently', 'true');
                                    }
                                }}
                            />
                        </Document>
                        <span className={`${pageNumber === numPages ? 'd-none' : ''} ${style.arrow} ${style.next}`} onClick={nextPage}>{getAccent.report.next}</span>
                    </div>
                    <p>{text.LP2}</p>
                    <ButtonPrimary onClick={() => setReportShow(true)}>{text.button}</ButtonPrimary>
                    <ReportModal show={reportShow} onHide={() => setReportShow(false)} />
                </div>
            </section>
        </div>
    );
}

export default ReportPage;
