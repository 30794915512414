import { useState, useEffect, useCallback } from 'react';
import { getTestimonials, accentSVG, sliderSettings, CustomSlide } from '../../../../services';
import Slider from 'react-slick';
import style from './card.module.scss';

const TestimonialSlider = ({ id, className }) => {
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [testimonials, setTestimonials] = useState([]);
    const getAccent = accentSVG();

    const handleLangChangeEvent = useCallback(() => {
        const newLang = sessionStorage.getItem('gfdp-lang');
        setLang(newLang);
    }, []);

    const fetchTestimonials = useCallback((signal) => {
        getTestimonials(lang, signal).then(data => {
            if (data) {
                const filteredImpact = data.filter(testimony => testimony.type === 'manfaat');
                const filteredDonor = data.filter(testimony => testimony.type === 'sponsor');

                if (id === 'impact') {
                    setTestimonials(filteredImpact);
                } else if (id === 'donor') {
                    setTestimonials(filteredDonor);
                }
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [lang, id]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetchTestimonials(signal);

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            controller.abort();
        };
    }, [handleLangChangeEvent, fetchTestimonials]);

    return (
        <div id={id} className={`${className}`}>
            <Slider {...sliderSettings}>
                {testimonials.map((testimony, key) => (
                    testimony.type === 'manfaat' ? (
                        <CustomSlide className={`${style['slider-card']} ${style.impact}`} key={key}>
                            <div className={`flex-fill ${style.feedback}`}>
                                <p>{testimony.feedback}</p>
                            </div>
                            <div className={style.profile}>
                                <img className={style.image} src={testimony.img} alt='profile-impact' loading='lazy' />
                                <div className={style.bio}>
                                    <h6>{testimony.name}</h6>
                                    <p>{testimony.title}</p>
                                </div>
                            </div>
                        </CustomSlide>
                    ) : (
                        <CustomSlide className={`${style['slider-card']} ${style.donor}`} key={key}>
                            <div className={style.profile}>
                                <div className={style.header}>
                                    <span>{getAccent.landing.ten.one}</span>
                                    <img className={style.image} src={testimony.img} alt='profile-impact' loading='lazy' />
                                    <span>{getAccent.landing.ten.zero}</span>
                                </div>
                                <div className={style.bio}>
                                    <h6>{testimony.name}</h6>
                                    <p>{testimony.title}</p>
                                </div>
                            </div>
                            <div className={`flex-fill ${style.feedback}`}>
                                <p>{testimony.feedback}</p>
                            </div>
                        </CustomSlide>
                    )
                ))}
            </Slider>
        </div>
    );
};

export default TestimonialSlider;
