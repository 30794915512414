import { accentSVG } from '../../services';

const getAccent = accentSVG();

export const CustomSlide = ({ children, className }) => (
    <div className={className}>
        {children}
    </div>
);

const NextArrow = ({ className, onClick }) => {
    return (
        <div
            className={className}
            onClick={onClick}
        >
            {getAccent.landing.testimony.next}
        </div>
    );
};

const PrevArrow = ({ className, onClick }) => {
    return (
        <div
            className={className}
            onClick={onClick}
        >
            {getAccent.landing.testimony.prev}
        </div>
    );
};

export const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                initialSlide: 4
            }
        }
    ]
};

export const mitraSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }
    ]
};