import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { contentTranslate, accentSVG, getBadges, getRecurring, useAuth, getCurrentDate, capitalize, slice, shortenUrl } from '../../services';
import { ButtonPrimary, ButtonTransparent, Input, PasswordModal, RecurringModal, ReferralModal, ShareModal, getFirst, getProfile } from '../../features';
import clipboardCopy from 'clipboard-copy';
import avatarDefault from '../../assets/img/user.png';
import accentBoost from '../../assets/img/accent-boost.png';
import accentShare from '../../assets/img/accent-share.png';
import style from './donor.module.scss';

const DonorPage = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const publicContent = process.env.REACT_APP_PUBLIC_CONTENT;
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [menu, setMenu] = useState(sessionStorage.getItem('menu'));
    const [laptop, setLaptop] = useState(window.innerWidth <= 1200);
    const [tablet, setTablet] = useState(window.innerWidth <= 992);
    const [mobile, setMobile] = useState(window.innerWidth <= 768);
    const { auth } = useAuth();
    const user = auth ? getProfile() : null;
    const { fullname, email, avatar } = user
        ? { fullname: capitalize(user.fullname), email: user.email, avatar: user.avatar ? publicContent + user.avatar : avatarDefault }
        : { fullname: '', email: null, avatar: avatarDefault };
    const getText = contentTranslate(lang);
    const text = getText.dashboard;
    const achievement = getText.dashboard.achievement;
    const donor = getText.dashboard.donor;
    const currentDate = getCurrentDate(lang);
    const getSVG = accentSVG();
    const [badges, setBadges] = useState([]);
    const [currentBadge, setCurrentBadge] = useState([]);
    const [displayBadge, setDisplayBadge] = useState([]);
    const [currentPoint, setCurrentPoint] = useState(0);
    const [pointMax, setPointMax] = useState(0);
    const [recurring, setRecurring] = useState([]);
    const [recurringShow, setRecurringShow] = useState(false);
    const [referralShow, setReferralShow] = useState(false);
    const [shareShow, setShareShow] = useState(false);
    const link = `${baseUrl}/donation/monthly?ref=${user ? user.referral_code : ''}`;
    const [linkShow, setLinkShow] = useState(link);
    const [isCopied, setIsCopied] = useState(false);
    const [nextPoint, setNextPoint] = useState(0);
    const first = getFirst();
    const [passwordShow, setPasswordShow] = useState(false);

    const fetchBadges = useCallback((signal) => {
        getBadges(lang, signal).then(data => {
            if (data) {
                setBadges(data);
                setCurrentBadge(data.currentBadges);
                setDisplayBadge(data.displayBadges);
                setCurrentPoint(data.progressBar.currentPoint);
                setPointMax(data.progressBar.pointMax);
                setNextPoint(data.nextBadges.pointMin - data.userPoint?.toFixed(2));
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [lang]);

    const showRecurring = useCallback((signal) => {
        getRecurring(email, signal).then(data => {
            if (data) {
                setRecurring(data.amount);
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [email]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (auth && email) {
            showRecurring(signal);
        }

        fetchBadges(signal);

        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        const handleMenuChangeEvent = () => {
            setMenu(sessionStorage.getItem('menu'));
        };

        const handleAmountChangeEvent = () => {
            showRecurring(signal);
        };

        const handlePasswordUpdateEvent = () => {
            showRecurring(signal);
            fetchBadges(signal);
        };

        const handleReferralChangeEvent = () => {
            const newRef = getProfile().referral_code;
            const newLink = `${baseUrl}/donation/monthly?ref=${newRef}`;
            setLinkShow(newLink);
        };

        const handleWindowResize = () => {
            setLaptop(window.innerWidth <= 1200);
            setTablet(window.innerWidth <= 992);
            setMobile(window.innerWidth <= 768);
        };

        window.scrollTo(0, 0);
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('menu-change', handleMenuChangeEvent);
        window.addEventListener('amount-change', handleAmountChangeEvent);
        window.addEventListener('referral-change', handleReferralChangeEvent);
        window.addEventListener('password-update', handlePasswordUpdateEvent);
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('menu-change', handleMenuChangeEvent);
            window.removeEventListener('amount-change', handleAmountChangeEvent);
            window.removeEventListener('referral-change', handleReferralChangeEvent);
            window.removeEventListener('password-update', handlePasswordUpdateEvent);
            window.removeEventListener('resize', handleWindowResize);
            controller.abort();
        };
    }, [email, auth, baseUrl, showRecurring, fetchBadges]);

    const copyToClipboard = (string) => {
        clipboardCopy(string).then(() => {
            setIsCopied(true);
        }).catch((err) => {
            setIsCopied(false);
            console.error('Copy failed: ', err);
        });
    };

    const handleCopy = () => {
        copyToClipboard(linkShow);
        setTimeout(() => setIsCopied(false), 1500);
    };

    return (
        <div className={`side-gap ${tablet ? style.overlay : ''} ${menu === 'close' ? style.close : ''}`}>
            <div className={style.logo}>{getSVG.logo.gfdp}</div>
            <div className={`${style.donor} ${menu === 'close' ? style.wider : ''}`}>
                <div className={style['wide-wrapper']}>
                    <div className={style.header}>
                        <p>{currentDate}</p>
                        <h3>{text.title} {fullname}</h3>
                    </div>

                    <div className={style.body}>
                        <div className={style['grid-wrapper']}>
                            <div className={`${style.box} ${style.box1} ${!laptop ? style['grid-span-2'] : ''}`}>
                                <div className={style.user}>
                                    <img src={avatar} alt='avatar' loading='lazy' />
                                    <div className={style.bio}>
                                        <h5>{fullname}</h5>
                                        {first ? (
                                            <p>{currentBadge.title}</p>
                                        ) : (
                                            <p>-</p>
                                        )}
                                    </div>
                                </div>
                                <div className={style.summary}>
                                    <div className={style.wrapper}>
                                        <p>{achievement.badge}</p>
                                        {first ? (
                                            <h2>{badges.badgesCollected}</h2>
                                        ) : (
                                            <h2>-</h2>
                                        )}
                                    </div>
                                    <div className={style.wrapper}>
                                        <p>{achievement.point}</p>
                                        {first ? (
                                            <h2>{badges.userPoint?.toFixed(2)}</h2>
                                        ) : (
                                            <h2>-</h2>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={`${style.box} ${style.box2} ${!laptop ? style['grid-span-4'] : ''}`}>
                                <div className={style.achievement}>
                                    {first ? (
                                        <>
                                            <h6>{achievement.title}</h6>
                                            {badges.nextBadges ? (
                                                <p>{achievement.desc_1} <strong>{badges.badgesCollected} {achievement.badge}</strong>. {achievement.desc_2} <strong>{isNaN(nextPoint) ? 0 : nextPoint.toFixed(2)} {achievement.point}</strong> {achievement.desc_3} <strong>{badges.nextBadges.title}</strong></p>
                                            ) : (
                                                <p>{achievement.desc_completed}</p>
                                            )}
                                        </>
                                    ) : (
                                        <h6>{achievement.locked}</h6>
                                    )}
                                </div>
                                <div className={style.display}>
                                    {first ? (
                                        <>
                                            <div className={style.badges}>
                                                {displayBadge.map((badge, index) => (
                                                    <div className={style.badge} key={index}>
                                                        <h6 className={`${style['badge-title']} ${badge.isOpen ? style.unlocked : ''}`}>{badge.title}</h6>
                                                        <span className={`display-badge-icon ${badge.isOpen ? 'unlocked' : ''}`}>{badge.display}</span>
                                                        <span className={`${style.bullet} ${badge.isOpen ? style.unlocked : ''}`}></span>
                                                        <p className={`${style.point} ${badge.isOpen ? style.unlocked : ''}`}>{badge.pointMin} {achievement.point}</p>
                                                    </div>
                                                ))}

                                                <progress id="progress" className={`progress ${style.progress}`} value={isNaN(currentPoint) ? 0 : currentPoint} max={pointMax}></progress>
                                            </div>

                                            <Link to='/dashboard/badges' className={style.view}>
                                                <span>{getSVG.donor.arrowLG}</span>
                                                <span>{achievement.view}</span>
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            <ButtonPrimary onClick={() => setPasswordShow(true)}>Update Password</ButtonPrimary>
                                            <PasswordModal show={passwordShow} onHide={() => setPasswordShow(false)} />
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className={`${style.box} ${style.box3} ${!laptop ? style['grid-span-3'] : ''}`}>
                                <h6>{donor.boost.title}</h6>
                                <p>{donor.boost.subtitle}</p>
                                <Link className={style['link-button']} to='/donation/boost/form'>{donor.boost.button}</Link>

                                <span className={style['accent-boost']}>
                                    <img src={accentBoost} alt='boost' loading='lazy' />
                                </span>
                            </div>

                            <div className={`${style.box} ${style.box4} ${!laptop ? style['grid-span-3'] : ''}`}>
                                <h6>{donor.share.title}</h6>
                                <p>{donor.share.subtitle}</p>
                                <div className={style['input-group']}>
                                    <Input type="text" id="referral-read" className={`form-control`} value={shortenUrl(linkShow)} readOnly={true} disabled={true} />
                                    <button onClick={() => setReferralShow(true)}><span>{getSVG.sidebar.pen}</span> <span className={style['edit-text']}>{donor.share.edit}</span></button>
                                    <ReferralModal show={referralShow} onHide={() => setReferralShow(false)} />
                                </div>
                                <div className={style['button-group']}>
                                    <ButtonPrimary className={style.copy} onClick={handleCopy}>
                                        {isCopied ? (
                                            <div className={style.container}>
                                                <figure className={style.checkmark}></figure>
                                            </div>
                                        ) : (
                                            <><span>{getSVG.donor.copy}</span> {donor.share.copy}</>
                                        )}
                                    </ButtonPrimary>
                                    <ButtonTransparent onClick={() => setShareShow(true)}>{mobile ? slice(donor.share.social) : donor.share.social} <span>{getSVG.donor.arrowSM}</span></ButtonTransparent>
                                    <ShareModal show={shareShow} onHide={() => setShareShow(false)} />
                                </div>

                                <span className={style['accent-share']}>
                                    <img src={accentShare} alt='share' loading='lazy' />
                                </span>
                            </div>
                        </div>

                        {mobile && (
                            <div className={style.card}>
                                <span className='flex-shrink-0'>{getSVG.sidebar.card}</span>
                                <div className={style.content}>
                                    <p className={style.top}>{getText.sidebar.donation.recurring}</p>
                                    <div className={style.bottom}>
                                        <h4>Rp. {parseInt(recurring).toLocaleString('id-ID')}</h4>
                                        <span className={style.edit} onClick={() => setRecurringShow(true)}>{getSVG.sidebar.pen}</span>
                                    </div>
                                </div>
                                <RecurringModal show={recurringShow} onHide={() => setRecurringShow(false)} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DonorPage;