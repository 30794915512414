import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { contentTranslate, accentSVG, useAuth, getCurrentDate, capitalize } from '../../services';
import { ButtonPrimary, Input, getProfile, getUserType, logout, updatePassword, updateProfile, updateBankAccount, ButtonGrey } from '../../features';
import style from './profile.module.scss';
import avatarDefault from '../../assets/img/user.png';

const ProfilePage = () => {
    const navigate = useNavigate();
    const publicContent = process.env.REACT_APP_PUBLIC_CONTENT;
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [menu, setMenu] = useState(sessionStorage.getItem('menu'));
    const [tablet, setTablet] = useState(window.innerWidth <= 992);
    const userType = getUserType();
    const { auth } = useAuth();
    const user = auth ? getProfile() : null;
    const { fullname, avatar } = user
        ? { fullname: capitalize(user.fullname), avatar: user.avatar ? publicContent + user.avatar : avatarDefault }
        : { fullname: '', avatar: avatarDefault };
    const getText = contentTranslate(lang);
    const volunteer = getText.volunteer;
    const text = getText.dashboard;
    const currentDate = getCurrentDate(lang);
    const getSVG = accentSVG();
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditPass, setIsEditPass] = useState(false);
    const [isEditBank, setIsEditBank] = useState(false);
    const [formDonor, setFormDonor] = useState({
        fullname: user.fullname,
        email: user.email,
        phone_number: user.phone_number,
        address: user.address,
        avatar: null
    });
    const [formVolunteer, setFormVolunteer] = useState({
        first_name: userType === 'volunteer' ? user.first_name : '',
        last_name: userType === 'volunteer' ? user.last_name : '',
        email: userType === 'volunteer' ? user.email : '',
        phone: userType === 'volunteer' ? user.phone : '',
        private_information: user.private_information !== null ? {
            gender: userType === 'volunteer' ? user.private_information.gender : '',
            dob: userType === 'volunteer' ? user.private_information.dob : '',
            state: userType === 'volunteer' ? user.private_information.state : '',
            city: userType === 'volunteer' ? user.private_information.city : '',
            job: userType === 'volunteer' ? user.private_information.job : '',
            institution: userType === 'volunteer' ? user.private_information.institution : '',
        } : {
            gender: '',
            dob: '',
            state: '',
            city: '',
            job: '',
            institution: '',
        },
        social_media: userType === 'volunteer' && user.social_media !== null ? user.social_media : '',
        optional_information: user.optional_information !== null ? {
            hobby: userType === 'volunteer' ? user.optional_information.hobby : '',
            affiliation: userType === 'volunteer' ? user.optional_information.affiliation : '',
            motivation: userType === 'volunteer' ? user.optional_information.motivation : '',
        } : {
            hobby: '',
            affiliation: '',
            motivation: '',
        },
        avatar: null,
    });
    const [formBank, setFormBank] = useState({
        bank_account: {
            bank_name: userType === 'volunteer' ? JSON.parse(user.bank_account).bank_name : '',
            holder: userType === 'volunteer' ? JSON.parse(user.bank_account).holder : '',
            account_number: userType === 'volunteer' ? JSON.parse(user.bank_account).account_number : '',
            branch: userType === 'volunteer' ? JSON.parse(user.bank_account).branch : ''
        }
    });
    const [updatePass, setUpdatePass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [isPassValid, setIsPassValid] = useState(null);
    const [isConfirmValid, setIsConfirmValid] = useState(null);
    const [invalidPassMsg, setInvalidPassMsg] = useState('');
    const [invalidConfirmMsg, setInvalidConfirmMsg] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [passVisible, setPassVisible] = useState(false);
    const [confVisible, setConfVisible] = useState(false);

    useEffect(() => {
        const btnLoader = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        const handleMenuChangeEvent = () => {
            setMenu(sessionStorage.getItem('menu'));
        };

        const handleWindowResize = () => {
            setTablet(window.innerWidth <= 992);
        };

        window.scrollTo(0, 0);
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('menu-change', handleMenuChangeEvent);
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('menu-change', handleMenuChangeEvent);
            window.removeEventListener('resize', handleWindowResize);
            clearTimeout(btnLoader);
        };
    }, [isLoading]);

    const handleDonorChange = (e) => {
        const { name, value } = e.target;
        setFormDonor({
            ...formDonor,
            [name]: value,
        });
    }

    const handleDonorFileChange = (e) => {
        const file = e.target.files[0];
        setFormDonor({
            ...formDonor,
            avatar: file,
        });
    };

    const handleVolunteerChange = (e) => {
        const { name, value } = e.target;

        const updatedFormVolunteer = {
            ...formVolunteer,
            private_information: { ...formVolunteer.private_information },
            optional_information: { ...formVolunteer.optional_information },
        };

        if (name.startsWith('private_information.')) {
            const field = name.replace('private_information.', '');
            updatedFormVolunteer.private_information[field] = value;
        } else if (name.startsWith('optional_information.')) {
            const field = name.replace('optional_information.', '');
            updatedFormVolunteer.optional_information[field] = value;
        } else {
            updatedFormVolunteer[name] = value;
        }

        setFormVolunteer(updatedFormVolunteer);
    };

    const handleVolunteerFileChange = (e) => {
        const file = e.target.files[0];
        setFormVolunteer({
            ...formVolunteer,
            avatar: file,
        });
    };

    const validatePassword = (password) => {
        if (password.length < 1) {
            setInvalidPassMsg(text.profile.invalid.emptyPass);
            return false;
        }
        setInvalidPassMsg('');
        return true;
    };

    const validateConfirm = (password) => {
        if (password.length < 1) {
            setInvalidConfirmMsg(text.profile.invalid.emptyPass);
            return false;
        } else if (password !== updatePass) {
            setInvalidConfirmMsg(text.profile.invalid.unmatchedPass);
            return false;
        }
        return true;
    };

    const handleUpdatePass = (e) => {
        const newPass = e.target.value;
        setUpdatePass(newPass);
        setIsPassValid(validatePassword(newPass));
    };

    const handleConfirmPass = (e) => {
        const confirm = e.target.value;
        setConfirmPass(confirm);
        setIsConfirmValid(validateConfirm(confirm));
    };

    const handleInvalidPasswords = () => {
        if (isPassValid === null) {
            setIsPassValid(false)
            setInvalidPassMsg(text.profile.invalid.emptyPass);
            setIsEditPass(true);
        }
        if (isConfirmValid === null) {
            setIsConfirmValid(false)
            setInvalidConfirmMsg(text.profile.invalid.emptyPass);
            setIsEditPass(true);
        }
    };

    const handleBankChange = (e) => {
        const { name, value } = e.target;

        const updatedFormBank = {
            ...formBank,
            bank_account: { ...formBank.bank_account },
        };

        const nestedProperties = name.split('.');
        const field = nestedProperties[nestedProperties.length - 1];
        updatedFormBank.bank_account[field] = value;

        setFormBank(updatedFormBank);
    };

    const handleServerError = () => {
        navigate('/oops/500', { replace: true });
    };

    const dispatchEvent = () => {
        const profileChangeEvent = new Event('profile-change');
        window.dispatchEvent(profileChangeEvent);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const success = await updateProfile(userType === 'donor' ? formDonor : formVolunteer, userType);
            if (success) {
                dispatchEvent();
                setIsEdit(false);
                setIsSuccess(true);
                setToastMsg(text.profile.toast.profileSuccess);
            } else {
                setIsSuccess(false);
            }
            callToast();
        } catch (error) {
            handleServerError();
        } finally {
            setIsLoading(false);
        }
    };

    const handlePassSubmit = async (event) => {
        event.preventDefault();
        if (!isPassValid || !isConfirmValid) {
            handleInvalidPasswords();
            return;
        }
        setIsLoading(true);
        try {
            const success = await updatePassword(updatePass);
            if (success) {
                setUpdatePass('');
                setConfirmPass('');
                setIsEditPass(false);
                setIsSuccess(true);
                setToastMsg(text.profile.toast.passwordSuccess);
            } else {
                setIsSuccess(false);
            }
            callToast();
        } catch (error) {
            handleServerError();
        } finally {
            setIsLoading(false);
        }
    };

    const handleBankSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const success = await updateBankAccount(formBank);
            if (success) {
                setIsEditBank(false);
                setIsSuccess(true);
                setToastMsg(text.profile.toast.bankSuccess);
            } else {
                setIsSuccess(false);
            }
            callToast();
        } catch (error) {
            handleServerError();
        } finally {
            setIsLoading(false);
        }
    };

    const callToast = () => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const editToggle = () => {
        setIsEdit(!isEdit);
    };

    const editPassToggle = () => {
        setIsEditPass(!isEditPass);
        setUpdatePass('');
        setConfirmPass('');
        setIsPassValid(null);
        setIsConfirmValid(null);
        setPassVisible(false);
        setConfVisible(false);
    };

    const editBankToggle = () => {
        setIsEditBank(!isEditBank);
        setFormBank({
            bank_account: {
                bank_name: JSON.parse(user.bank_account).bank_name ? JSON.parse(user.bank_account).bank_name : '',
                holder: JSON.parse(user.bank_account).holder ? JSON.parse(user.bank_account).holder : '',
                account_number: JSON.parse(user.bank_account).account_number ? JSON.parse(user.bank_account).account_number : '',
                branch: JSON.parse(user.bank_account).branch ? JSON.parse(user.bank_account).branch : ''
            }
        });
    };

    const handlePassVisibility = () => {
        setPassVisible(!passVisible);
    };

    const handleConfVisibility = () => {
        setConfVisible(!confVisible);
    };

    return (
        <div className={`side-gap ${tablet ? style.overlay : ''} ${menu === 'close' ? style.close : ''}`}>
            <div className={style.logo}>{getSVG.logo.gfdp}</div>
            <div className={`${style.profile} ${menu === 'close' ? style.wider : ''}`}>
                <div className={style['wide-wrapper']}>
                    <div className={`${style.toast} ${showToast ? style.show : ''} ${isSuccess ? style.success : style.error}`}>
                        <div className={style['toast-icon']}>{isSuccess ? getSVG.dashboard.success : getSVG.dashboard.error}</div>
                        <div className={`${style.message} `}>
                            <h6>{isSuccess ? text.profile.toast.success : text.profile.toast.error}</h6>
                            <p>{isSuccess ? toastMsg : text.profile.toast.errorMsg}</p>
                        </div>
                    </div>
                    <div className={style.header}>
                        <span>
                            <p>{currentDate}</p>
                            <h3>{text.title} {fullname}</h3>
                        </span>
                        <Link onClick={logout} to='/login'>
                            <span>{text.logout}</span>
                            <span className={style.logout}>{getSVG.dashboard.logout}</span>
                        </Link>
                    </div>

                    <div className={style.body}>
                        <div className={style['form-wrapper']}>
                            <h6>{text.profile.title}</h6>

                            <form className={style['form-profile']} noValidate>
                                <div className={style.avatar}>
                                    <img src={avatar} alt='avatar' loading='lazy' />
                                </div>

                                <div className={style['form-section']}>
                                    {userType !== 'donor' && (<h6>{text.profile.section.title_1}</h6>)}
                                    <div className={style['input-section']}>
                                        {userType === 'donor' ? (
                                            <div className={style['input-group']}>
                                                <label className="form-label" htmlFor="fullname">{text.profile.label.fullname}</label>
                                                <Input type="text" id="fullname" name="fullname" value={formDonor.fullname} onChange={handleDonorChange} className={`form-control`} placeholder={text.profile.placeholder.fullname} disabled={!isEdit} />
                                            </div>
                                        ) : (
                                            <>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="firstname">{text.profile.label.firstname}</label>
                                                    <Input type="text" id="firstname" name="first_name" value={formVolunteer.first_name} onChange={handleVolunteerChange} className={`form-control`} placeholder={text.profile.placeholder.firstname} disabled={!isEdit} />
                                                </div>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="lastname">{text.profile.label.lastname}</label>
                                                    <Input type="text" id="lastname" name="last_name" value={formVolunteer.last_name} onChange={handleVolunteerChange} className={`form-control`} placeholder={text.profile.placeholder.lastname} disabled={!isEdit} />
                                                </div>
                                            </>
                                        )}

                                    </div>

                                    <div className={style['input-section']}>
                                        <div className={style['input-group']}>
                                            <label className="form-label" htmlFor="phone">{text.profile.label.phone}</label>
                                            {userType === 'donor' ? (
                                                <div className="input-group">
                                                    <span className={`input-group-text ${style.custom}`}>+62</span>
                                                    <Input type="number" id="phone" name="phone_number" value={formDonor.phone_number} onChange={handleDonorChange} className={`form-control ${style['input-other']}`} placeholder={text.profile.placeholder.phone} disabled={!isEdit} />
                                                </div>
                                            ) : (
                                                <div className="input-group">
                                                    <span className={`input-group-text ${style.custom}`}>+62</span>
                                                    <Input type="number" id="phone" name="phone" value={formVolunteer.phone} onChange={handleVolunteerChange} className={`form-control ${style['input-other']}`} placeholder={text.profile.placeholder.phone} disabled={!isEdit} />
                                                </div>
                                            )}
                                        </div>
                                        <div className={style['input-group']}>
                                            <label className="form-label" htmlFor="email">{text.profile.label.email}</label>
                                            <Input type="email" id="email" name="email" value={formDonor.email} onChange={handleDonorChange} className={`form-control`} placeholder={text.profile.placeholder.email} disabled={true} />
                                        </div>
                                    </div>

                                    {userType === 'donor' && (
                                        <>
                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="address">{text.profile.label.address}</label>
                                                    <Input type="text" id="address" name="address" onChange={handleDonorChange} value={formDonor.address} className={`form-control`} placeholder={text.profile.placeholder.address} disabled={!isEdit} />
                                                </div>
                                            </div>

                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="avatar">Avatar</label>
                                                    <Input type="file" id="avatar" name="avatar" onChange={handleDonorFileChange} className={`form-control`} disabled={!isEdit} />
                                                    <span className={`text-muted ${style.note}`}>{text.profile.label.note}</span>
                                                </div>
                                            </div>

                                            <div className={style['button-group']}>
                                                {!isEdit && (
                                                    <ButtonPrimary onClick={editToggle}>{text.profile.button.edit}</ButtonPrimary>
                                                )}
                                                {isEdit && (
                                                    <>
                                                        <ButtonGrey onClick={editToggle}>{text.profile.button.cancel}</ButtonGrey>
                                                        <ButtonPrimary type="submit" onClick={handleSubmit} className={`${isLoading ? 'temp-disabled' : ''}`}>
                                                            {isLoading ? (
                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            ) : (
                                                                text.profile.button.save
                                                            )}
                                                        </ButtonPrimary>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>

                                {userType !== 'donor' && (
                                    <>
                                        <div className={style['form-section']}>
                                            <h6>{text.profile.section.title_2}</h6>
                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="gender">{volunteer.form.label.sex}</label>
                                                    <select className={`form-select form-control ${style['custom-select']}`} id="gender" name="private_information.gender" value={formVolunteer.private_information.gender} onChange={handleVolunteerChange} disabled={!isEdit}>
                                                        <option value={''}>{volunteer.form.placeholder.sex}</option>
                                                        <option value={'Laki-laki'}>{volunteer.form.placeholder.m}</option>
                                                        <option value={'Perempuan'}>{volunteer.form.placeholder.f}</option>
                                                    </select>
                                                </div>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="birthdate">{volunteer.form.label.birth}</label>
                                                    <Input type="date" id="birthdate" name="private_information.dob" className={`form-control`} placeholder={volunteer.form.placeholder.birth} value={formVolunteer.private_information.dob} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                            </div>

                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="province">{volunteer.form.label.province}</label>
                                                    <Input type="text" id="province" name="private_information.state" className={`form-control`} placeholder={volunteer.form.placeholder.province} value={formVolunteer.private_information.state} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="city">{volunteer.form.label.city}</label>
                                                    <Input type="text" id="city" name="private_information.city" className={`form-control`} placeholder={volunteer.form.placeholder.city} value={formVolunteer.private_information.city} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                            </div>

                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="occupation">{volunteer.form.label.occupation}</label>
                                                    <Input type="text" id="occupation" name="private_information.job" className={`form-control`} placeholder={volunteer.form.placeholder.occupation} value={formVolunteer.private_information.job} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="institution">{volunteer.form.label.institution}</label>
                                                    <Input type="text" id="institution" name="private_information.institution" className={`form-control`} placeholder={volunteer.form.placeholder.institution} value={formVolunteer.private_information.institution} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style['form-section']}>
                                            <h6>{text.profile.section.title_3}</h6>
                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="instagram">{volunteer.form.label.instagram}</label>
                                                    <Input type="text" id="instagram" name="social_media" className={`form-control`} placeholder={volunteer.form.placeholder.instagram} value={formVolunteer.social_media} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style['form-section']}>
                                            <h6>{text.profile.section.title_4}</h6>

                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="hobby">{volunteer.form.label.hobby}</label>
                                                    <Input type="text" id="hobby" name="optional_information.hobby" className={`form-control`} placeholder={volunteer.form.placeholder.hobby} value={formVolunteer.optional_information.hobby} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="source">{volunteer.form.label.source}</label>
                                                    <Input type="text" id="source" name="optional_information.affiliation" className={`form-control`} placeholder={volunteer.form.placeholder.source} value={formVolunteer.optional_information.affiliation} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                            </div>

                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="motivation">{volunteer.form.label.motivation}</label>
                                                    <Input type="text" id="motivation" name="optional_information.motivation" className={`form-control`} placeholder={volunteer.form.placeholder.motivation} value={formVolunteer.optional_information.motivation} onChange={handleVolunteerChange} disabled={!isEdit} />
                                                </div>
                                            </div>

                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="avatar">Avatar</label>
                                                    <Input type="file" id="avatar" name="avatar" onChange={handleVolunteerFileChange} className={`form-control`} disabled={!isEdit} />
                                                    <span className={`text-muted ${style.note}`}>{text.profile.label.note}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style['button-group']}>
                                            {!isEdit && (
                                                <ButtonPrimary onClick={editToggle}>{text.profile.button.edit}</ButtonPrimary>
                                            )}
                                            {isEdit && (
                                                <>
                                                    <ButtonGrey onClick={editToggle}>{text.profile.button.cancel}</ButtonGrey>
                                                    <ButtonPrimary type="submit" onClick={handleSubmit} className={`${isLoading ? 'temp-disabled' : ''}`}>
                                                        {isLoading ? (
                                                            <div className="spinner-border spinner-border-sm" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        ) : (
                                                            text.profile.button.save
                                                        )}
                                                    </ButtonPrimary>
                                                </>
                                            )}
                                        </div>

                                        <div className={style['form-section']}>
                                            <h6>{text.profile.section.title_5}</h6>
                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="bank_name">{text.profile.label.bank}</label>
                                                    <Input type="text" id="bank_name" name="bank_account.bank_name" className={`form-control`} placeholder={text.profile.placeholder.bank} value={formBank.bank_account.bank_name || ''} onChange={handleBankChange} disabled={!isEditBank} />
                                                </div>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="account_number">{text.profile.label.account}</label>
                                                    <Input type="number" id="account_number" name="bank_account.account_number" value={formBank.bank_account.account_number || ''} onChange={handleBankChange} className={`form-control ${style['input-other']} ${style['stand-alone']}`} placeholder={text.profile.placeholder.account} disabled={!isEditBank} />
                                                </div>
                                            </div>

                                            <div className={style['input-section']}>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="holder">{text.profile.label.holder}</label>
                                                    <Input type="text" id="holder" name="bank_account.holder" className={`form-control`} placeholder={text.profile.placeholder.holder} value={formBank.bank_account.holder || ''} onChange={handleBankChange} disabled={!isEditBank} />
                                                </div>
                                                <div className={style['input-group']}>
                                                    <label className="form-label" htmlFor="branch">{text.profile.label.branch}</label>
                                                    <Input type="text" id="branch" name="bank_account.branch" className={`form-control`} placeholder={text.profile.placeholder.branch} value={formBank.bank_account.branch || ''} onChange={handleBankChange} disabled={!isEditBank} />
                                                </div>
                                            </div>

                                            <div className={style['button-group']}>
                                                {!isEditBank && (
                                                    <ButtonPrimary onClick={editBankToggle}>{text.profile.button.edit}</ButtonPrimary>
                                                )}
                                                {isEditBank && (
                                                    <>
                                                        <ButtonGrey onClick={editBankToggle}>{text.profile.button.cancel}</ButtonGrey>
                                                        <ButtonPrimary type="submit" onClick={handleBankSubmit} className={`${isLoading ? 'temp-disabled' : ''}`}>
                                                            {isLoading ? (
                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            ) : (
                                                                text.profile.button.save
                                                            )}
                                                        </ButtonPrimary>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={style['form-section']}>
                                    <div className={style['input-section']}>
                                        <div className={style['input-group']}>
                                            <label className="form-label" htmlFor="password">{text.profile.label.password}</label>
                                            <Input type={passVisible ? 'text' : 'password'} id="password" name="password" value={updatePass} onChange={handleUpdatePass} className={`form-control ${isPassValid === false ? 'is-invalid' : ''}`} placeholder={text.profile.placeholder.password} disabled={!isEditPass} />
                                            <span className={`${style.visibility} ${isEditPass ? (isPassValid || isPassValid === null ? '' : style.hidden) : style.hidden}`} onClick={handlePassVisibility}>
                                                {passVisible ? getSVG.login.show : getSVG.login.hide}
                                            </span>
                                            {invalidPassMsg !== '' ? (
                                                <span className="invalid-feedback">{invalidPassMsg}</span>
                                            ) : (
                                                <span className={`text-muted ${style.note}`}>{text.profile.label.note}</span>
                                            )}
                                        </div>
                                        <div className={style['input-group']}>
                                            <label className="form-label" htmlFor="confirm">{text.profile.label.confirm}</label>
                                            <Input type={confVisible ? 'text' : 'password'} id="confirm" name="confirm" value={confirmPass} onChange={handleConfirmPass} className={`form-control ${isConfirmValid === false ? 'is-invalid' : ''}`} placeholder={text.profile.placeholder.confirm} disabled={!isEditPass} />
                                            <span className={`${style.visibility} ${isEditPass ? (isConfirmValid || isConfirmValid === null ? '' : style.hidden) : style.hidden}`} onClick={handleConfVisibility}>
                                                {confVisible ? getSVG.login.show : getSVG.login.hide}
                                            </span>
                                            <span className="invalid-feedback">{invalidConfirmMsg}</span>
                                        </div>
                                    </div>

                                    <div className={style['button-group']}>
                                        {!isEditPass && (
                                            <ButtonPrimary onClick={editPassToggle}>{text.profile.button.edit}</ButtonPrimary>
                                        )}
                                        {isEditPass && (
                                            <>
                                                <ButtonGrey onClick={editPassToggle}>{text.profile.button.cancel}</ButtonGrey>
                                                <ButtonPrimary type="submit" onClick={handlePassSubmit} className={`${isLoading ? 'temp-disabled' : ''}`}>
                                                    {isLoading ? (
                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        text.profile.button.save
                                                    )}
                                                </ButtonPrimary>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;