import React from 'react';
import style from './button.module.scss';

const Primary = React.forwardRef(({ type, children, onClick, className, onKeyDown, tabIndex }, ref) => {
  return (
    <button type={type} className={`${style.primary} ${className}`} onClick={onClick} onKeyDown={onKeyDown} tabIndex={tabIndex} ref={ref}>
      {children}
    </button>
  );
});

export default Primary;