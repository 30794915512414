export function getNavigation(lang) {
    const navigation = {
        id: [
            {
                title: '10.10.10',
                nav: '#ten',
                section: true,
            },
            {
                title: 'Beranda',
                nav: '/',
                section: false,
            },
            {
                title: 'Kampanye',
                nav: '#campaigns',
                section: true,
            },
            {
                title: 'Volunteer',
                nav: '/volunteer',
                section: false,
            },
        ],
        en: [
            {
                title: '10.10.10',
                nav: '#ten',
                section: true,
            },
            {
                title: 'Home',
                nav: '/',
                section: false,
            },
            {
                title: 'Campaign',
                nav: '#campaigns',
                section: true,
            },
            {
                title: 'Volunteer',
                nav: '/volunteer',
                section: false,
            },
        ],
    };

    return navigation[lang];
}

export function navDashboard(lang) {
    const navigation = {
        id: [
            {
                title: 'Dasbor',
                link: '/dashboard/donor',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M5 9.2H8V19H5V9.2ZM10.6 5H13.4V19H10.6V5ZM16.2 13H19V19H16.2V13Z" fill="#00B5FF"/></svg>,
                user: 'donor'
            },
            {
                title: 'Lencana',
                link: '/dashboard/badges',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M7 10C4.79 10 3 11.79 3 14C3 16.21 4.79 18 7 18C9.21 18 11 16.21 11 14C11 11.79 9.21 10 7 10ZM7 16C5.9 16 5 15.1 5 14C5 12.9 5.9 12 7 12C8.1 12 9 12.9 9 14C9 15.1 8.1 16 7 16ZM15.01 15C13.36 15 12.01 16.35 12.01 18C12.01 19.65 13.36 21 15.01 21C16.66 21 18.01 19.65 18.01 18C18.01 16.35 16.66 15 15.01 15ZM15.01 19C14.46 19 14.01 18.55 14.01 18C14.01 17.45 14.46 17 15.01 17C15.56 17 16.01 17.45 16.01 18C16.01 18.55 15.56 19 15.01 19ZM16.5 3C13.47 3 11 5.47 11 8.5C11 11.53 13.47 14 16.5 14C19.53 14 22 11.53 22 8.5C22 5.47 19.53 3 16.5 3ZM16.5 12C14.57 12 13 10.43 13 8.5C13 6.57 14.57 5 16.5 5C18.43 5 20 6.57 20 8.5C20 10.43 18.43 12 16.5 12Z" fill="#A6A6A6"/></svg>,
                user: 'donor'
            },
            // {
            //     title: 'Riwayat',
            //     link: '/dashboard/history',
            //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.25 15.52L17.02 14.24L13.5 12.15V8H12Z" fill="#A6A6A6"/></svg>,
            //     user: 'donor'
            // },
            {
                title: 'Halaman Utama',
                link: '/',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69ZM12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z" fill="#A6A6A6"/></svg>,
                user: 'donor'
            },
            {
                title: 'Dasbor',
                link: '/dashboard/volunteer',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19 5V7H15V5H19ZM9 5V11H5V5H9ZM19 13V19H15V13H19ZM9 17V19H5V17H9ZM21 3H13V9H21V3ZM11 3H3V13H11V3ZM21 11H13V21H21V11ZM11 15H3V21H11V15Z" fill="#323232"/></svg>,
                user: 'volunteer'
            },
            {
                title: 'Pencapaian',
                link: '/dashboard/incentive',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M20 6H17.82C17.93 5.69 18 5.35 18 5C18 3.34 16.66 2 15 2C13.95 2 13.04 2.54 12.5 3.35L12 4.02L11.5 3.34C10.96 2.54 10.05 2 9 2C7.34 2 6 3.34 6 5C6 5.35 6.07 5.69 6.18 6H4C2.89 6 2.01 6.89 2.01 8L2 19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V8C22 6.89 21.11 6 20 6ZM15 4C15.55 4 16 4.45 16 5C16 5.55 15.55 6 15 6C14.45 6 14 5.55 14 5C14 4.45 14.45 4 15 4ZM9 4C9.55 4 10 4.45 10 5C10 5.55 9.55 6 9 6C8.45 6 8 5.55 8 5C8 4.45 8.45 4 9 4ZM20 19H4V17H20V19ZM20 14H4V8H9.08L7 10.83L8.62 12L11 8.76L12 7.4L13 8.76L15.38 12L17 10.83L14.92 8H20V14Z" fill="#323232"/></svg>,
                user: 'volunteer'
            },
            {
                title: 'Peringkat',
                link: '/dashboard/leaderboard',
                icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_935_7557)"><path d="M16 11V3H8V9H2V21H22V11H16ZM10 5H14V19H10V5ZM4 11H8V19H4V11ZM20 19H16V13H20V19Z" fill="#323232"/></g><defs><clipPath id="clip0_935_7557"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>,
                user: 'volunteer'
            }
        ],
        en: [
            {
                title: 'Dashboard',
                link: '/dashboard/donor',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M5 9.2H8V19H5V9.2ZM10.6 5H13.4V19H10.6V5ZM16.2 13H19V19H16.2V13Z" fill="#00B5FF"/></svg>,
                user: 'donor'
            },
            {
                title: 'Badges',
                link: '/dashboard/badges',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M7 10C4.79 10 3 11.79 3 14C3 16.21 4.79 18 7 18C9.21 18 11 16.21 11 14C11 11.79 9.21 10 7 10ZM7 16C5.9 16 5 15.1 5 14C5 12.9 5.9 12 7 12C8.1 12 9 12.9 9 14C9 15.1 8.1 16 7 16ZM15.01 15C13.36 15 12.01 16.35 12.01 18C12.01 19.65 13.36 21 15.01 21C16.66 21 18.01 19.65 18.01 18C18.01 16.35 16.66 15 15.01 15ZM15.01 19C14.46 19 14.01 18.55 14.01 18C14.01 17.45 14.46 17 15.01 17C15.56 17 16.01 17.45 16.01 18C16.01 18.55 15.56 19 15.01 19ZM16.5 3C13.47 3 11 5.47 11 8.5C11 11.53 13.47 14 16.5 14C19.53 14 22 11.53 22 8.5C22 5.47 19.53 3 16.5 3ZM16.5 12C14.57 12 13 10.43 13 8.5C13 6.57 14.57 5 16.5 5C18.43 5 20 6.57 20 8.5C20 10.43 18.43 12 16.5 12Z" fill="#A6A6A6"/></svg>,
                user: 'donor'
            },
            // {
            //     title: 'History',
            //     link: '/dashboard/history',
            //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.25 15.52L17.02 14.24L13.5 12.15V8H12Z" fill="#A6A6A6"/></svg>,
            //     user: 'donor'
            // },
            {
                title: 'Landing Page',
                link: '/',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69ZM12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z" fill="#A6A6A6"/></svg>,
                user: 'donor'
            },
            {
                title: 'Dashboard',
                link: '/dashboard/volunteer',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19 5V7H15V5H19ZM9 5V11H5V5H9ZM19 13V19H15V13H19ZM9 17V19H5V17H9ZM21 3H13V9H21V3ZM11 3H3V13H11V3ZM21 11H13V21H21V11ZM11 15H3V21H11V15Z" fill="#323232"/></svg>,
                user: 'volunteer'
            },
            {
                title: 'Incentive',
                link: '/dashboard/incentive',
                icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M20 6H17.82C17.93 5.69 18 5.35 18 5C18 3.34 16.66 2 15 2C13.95 2 13.04 2.54 12.5 3.35L12 4.02L11.5 3.34C10.96 2.54 10.05 2 9 2C7.34 2 6 3.34 6 5C6 5.35 6.07 5.69 6.18 6H4C2.89 6 2.01 6.89 2.01 8L2 19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V8C22 6.89 21.11 6 20 6ZM15 4C15.55 4 16 4.45 16 5C16 5.55 15.55 6 15 6C14.45 6 14 5.55 14 5C14 4.45 14.45 4 15 4ZM9 4C9.55 4 10 4.45 10 5C10 5.55 9.55 6 9 6C8.45 6 8 5.55 8 5C8 4.45 8.45 4 9 4ZM20 19H4V17H20V19ZM20 14H4V8H9.08L7 10.83L8.62 12L11 8.76L12 7.4L13 8.76L15.38 12L17 10.83L14.92 8H20V14Z" fill="#323232"/></svg>,
                user: 'volunteer'
            },
            {
                title: 'Leaderboard',
                link: '/dashboard/leaderboard',
                icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_935_7557)"><path d="M16 11V3H8V9H2V21H22V11H16ZM10 5H14V19H10V5ZM4 11H8V19H4V11ZM20 19H16V13H20V19Z" fill="#323232"/></g><defs><clipPath id="clip0_935_7557"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>,
                user: 'volunteer'
            }
        ],
    };

    return navigation[lang];
}