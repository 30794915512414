import { useState, useEffect } from 'react';
import style from './card.module.scss';

const ScrollShadow = ({ children, className }) => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`${isScrolled ? style['scroll-shadow'] : ''} ${className}`}>
            {children}
        </div>
    );
}

export default ScrollShadow;
