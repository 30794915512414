import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaHelmet = ({ title, description, keywords, image, url }) => {
    return (
        <Helmet>
            <title>{title}</title>

            {process.env.REACT_APP_ENV !== 'development' && (
                <>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                    <meta property="og:url" content={url} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={image} />
                </>
            )}

        </Helmet>
    );
}

export default MetaHelmet;