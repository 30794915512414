import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { nominalAvailable, contentTranslate, accentSVG, formatIDR, equivalency } from '../../../../services';
import { ButtonPrimary, ButtonGreen, Check } from '..';
import style from './donation.module.scss';
import eqmonthly from '../../../../assets/img/eq-monthly.jpeg';
import eqsingle from '../../../../assets/img/eq-single.jpeg';

const DonationCard = ({ className }) => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [donationType, setDonationType] = useState('monthly');
    const [nominals, setNominals] = useState([]);
    const [selectedNominal, setSelectedNominal] = useState('');
    const [otherAmount, setOtherAmount] = useState('');
    const [otherPass, setOtherPass] = useState('other');
    const [other, setOther] = useState(false);
    const [isAgreeChecked, setIsAgreeChecked] = useState(null);
    const [uncheckedMsg, setUncheckedMsg] = useState('');
    const [check, setCheck] = useState(false);
    const getText = contentTranslate(lang);
    const text = getText.donationCard.equivalency;
    const getSVG = accentSVG();
    const [isAmountValid, setIsAmountValid] = useState(null);
    const [isNominalValid, setIsNominalValid] = useState(null);
    const [form, setForm] = useState({
        donation: 'monthly',
        amount: 10000
    });

    const [equivalent, setEquivalent] = useState(0);
    const [eqObject, setEqObject] = useState([]);

    const handleLangChangeEvent = useCallback(() => {
        const newLang = sessionStorage.getItem('gfdp-lang');
        setLang(newLang);
    }, []);

    const fetchNominals = useCallback(() => {
        setNominals(nominalAvailable(donationType));
    }, [donationType]);

    const parsing = useCallback(() => {
        if (parseInt(otherAmount) === 0) {
            setOtherPass('other');
        }
    }, [otherAmount]);

    const fetchEquivalency = useCallback(() => {
        setEqObject(equivalency(equivalent));
    }, [equivalent]);

    useEffect(() => {
        fetchNominals();
        fetchEquivalency();
        parsing();

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        };
    }, [navigate, handleLangChangeEvent, fetchNominals, fetchEquivalency, parsing]);

    const scrollToSection = (sectionId) => {
        const sectionElement = document.querySelector(sectionId);
        if (sectionElement) {
            const offset = 90;
            const scrollToOptions = {
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            };
            window.scrollTo({
                top: sectionElement.getBoundingClientRect().top + window.scrollY - offset,
                ...scrollToOptions,
            });
        }
    };

    const handleSectionLinkClick = (event, sectionId) => {
        event.preventDefault();
        scrollToSection(sectionId);
    };

    const handleTypeChange = (event) => {
        const newValue = event.target.value;
        let defaultAmount = 0;
        if (newValue === 'monthly') {
            defaultAmount = 10000;
        } else if (newValue === 'single') {
            defaultAmount = 50000;
        }
        setDonationType(newValue);
        setSelectedNominal('');
        setOtherAmount('');
        setOther(false);
        setIsAmountValid(null)
        setIsNominalValid(null);
        setForm({ ...form, donation: newValue, amount: defaultAmount });
    };

    const handleCheck = () => {
        setCheck(!check);
        const agree = !check;
        if (agree) {
            setIsAgreeChecked(true);
            return;
        }
        setIsAgreeChecked(false);
        setUncheckedMsg(getText.donationCard.agree);
    };

    const validateAmount = (amount) => {
        return amount >= 10000;
    };

    const validateAmountSingle = (amount) => {
        return amount >= 50000;
    };

    const idr = (amount) => {
        return formatIDR(amount);
    };

    const handleNominal = (event) => {
        const amount = event.target.value;
        let selectedAmount;
        if (amount === 'other') {
            setOther(true);
            selectedAmount = selectedNominal;
            setSelectedNominal('');
        } else {
            setOther(false);
            setSelectedNominal(amount);
            selectedAmount = amount;
        }
        // setOtherAmount('other');
        setIsAmountValid(true);
        setIsNominalValid(true);
        setOtherAmount(idr(selectedAmount));
        setEquivalent(parseInt(selectedAmount));
        setForm({ ...form, amount: parseInt(selectedAmount) });
    };

    const handleInputOther = (event) => {
        const amountOther = event.target.value;
        const numericValue = amountOther.replace(/\D/g, '');
        setForm({ ...form, amount: parseInt(numericValue) });
        setIsNominalValid(true);
        setIsAmountValid(donationType === 'single' ? validateAmountSingle(numericValue) : validateAmount(numericValue));
        setEquivalent(parseInt(numericValue))
        setOtherAmount(idr(numericValue));
        setOther(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (form.amount === 0) {
            setIsAmountValid(false);
            return;
        }
        if (!isAmountValid) {
            setIsNominalValid(false);
            return;
        }
        if (!isAgreeChecked) {
            if (isAgreeChecked === null) {
                setIsAgreeChecked(false);
                setUncheckedMsg(getText.donationCard.agree);
            }
            return;
        }
        setIsAmountValid(null);
        localStorage.setItem('donation', JSON.stringify(form));
        if (form.donation === 'monthly') {
            navigate('/donation/monthly');
            return;
        }
        navigate('/donation/single/form');
    };

    const [eqIcons, setEqIcons] = useState([]);
    const [eqValue, setEqValue] = useState('');
    const [eqAmount, setEqAmount] = useState(0);

    useEffect(() => {
        const eqObject = equivalency(equivalent);
        if (eqObject && eqObject.icons) {
            setEqIcons(Array.from({ length: eqObject.icons }));
            setEqAmount(eqObject.icons);
            setEqValue(donationType === 'monthly' ? eqObject.monthly : eqObject.single);
        } else {
            setEqIcons([]);
            setEqAmount(0);
            setEqValue('')
        }
    }, [equivalent, donationType]);

    return (
        <div className={`${style['card-wrapper']} ${className}`}>
            <div className={style['card-content']}>
                <div className={style['form-wrapper']}>
                    <form className={style['form-donation-card']} noValidate onSubmit={handleSubmit}>
                        <div className={style['donation-type']}>
                            <div className={style['donation-check']}>
                                <input
                                    type="radio"
                                    className={`gfdp-duration-monthly ${style.type} ${style.monthly}`}
                                    name="donation_type"
                                    id="monthly"
                                    value="monthly"
                                    autoComplete="off"
                                    checked={donationType === 'monthly'}
                                    onChange={handleTypeChange}
                                />
                                <label className={style['button-check']} htmlFor="monthly">{getText.donationCard.monthly}</label>
                            </div>
                            <div className={style['donation-check']}>
                                <input
                                    type="radio"
                                    className={`gfdp-duration-single ${style.type} ${style.single}`}
                                    name="donation_type"
                                    id="single"
                                    value="single"
                                    autoComplete="off"
                                    checked={donationType === 'single'}
                                    onChange={handleTypeChange}
                                />
                                <label className={style['button-check']} htmlFor="single">{getText.donationCard.single}</label>
                            </div>
                        </div>

                        {donationType === 'monthly' && (
                            <p className={style.subtitle}>{getText.donationCard.subtitle}</p>
                        )}

                        <div className={style['donation-amount']}>
                            <div className={style['nominal-wrapper']}>
                                {nominals.map(item => (
                                    <div className={`
                                    ${donationType === 'monthly' ? style['btn-monthly'] : style['btn-single']}
                                    ${parseInt(selectedNominal) === item.nominal ? style.checked : ''} 
                                    ${style['nominal-button-wrapper']}`}
                                        key={item.nominal}>
                                        <input
                                            type="radio"
                                            className={`${style.nominal} ${item.gaClass}`}
                                            name="amount_option"
                                            id={`donate${item.nominal}`}
                                            value={item.nominal}
                                            autoComplete="off"
                                            checked={parseInt(selectedNominal) === item.nominal}
                                            onChange={handleNominal}
                                        />
                                        <label className={style['button-nominal']} htmlFor={`donate${item.nominal}`}>{item.nominalText}</label>
                                    </div>
                                ))}

                                <div className={`
                                ${donationType === 'monthly' ? style['btn-monthly'] : style['btn-single']} 
                                ${donationType === 'monthly' ? style['grid-span-2'] : style['grid-span-3']} 
                                ${other === true ? style.checked : ''} 
                                ${style['nominal-button-wrapper']}`}>
                                    <input
                                        type="radio"
                                        className={`${style.nominal}`}
                                        name="amount_option"
                                        id="donateOther"
                                        value={otherPass}
                                        autoComplete="off"
                                        checked={other === true}
                                        onChange={handleNominal}
                                    />
                                    <label className={style['button-nominal']} htmlFor="donateOther">{getText.donationCard.other}</label>
                                </div>
                            </div>

                            {other && (
                                <div className={style['other-amount']}>
                                    <label htmlFor="final-amount">{getText.donationCard.other}</label>
                                    <div className="input-group">
                                        <span className={`input-group-text ${style.custom}`}>Rp.</span>
                                        <input type="text" className={`form-control gfdp-nominal-jumlah_lainnya ${style['input-other']} ${isAmountValid === false ? 'is-invalid' : ''}`} name="other_amount" id="final-amount" value={otherAmount} onChange={handleInputOther} />
                                        <span className="invalid-feedback">{donationType === 'single' ? getText.donationCard.amount50 : getText.donationCard.amount10}</span>
                                    </div>
                                </div>
                            )}

                            {donationType === 'monthly' && selectedNominal !== '' && (
                                <p className={`${style.subtitle}`}>
                                    {getText.donationCard.note1} {idr(selectedNominal)} {getText.donationCard.note2}
                                </p>
                            )}
                        </div>

                        <span className={`${style['custom-invalid']} ${isNominalValid === false ? style.invalid : ''}`}>{getText.donationCard.invalid}</span>

                        {(selectedNominal || other) && ((donationType === 'monthly' && equivalent >= 150000) || (donationType === 'single' && equivalent >= 200000)) && eqObject && (
                            <div className={`${style.equivalent}`}>
                                <div className={style['eq-image']}><img src={donationType === 'monthly' ? eqmonthly : eqsingle} alt="kelola sampah" loading="lazy" /></div>
                                <div className={`${style['eq-description']} ${style['grid-span-2']}`}>
                                    <div className={style['eq-icons']}>
                                        {eqIcons.map((_, index) => (
                                            <span key={index} className={style['eq-icon']}>
                                                {donationType === 'monthly' ? (
                                                    getSVG.donation.truck
                                                ) : (
                                                    getSVG.donation.waste
                                                )}
                                            </span>
                                        ))}
                                    </div>
                                    <p className={style['eq-text']}>{text.manage} {eqValue} {donationType === 'monthly' ? `${text.wasteMonthly}/${text.monthly}` : `${text.wasteSingle}/${text.single}`}!</p>
                                    <p className={style['eq-text']}>{donationType === 'monthly' ? `${text.descMonthly1} ${eqAmount} ${text.descMonthly2}` : `${text.descSingle1} ${eqAmount} ${text.descSingle2}`}</p>
                                </div>
                            </div>
                        )}

                        <div className={`form-check ${style.terms}`}>
                            <Check id="tnc" name="tnc" type="checkbox" className={`form-check-input ${isAgreeChecked === false ? 'is-invalid' : ''}`} onChange={handleCheck} checked={check} />
                            <label className="form-check-label" htmlFor="tnc">
                                {getText.donationCard.terms} <Link to="/terms-and-conditions">{getText.donationCard.termsLink}</Link>
                            </label>
                            <span className="invalid-feedback">{uncheckedMsg}</span>
                        </div>

                        {donationType === 'monthly' ?
                            <ButtonPrimary type="submit">{getText.donationCard.button}</ButtonPrimary>
                            :
                            <ButtonGreen type="submit">{getText.donationCard.button}</ButtonGreen>
                        }

                        <div className={style.help}>
                            <a href="#contacts" onClick={(e) => handleSectionLinkClick(e, '#contacts')}>
                                <span>{getSVG.donation.help}</span>
                                {getText.donationCard.help}
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DonationCard;
