import style from './text-field.module.scss';

const Input = ({ id, name, type, value, onChange, className, placeholder, disabled, readOnly }) => {
    return (
        <input
            id={id}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            className={`${style['custom-input']} ${className}`}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
        />
    );
};

export default Input;