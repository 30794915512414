import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { nominalAvailable, paymentMethods, contentTranslate, accentSVG, donationProcess, formatIDR } from '../../services';
import { ButtonGreen, ButtonPrimary, getProfile, Input } from '../../features';
import style from './donation.module.scss';

const DonationPage = () => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const donation = getText.donation;
    const getSVG = accentSVG();
    const location = useLocation();
    const match = location.pathname.match(/donation\/([^/]+)/);
    const type = match ? match[1] : null;
    const getmonthlyType = location.pathname.match(/\/donation\/[^/]+\/form\/(recurring|reminder)/);
    const monthlyType = getmonthlyType ? getmonthlyType[1] : null;
    const [nominals, setNominals] = useState([]);
    const [otherAmount, setOtherAmount] = useState('');
    const [selectedNominal, setSelectedNominal] = useState(type === 'single' ? '50000' : '10000');
    const [otherPass, setOtherPass] = useState('other');
    const [other, setOther] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const payments = paymentMethods(type, monthlyType);
    const getLocal = JSON.parse(localStorage.getItem('donation'));
    const localAmount = getLocal?.amount;
    const isReferral = getLocal ? getLocal.ref ? true : false : false;
    const [form, setForm] = useState({
        fullname: '',
        email: '',
        phone: '',
        amount: getLocal && getLocal.amount ? localAmount : parseInt(selectedNominal),
        payment_methods: '',
        referral: isReferral ? getLocal.ref : '',
    });

    const [isNameValid, setIsNameValid] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [isPhoneValid, setIsPhoneValid] = useState(null);
    const [isAmountValid, setIsAmountValid] = useState(true);
    const [isPaymentValid, setIsPaymentValid] = useState(null);

    const [invalidNameMsg, setInvalidNameMsg] = useState('');
    const [invalidEmailMsg, setInvalidEmailMsg] = useState('');
    const [invalidPhoneMsg, setInvalidPhoneMsg] = useState('');
    const [invalidPaymentMsg, setInvalidPaymentMsg] = useState('');

    const parsing = useCallback(() => {
        setNominals(nominalAvailable(type));
        if (parseInt(otherAmount) === 0) {
            setOtherPass('other');
        }
    }, [type, otherAmount]);

    useEffect(() => {
        if (type === '' || type === null) {
            navigate('/oops/404');
        }

        parsing();

        if (!isNaN(localAmount)) {
            const foundNominal = nominalAvailable(type).find(item => item.nominal === parseInt(localAmount));
            if (foundNominal) {
                setSelectedNominal(localAmount.toString());
                return;
            }
            setSelectedNominal('');
            setOtherAmount(formatIDR(localAmount.toString()));
            setOther(true);
        }

        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.scrollTo(0, 0);
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        };
    }, [type, localAmount, navigate, parsing]);

    const idr = (amount) => {
        return formatIDR(amount);
    };

    const handleNominal = (event) => {
        if (getLocal) {
            localStorage.removeItem('donation');
        }
        const amount = event.target.value;
        if (amount === 'other') {
            setOther(true);
            setSelectedNominal('');
        } else {
            setOther(false);
            setSelectedNominal(amount);
            setForm((prevForm) => ({ ...prevForm, amount: parseInt(amount) }));
        }
        // setOtherAmount('other');
        setIsAmountValid(true);
        setOtherAmount(idr(selectedNominal));
    };

    const validateAmount = (amount) => {
        return amount >= 10000;
    };

    const validateAmountSingle = (amount) => {
        return amount >= 50000;
    };

    const handleInputOther = (event) => {
        if (getLocal) {
            localStorage.removeItem('donation');
        }
        const amountOther = event.target.value;
        const numericValue = amountOther.replace(/\D/g, '');
        setForm((prevForm) => ({ ...prevForm, amount: parseInt(numericValue) }));
        setOtherAmount(idr(numericValue));
        setIsAmountValid(type === 'single' ? validateAmountSingle(numericValue) : validateAmount(numericValue));
        setOther(true);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleName = (event) => {
        const name = event.target.value;
        setForm({ ...form, fullname: name });
        if (name === '') {
            setIsNameValid(false);
            setInvalidNameMsg(donation.two.invalid.fullname);
            return;
        }
        setIsNameValid(true);
    };

    const handleEmail = (event) => {
        const email = event.target.value;
        setForm({ ...form, email: email });
        if (email === '') {
            setIsEmailValid(false);
            setInvalidEmailMsg(donation.two.invalid.emptyEmail);
            return;
        }
        if (validateEmail(email)) {
            setIsEmailValid(true);
            return;
        }
        setIsEmailValid(false);
        setInvalidEmailMsg(donation.two.invalid.invalidEmail);
    };

    const handlePhone = (event) => {
        const phone = event.target.value;
        setForm({ ...form, phone: phone });
        if (phone === '') {
            setIsPhoneValid(false);
            setInvalidPhoneMsg(donation.two.invalid.phone);
            return;
        }
        setIsPhoneValid(true);
        setInvalidPhoneMsg('');
    };

    const handlePayment = (event) => {
        const payment = event.target.value;
        setForm({ ...form, payment_methods: payment });
        if (payment === '') {
            setIsPaymentValid(false);
            setInvalidPaymentMsg(donation.two.invalid.payment);
            return;
        }
        setIsPaymentValid(true);
    }

    const handleReferral = (event) => {
        const referral = event.target.value;
        setForm({ ...form, referral: referral });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (isAmountValid && isNameValid && isEmailValid && isPhoneValid && isPaymentValid) {
            setIsLoading(true);
            try {
                const success = await donationProcess(type, form);
                if (success) {
                    return;
                }
            } catch (error) {
                navigate('/oops/500', { replace: true });
            } finally {
                setIsLoading(false);
            }
        }

        if (isNameValid === null) {
            setIsNameValid(false);
            setInvalidNameMsg(donation.two.invalid.fullname);
        }
        if (isEmailValid === null) {
            setIsEmailValid(false);
            setInvalidEmailMsg(donation.two.invalid.emptyEmail);
        }
        if (isPhoneValid === null) {
            setIsPhoneValid(false);
            setInvalidPhoneMsg(donation.two.invalid.phone);
        }
        if (isPaymentValid === null) {
            setIsPaymentValid(false);
            setInvalidPaymentMsg(donation.two.invalid.payment);
        }
    };

    const handleBoostSubmit = async (event) => {
        event.preventDefault();

        if (isAmountValid && isPaymentValid) {
            form.email = getProfile().email;
            form.fullname = getProfile().fullname;
            form.phone = getProfile().phone_number;
            setIsLoading(true);
            try {
                const success = await donationProcess(type, form);
                if (success) {
                    return;
                }
            } catch (error) {
                navigate('/oops/500', { replace: true });
            } finally {
                setIsLoading(false);
            }
        }

        if (isPaymentValid === null) {
            setIsPaymentValid(false);
            setInvalidPaymentMsg(donation.two.invalid.payment);
        }
    };

    const handleBack = () => {
        localStorage.removeItem('donation');
    };

    return (
        <div className='side-gap'>
            <div className={`${style.donation} ${style[type]}`}>
                <span className={style.logo}>{getSVG.logo.gfdp}</span>

                <Link to='/donation/monthly' className={`${style.navigation} ${style.top}`}><span>{getSVG.navigation.back}</span>{getText.sidebar.donation.navigation.onboarding}</Link>

                {type === 'single' && (
                    <Link to='/donation/monthly' className={`${style.navigation} ${style.single}`}>{getText.sidebar.donation.navigation.monthly}</Link>
                )}

                <form className={style['donation-form']} noValidate>
                    <div className={style['form-section']}>
                        <div className={style.header}>
                            <div className={style.number}>1</div>
                            <h6 className={style.title}>{donation.one.title}</h6>
                            <span className={style.line}><hr /></span>
                        </div>
                        <div className={style.body}>
                            <div className={style['nominal-wrapper']}>
                                {nominals.map(item => (
                                    <div className={`
                                    ${type === 'monthly' ? style['btn-monthly'] : style['btn-single']}
                                    ${parseInt(selectedNominal) === item.nominal ? style.checked : ''} 
                                    ${style['nominal-button-wrapper']}`}
                                        key={item.nominal}>
                                        <input
                                            type="radio"
                                            className={`${style.nominal} ${item.gaClass}`}
                                            name="amount_option"
                                            id={`donate${item.nominal}`}
                                            value={item.nominal}
                                            autoComplete="off"
                                            checked={parseInt(selectedNominal) === item.nominal}
                                            onChange={handleNominal}
                                        />
                                        <label className={style['button-nominal']} htmlFor={`donate${item.nominal}`}>{item.nominalText}</label>
                                    </div>
                                ))}

                                <div className={`
                                ${type === 'monthly' ? style['btn-monthly'] : style['btn-single']} 
                                ${type === 'monthly' ? style['grid-span-2'] : style['grid-span-3']} 
                                ${other === true ? style.checked : ''} 
                                ${style['nominal-button-wrapper']}`}>
                                    <input
                                        type="radio"
                                        className={`${style.nominal}`}
                                        name="amount_option"
                                        id="donateOther"
                                        value={otherPass}
                                        autoComplete="off"
                                        checked={other === true}
                                        onChange={handleNominal}
                                    />
                                    <label className={style['button-nominal']} htmlFor="donateOther">{getText.donationCard.other}</label>
                                </div>
                            </div>

                            {other && (
                                <div className={style['other-amount']}>
                                    <label htmlFor="final-amount">{getText.donationCard.other}</label>
                                    <div className="input-group">
                                        <span className={`input-group-text ${style.custom}`}>Rp.</span>
                                        <input type="text" className={`form-control gfdp-nominal-jumlah_lainnya ${style['input-other']} ${isAmountValid === false ? 'is-invalid' : ''}`} name="other_amount" id="final-amount" value={otherAmount} onChange={handleInputOther} />
                                        <span className="invalid-feedback">{type === 'single' ? getText.donationCard.amount50 : getText.donationCard.amount10}</span>
                                    </div>
                                </div>
                            )}

                            {type === 'monthly' && isAmountValid && (
                                <p className={`${style.subtitle}`}>
                                    {selectedNominal === '' ? (
                                        `${getText.donationCard.note1} ${idr(otherAmount)} ${getText.donationCard.note2}`
                                    ) : (
                                        `${getText.donationCard.note1} ${idr(selectedNominal)} ${getText.donationCard.note2}`
                                    )}
                                </p>
                            )}
                        </div>
                    </div>

                    {type !== 'boost' && (
                        <div className={style['form-section']}>
                            <div className={style.header}>
                                <div className={style.number}>2</div>
                                <h6 className={style.title}>{donation.two.title}</h6>
                                <span className={style.line}><hr /></span>
                            </div>
                            <div className={style.body}>
                                <div className={style['input-section']}>
                                    <div className={style['input-group']}>
                                        <label className="form-label" htmlFor="fullname">{donation.two.label.name}</label>
                                        <Input type="text" id="fullname" name="fullname" className={`form-control ${isNameValid === false ? 'is-invalid' : ''}`} placeholder={donation.two.placeholder.name} value={form.fullname} onChange={handleName} />
                                        <span className="invalid-feedback">{invalidNameMsg}</span>
                                    </div>
                                </div>

                                <div className={style['input-section']}>
                                    <div className={style['input-group']}>
                                        <label className="form-label" htmlFor="email">{donation.two.label.email}</label>
                                        <Input type="email" id="email" name="email" value={form.email} className={`form-control ${isEmailValid === false ? 'is-invalid' : ''}`} placeholder={donation.two.placeholder.email} onChange={handleEmail} />
                                        <span className="invalid-feedback">{invalidEmailMsg}</span>
                                    </div>
                                    <div className={style['input-group']}>
                                        <label className="form-label" htmlFor="phone">{donation.two.label.phone}</label>
                                        <div className="input-group">
                                            <span className={`input-group-text ${style.custom}`}>+62</span>
                                            <Input type="number" id="phone" name="phone" value={form.phone} className={`form-control ${style['input-other']} ${isPhoneValid === false ? 'is-invalid' : ''}`} placeholder={donation.two.placeholder.phone} onChange={handlePhone} />
                                            {invalidPhoneMsg !== '' ? (
                                                <span className="invalid-feedback">{invalidPhoneMsg}</span>
                                            ) : (
                                                <span className={`text-muted ${style.phone}`}>{getText.volunteer.form.note}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={style['form-section']}>
                        <div className={style.header}>
                            <div className={style.number}>{type === 'boost' ? '2' : '3'}</div>
                            <h6 className={style.title}>{donation.three.title}</h6>
                            <span className={style.line}><hr /></span>
                        </div>
                        <div className={style.body}>
                            <div className="accordion accordion-flush" id="payment-methods">
                                {monthlyType !== 'reminder' && (
                                    <div className={`accordion-item`}>
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                Credit/Debit Card
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#payment-methods">
                                            <div className={`accordion-body ${style['accordion-content']}`}>
                                                {payments.creditCard.map((payment, index) => (
                                                    <div className={style['input-group']} key={index}>
                                                        <input className={`form-check-input gfdp-payment_method-${payment.channelCode}`} type="radio" name="payment_methods" id={`pm${payment.channelCode}`} value={payment.channelCode} onChange={handlePayment} />
                                                        <label htmlFor={`pm${payment.channelCode}`}>
                                                            <img src={payment.logo} loading="lazy" className={style['pm-icon']} alt="payment-logo" />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {monthlyType !== 'reminder' && type === 'monthly' && (
                                    <div className={`accordion-item`}>
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                Direct Debit
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#payment-methods">
                                            <div className={`accordion-body ${style['accordion-content']}`}>
                                                {payments.directDebit.map((payment, index) => (
                                                    <div className={style['input-group']} key={index}>
                                                        <input className={`form-check-input gfdp-payment_method-${payment.channelCode}`} type="radio" name="payment_methods" id={`pm${payment.channelCode}`} value={payment.channelCode} onChange={handlePayment} />
                                                        <label htmlFor={`pm${payment.channelCode}`}>
                                                            <img src={payment.logo} loading="lazy" className={style['pm-icon']} alt="payment-logo" />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            E-Wallet
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#payment-methods">
                                        <div className={`accordion-body ${style['accordion-content']}`}>
                                            {payments.eWallet.map((payment, index) => (
                                                <div className={style['input-group']} key={index}>
                                                    <input className={`form-check-input gfdp-payment_method-${payment.channelCode}`} type="radio" name="payment_methods" id={`pm${payment.channelCode}`} value={payment.channelCode} onChange={handlePayment} />
                                                    <label htmlFor={`pm${payment.channelCode}`}>
                                                        <img src={payment.logo} loading="lazy" className={style['pm-icon']} alt="payment-logo" />
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {monthlyType !== 'recurring' && (
                                    <>
                                        <div className={`accordion-item`}>
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Virtual account
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#payment-methods">
                                                <div className={`accordion-body ${style['accordion-content']}`}>
                                                    {payments.virtualAccount.map((payment, index) => (
                                                        <div className={style['input-group']} key={index}>
                                                            <input className={`form-check-input gfdp-payment_method-${payment.channelCode}`} type="radio" name="payment_methods" id={`pm${payment.channelCode}`} value={payment.channelCode} onChange={handlePayment} />
                                                            <label htmlFor={`pm${payment.channelCode}`}>
                                                                <img src={payment.logo} loading="lazy" className={style['pm-icon']} alt="payment-logo" />
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`accordion-item`}>
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    QRIS
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#payment-methods">
                                                <div className={`accordion-body ${style['accordion-content']}`}>
                                                    {payments.others.map((payment, index) => (
                                                        <div className={style['input-group']} key={index}>
                                                            <input className={`form-check-input gfdp-payment_method-${payment.channelCode}`} type="radio" name="payment_methods" id={`pm${payment.channelCode}`} value={payment.channelCode} onChange={handlePayment} />
                                                            <label htmlFor={`pm${payment.channelCode}`}>
                                                                <img src={payment.logo} loading="lazy" className={style['pm-icon']} alt="payment-logo" />
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <span className={`${style['custom-invalid']} ${isPaymentValid === false ? style.invalid : ''}`}>{invalidPaymentMsg}</span>
                        </div>
                    </div>

                    {type === 'monthly' && (
                        <div className={style['form-section']}>
                            <div className={style.header}>
                                <div className={style.number}>4</div>
                                <h6 className={style.title}>{donation.four.title}</h6>
                                <div className={style.note}>{donation.four.note} <span>{getSVG.donation.optional}</span></div>
                                <span className={style.line}><hr /></span>
                            </div>
                            <div className={style.body}>
                                <div className={style['input-group']}>
                                    <Input type="text" id="referral" name="referral" className={`form-control`} placeholder={donation.four.placeholder} value={form.referral} onChange={handleReferral} disabled={isReferral} />
                                </div>
                            </div>
                        </div>
                    )}

                    {type === 'monthly' ? (
                        <ButtonPrimary onClick={handleSubmit} className={`${style.donate} ${isLoading ? 'temp-disabled' : ''}`}>
                            {isLoading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                donation.button
                            )}
                        </ButtonPrimary>
                    ) : (
                        <ButtonGreen onClick={type === 'boost' ? handleBoostSubmit : handleSubmit} className={`${style.donate} ${isLoading ? 'temp-disabled' : ''}`}>
                            {isLoading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                donation.button
                            )}
                        </ButtonGreen>
                    )}

                </form>

                {type === 'boost' ? (
                    <Link to='/dashboard' className={`${style.navigation} ${style.bottom}`}><span>{getSVG.navigation.back}</span>{getText.sidebar.donation.navigation.dashboard}</Link>
                ) : (
                    <a onClick={handleBack} href={`${process.env.REACT_APP_BASE_URL}`} className={`${style.navigation} ${style.bottom}`}><span>{getSVG.navigation.back}</span>{getText.sidebar.donation.navigation.home}</a>
                )}
            </div>
        </div>
    );
}

export default DonationPage;