import { createContext, useState, useContext, useEffect } from 'react';
import { getUserType } from '../../features';

const RoleContext = createContext({});

export const RoleProvider = ({ children }) => {
    const [role, setRole] = useState(getUserType());

    useEffect(() => {
        const handleRoleChange = () => {
            setRole(getUserType());
        };
        window.addEventListener('role-change', handleRoleChange);
        return () => {
            window.removeEventListener('role-change', handleRoleChange);
        };
    }, []);

    return (
        <RoleContext.Provider value={{ role, setRole }}>
            {children}
        </RoleContext.Provider>
    )
}

export function useRole() {
    return useContext(RoleContext);
}
