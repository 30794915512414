import { useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useToken, contentTranslate, accentSVG } from '../../../services';
import style from './auth.module.scss';
import image from '../../../assets/img/Login.webp';

const Auth = () => {
    const token = useToken();
    const [isLoading, setIsLoading] = useState(true);
    const lang = sessionStorage.getItem('gfdp-lang');
    const getText = contentTranslate(lang);
    const getSVG = accentSVG();

    useEffect(() => {
        if (token !== null) {
            setIsLoading(false);
        }
    }, [token]);

    const bgImage = {
        backgroundImage: `url('${image}')`
    };

    return (
        <main className={`vph ${style.login}`}>
            <div className={`col-5 d-none d-md-block ${style.left}`} style={bgImage}>
                <div className={style.logo}>{getSVG.logo.gfdp}</div>

                <div className={style.onboard}>
                    <p>{getText.login.p1}</p>
                    <p className={style.plink}>{getText.login.p2} <Link to="/donation/monthly">{getText.login.onboard}</Link></p>
                </div>
            </div>

            <div className={`col-12 col-md-7 ${style.right}`}>
                {isLoading ? (
                    <div className={`spinner-wrapper ${style['spinner-login']}`}>
                        <div className='loading-spinner'></div>
                    </div>
                ) : (
                    <Outlet />
                )}
            </div>
        </main>
    )
}

export default Auth;