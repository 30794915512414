import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import style from './error.module.scss';
import errorImg from '../../assets/img/bg-thanks-web.png';

const ErrorPage = ({ errorType }) => {
    const navigate = useNavigate();
    const { type } = useParams();
    const lang = useState(sessionStorage.getItem('gfdp-lang'));

    const handleBackClick = () => {
        sessionStorage.removeItem('gfdp-jwt');
        navigate('/', { replace: true });
    };

    const backOnePage = () => {
        navigate(-1);
    };

    const errorBG = {
        backgroundImage: `url('${errorImg}')`
    };

    if (type) {
        errorType = type;
    }

    return (
        <div className={`vph ${style.oops}`} style={errorBG}>
            { errorType === '401' ? (
                <div className={style.wrapper}>
                    <h4>{errorType}</h4>
                    <h4>{lang === 'id' ? 'Anda tidak memiliki akses ke halaman yang diminta' : 'You do not have access to the requested page'}</h4>

                    <div className={style.link} onClick={backOnePage}>
                        <span className='me-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="1 1 15 15" fill="none"><path d="M10.9437 2.41875L9.83115 1.3125L3.6499 7.5L9.8374 13.6875L10.9437 12.5812L5.8624 7.5L10.9437 2.41875Z" fill="#00B5FF" /></svg>
                        </span>
                        {lang === 'id' ? 'Kembali' : 'Back'}
                    </div>
                </div>
            ) : errorType === '404' ? (
                <div className={style.wrapper}>
                    <h4>{errorType}</h4>
                    <h4>{lang === 'id' ? 'Halaman tidak ditemukan' : 'Page not found'}</h4>

                    <div className={style.link} onClick={handleBackClick}>
                        <span className='me-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="1 1 15 15" fill="none"><path d="M10.9437 2.41875L9.83115 1.3125L3.6499 7.5L9.8374 13.6875L10.9437 12.5812L5.8624 7.5L10.9437 2.41875Z" fill="#00B5FF" /></svg>
                        </span>
                        {lang === 'id' ? 'Kembali ke Halaman Utama' : 'Back to Main Page'}
                    </div>
                </div>
            ) : errorType === '500' ? (
                <div className={style.wrapper}>
                    <h4>{errorType}</h4>
                    <h4>{lang === 'id' ? 'Terjadi kesalahan server' : 'Server error occurred'}</h4>
                    
                    {/* <div className={style.link} onClick={handleBackClick}>
                        <span className='me-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="1 1 15 15" fill="none"><path d="M10.9437 2.41875L9.83115 1.3125L3.6499 7.5L9.8374 13.6875L10.9437 12.5812L5.8624 7.5L10.9437 2.41875Z" fill="#00B5FF" /></svg>
                        </span>
                        {lang === 'id' ? 'Kembali ke Halaman Utama' : 'Back to Main Page'}
                    </div> */}
                </div>
            ) : (
                <div className={style.wrapper}>
                    <h4>Oops!</h4>
                    <h4>{lang === 'id' ? 'Ada yang salah' : 'Something went wrong'}</h4>

                    <div className={style.link} onClick={handleBackClick}>
                        <span className='me-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="1 1 15 15" fill="none"><path d="M10.9437 2.41875L9.83115 1.3125L3.6499 7.5L9.8374 13.6875L10.9437 12.5812L5.8624 7.5L10.9437 2.41875Z" fill="#00B5FF" /></svg>
                        </span>
                        {lang === 'id' ? 'Kembali' : 'Back'}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ErrorPage;