import { Route, Routes } from 'react-router-dom';
import { Base, Dashboard, Auth } from '../features';
import { DashboardRole, RequireAuth, RequireRole } from './protected-router';
import { LoginPage, ErrorPage, DonorPage, LandingPage, CampaignPage, VolunteerPage, VolunteerSignup, ForgotPage, SuccessPage, OnboardingPage, DonationPage, BadgesPage, ProfilePage, VolunteerDashboard, IncentivePage, LeaderboardPage, TermsPage, ResetPage, ReportPage } from '../pages';

const AppRouter = () => {
    return (
        <>
            <Routes>
                <Route element={<Base />}>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/campaign/:param" element={<CampaignPage />} />
                    <Route path="/volunteer" element={<VolunteerPage />} />
                    <Route path="/volunteer/signup" element={<VolunteerSignup />} />
                    <Route path="/terms-and-conditions" element={<TermsPage />} />
                    <Route path="/report" element={<ReportPage />} />
                </Route>

                <Route path="/dashboard" element={<Dashboard />}>
                    <Route index element={<DashboardRole />}/>

                    <Route element={<RequireAuth context='dashboard' />}>
                        <Route element={<RequireRole allowedRole='donor' />}>
                            <Route path="donor" element={<DonorPage />} />
                            <Route path="badges" element={<BadgesPage />} />
                            {/* <Route path="history" element={<HistoryPage />} /> */}
                        </Route>

                        <Route element={<RequireRole allowedRole='volunteer' />}>
                            <Route path="volunteer" element={<VolunteerDashboard />} />
                            <Route path="incentive" element={<IncentivePage />} />
                            <Route path="leaderboard" element={<LeaderboardPage />} />
                        </Route>

                        <Route path="profile" element={<ProfilePage />} />
                    </Route>
                </Route>

                <Route path="/donation" element={<Dashboard />}>
                    <Route element={<RequireAuth context='donation' />}>
                        <Route path="monthly" element={<OnboardingPage />} />
                        <Route path="monthly/form/recurring" element={<DonationPage />} />
                        <Route path="monthly/form/reminder" element={<DonationPage />} />
                        <Route path="single/form" element={<DonationPage />} />
                    </Route>

                    <Route element={<RequireAuth context='dashboard' />}>
                        <Route path="boost/form" element={<DonationPage />} />
                    </Route>
                </Route>

                <Route element={<Auth />}>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forgot" element={<ForgotPage />} />
                    <Route path="/reset-password/:param" element={<ResetPage />} />
                </Route>

                <Route path="*" element={<ErrorPage errorType="404" />} />
                <Route path="/oops" element={<ErrorPage />} />
                <Route path="/oops/:type" element={<ErrorPage />} />

                <Route path="/success" element={<SuccessPage />} />
                <Route path="/redirect-thanks-donation/thanks" element={<SuccessPage />} />
                <Route path="/volunteer/registered" element={<SuccessPage successType="volunteer" />} />
                <Route path="/stop-donation/:param" element={<SuccessPage successType="stop" />} />
            </Routes>
        </>
    );
}

export default AppRouter;