export function checkJwt(signal) {
    const sessionJwt = sessionStorage.getItem('gfdp-jwt');

    const updateJwt = (token) => {
        const gfdpJwt = JSON.stringify({
            token: token,
            lastUpdated: Math.floor(Date.now() / 1000)
        });
        sessionStorage.setItem('gfdp-jwt', gfdpJwt);
        return token;
    };

    const fetchJwt = async () => {
        try {
            const token = await getJwt(signal);
            return updateJwt(token);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            console.error('An error occurred:', error.message);
        }
    };

    if (!sessionJwt) {
        return fetchJwt();
    }

    const jwt = JSON.parse(sessionJwt);
    if (!jwt.token) {
        return fetchJwt();
    }

    const expiredMinutes = (Math.floor(Date.now() / 1000) - jwt.lastUpdated) / 60;
    if (expiredMinutes > 1) {
        return fetchJwt();
    }

    return Promise.resolve(jwt.token);
}

export async function getJwt(signal) {
    const credentials = {
        platform: process.env.REACT_APP_PLATFORM,
        apps: process.env.REACT_APP_APPT,
        crx: process.env.REACT_APP_CRED
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/rest`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(credentials),
            signal: signal
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const token = responseData.token;

        return token;
    } catch (error) {
        if (error.name === 'AbortError') {
            return;
        }

        console.error('An error occurred:', error.message);
        // if (process.env.REACT_APP_ENV === 'development') {
        //     console.error('An error occurred:', error.message);
        // } else {
        //     window.location.href = '/oops/500';
        // }
    }
}

export async function getSections(lang, signal) {
    const token = await checkJwt(signal);

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/landing?lang=${lang}`, {
            method: 'GET',
            headers: {
                'Authorization': token
            },
            signal: signal
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const sections = {};

        data.result.forEach(result => {
            sections[result.section] = JSON.parse(result.custom_data);
        });

        return sections;
    } catch (error) {
        if (error.name === 'AbortError') {
            return;
        }

        console.error('An error occurred:', error.message);
        // if (process.env.REACT_APP_ENV === 'development') {
        //     console.error('An error occurred:', error.message);
        // } else {
        //     window.location.href = '/oops/500';
        // }
    }
}

export async function getImpacts(lang, signal) {
    const token = await checkJwt(signal);

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/Impacts/all`, {
            method: 'GET',
            headers: {
                'Authorization': token
            },
            signal: signal
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const impacts = data.result.filter(impact => impact.status === "up" && impact.lang === lang);

        return impacts;
    } catch (error) {
        if (error.name === 'AbortError') {
            return;
        }

        console.error('An error occurred:', error.message);
        // if (process.env.REACT_APP_ENV === 'development') {
        //     console.error('An error occurred:', error.message);
        // } else {
        //     window.location.href = '/oops/500';
        // }
    }
}

export async function getCampaigns(lang, id = null, signal) {
    const token = await checkJwt(signal);

    try {
        let endpoint = `${process.env.REACT_APP_API_HOST}/v1/campaigns?lang=${lang}&isUp=up`;

        if (id !== null) {
            endpoint = `${process.env.REACT_APP_API_HOST}/v1/campaigns?lang=${lang}&isUp=up&search=${id}&searchBy=slug`;
        }

        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                Authorization: token
            },
            signal: signal
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.result.length !== 0) {
            data.result.forEach((campaign) => {
                campaign.contents = JSON.parse(campaign.contents);
            });
            const campaigns = data.result;
            return campaigns;
        } else {
            window.location.href = '/oops/404';
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            return;
        }

        console.error('An error occurred:', error.message);
        // if (process.env.REACT_APP_ENV === 'development') {
        //     console.error('An error occurred:', error.message);
        // } else {
        //     window.location.href = '/oops/500';
        // }
    }
}

export async function getTestimonials(lang, signal) {
    const token = await checkJwt(signal);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/testimonials/all`, {
            method: 'GET',
            headers: {
                Authorization: token
            },
            signal: signal
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        const testimonialData = data.result
            .filter((testimonial) => testimonial.status === 'up')
            .map((testimonial) => {
                const testimonialItem = {
                    type: testimonial.type,
                    name: testimonial.name,
                    img: process.env.REACT_APP_PUBLIC_CONTENT + testimonial.image,
                };

                if (lang === 'en') {
                    testimonialItem.title = testimonial.title_en;
                    testimonialItem.feedback = testimonial.feedback_en;
                } else {
                    testimonialItem.title = testimonial.title_id;
                    testimonialItem.feedback = testimonial.feedback_id;
                }

                return testimonialItem;
            });

        return testimonialData;
    } catch (error) {
        if (error.name === 'AbortError') {
            return;
        }

        console.error('An error occurred:', error.message);
        // if (process.env.REACT_APP_ENV === 'development') {
        //     console.error('An error occurred:', error.message);
        // } else {
        //     window.location.href = '/oops/500';
        // }
    }
}

export async function getRecurring(email, signal) {
    const token = await checkJwt(signal);

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/donations/recurring?email=${email}`, {
            method: 'GET',
            headers: {
                'Authorization': token
            },
            signal: signal
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const recurring = data.result;

        return recurring;
    } catch (error) {
        if (error.name === 'AbortError') {
            return;
        }

        console.error('An error occurred:', error.message);
        // if (process.env.REACT_APP_ENV === 'development') {
        //     console.error('An error occurred:', error.message);
        // } else {
        //     window.location.href = '/oops/500';
        // }
    }
}

export async function getUserLocation() {
    try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        return data.country_code;
    } catch (error) {
        console.error('An error occurred:', error.message);
        // if (process.env.REACT_APP_ENV === 'development') {
        //     console.error('An error occurred:', error.message);
        // } else {
        //     window.location.href = '/oops/500';
        // }
    }
}