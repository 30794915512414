import { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getNavigation, contentTranslate, accentSVG, useAuth, capitalize, slice } from '../../../../services';
import { ButtonPrimary, BackToTopButton, ScrollShadow } from '..';
import { getProfile } from '../../../users';
import style from './header.module.scss';
import avatarDefault from '../../../../assets/img/user.png';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;
    const publicContent = process.env.REACT_APP_PUBLIC_CONTENT;
    const menuRef = useRef(null);
    const [isToggled, setIsToggled] = useState(false);
    const [withMenu, setWithMenu] = useState(window.innerWidth <= 992);
    const [mobileXS, setMobileXS] = useState(window.innerWidth <= 370);
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const { auth } = useAuth();
    const user = auth ? getProfile() : null;
    const { fullname, avatar } = user
        ? { fullname: capitalize(user.fullname), avatar: user.avatar ? publicContent + user.avatar : avatarDefault }
        : { fullname: '', avatar: avatarDefault };
    const getText = contentTranslate(lang);
    const [navLink, setNavLink] = useState([]);
    const getSVG = accentSVG();

    const handleLangChangeEvent = useCallback(() => {
        const newLang = sessionStorage.getItem('gfdp-lang');
        setLang(newLang);
    }, []);

    const fetchNav = useCallback(async () => {
        try {
            const navigationData = await getNavigation(lang);
            setNavLink(navigationData);
        } catch (error) {
            console.error("Error fetching navigation data:", error);
        }
    }, [lang]);

    useEffect(() => {
        fetchNav();

        const handleWindowResize = () => {
            setWithMenu(window.innerWidth <= 992);
            setMobileXS(window.innerWidth <= 370);
        };

        const handleDocumentClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };
        const handleDocumentScroll = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('resize', handleWindowResize);
        if (withMenu) {
            document.addEventListener('click', handleDocumentClick);
            document.addEventListener('scroll', handleDocumentScroll);
        }
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('resize', handleWindowResize);
            if (withMenu) {
                document.removeEventListener('click', handleDocumentClick);
                document.removeEventListener('scroll', handleDocumentScroll);
            }
        };

    }, [withMenu, handleLangChangeEvent, fetchNav]);

    const handleLangChange = (event) => {
        const newLang = event.target.value;
        sessionStorage.setItem('gfdp-lang', newLang);
        setLang(newLang);
        closeMenu();
        const langChangeEvent = new Event('gfdp-lang-change');
        window.dispatchEvent(langChangeEvent);
    };

    const filteredNavLinks = navLink.filter(item => {
        if (path === '/') {
            return true;
        }
        return !item.section;
    });

    const scrollToSection = (event, sectionId) => {
        event.preventDefault();
        const sectionElement = document.querySelector(sectionId);
        if (sectionElement) {
            const offset = 90;
            const scrollToOptions = {
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            };
            window.scrollTo({
                top: sectionElement.getBoundingClientRect().top + window.scrollY - offset,
                ...scrollToOptions,
            });
        }
        // navigate(sectionId);
        closeMenu();
    };

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        closeMenu();
    };

    const menuToggle = () => {
        setIsToggled(!isToggled);
    };

    const closeMenu = () => {
        setIsToggled(false);
    };

    const goToDashboard = () => {
        navigate('/dashboard');
    };

    const goToDonate = () => {
        if (path === '/') {
            backToTop();
            closeMenu();
        }
        navigate('/');
    };

    return (
        <header ref={menuRef}>
            <ScrollShadow className={style.header}>
                <div className={style.left}>
                    <Link to="/" aria-label='GFDP'>{getSVG.logo.gfdpResponsive}</Link>
                </div>

                <div className={style.middle}>
                    <nav className={`${style['link-group']} ${isToggled ? style.open : ''}`}>
                        <ul>
                            {filteredNavLinks.map((item, index) => (
                                item.section ? (
                                    <li key={index}>
                                        <a
                                            className={`${style['link-nav']}`}
                                            href={item.nav}
                                            onClick={(e) => scrollToSection(e, item.nav)}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                ) : (
                                    <li className={`${path === item.nav ? style.active : ''}`} key={index}>
                                        <Link
                                            className={`${style['link-nav']}`}
                                            to={item.nav}
                                            onClick={() => backToTop(item.nav)}
                                        >
                                            {item.title}
                                        </Link>
                                    </li>
                                )
                            ))}
                            <li className={`${style['user-profile']} ${style.mobile}`}>
                                {auth ?
                                    <Link to="/dashboard/profile">
                                        <img className={style.avatar} src={avatar} width="20" height="20" alt="avatar" loading='lazy' />
                                        <span className={style.username}>{fullname}</span>
                                    </Link>
                                    :
                                    <Link to="/login">{getText.header.login}</Link>
                                }
                            </li>
                            <li className={`${style.lang} ${style.mobile}`}>
                                <div className={style.option}>
                                    <input type="radio" className={style['option-language']} name="language-option" id="language-id-mobile" value="id" checked={lang === 'id'} onChange={handleLangChange} />
                                    <label htmlFor="language-id-mobile">ID</label>
                                </div>
                                <span></span>
                                <div className={style.option}>
                                    <input type="radio" className={style['option-language']} name="language-option" id="language-en-mobile" value="en" checked={lang === 'en'} onChange={handleLangChange} />
                                    <label htmlFor="language-en-mobile">EN</label>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className={style.right}>
                    <div className={style['option-group']}>
                        <div className={style.user}>
                            {auth ? (
                                <div className={style['group-user']}>
                                    <div className={style['user-profile']}>
                                        <Link to="/dashboard/profile">
                                            <img className={style.avatar} src={avatar} width="20" height="20" alt="avatar" loading='lazy' />
                                            <span className={style.username}>{fullname}</span>
                                        </Link>
                                    </div>
                                    <div>
                                        <ButtonPrimary className={style['nav-button']} onClick={goToDashboard}>{getText.header.dashboard}</ButtonPrimary>
                                    </div>
                                </div>
                            ) : (
                                <div className={style['group-user']}>
                                    <div className={style['login-link']}>
                                        <Link to="/login">{getText.header.login}</Link>
                                    </div>
                                    <div>
                                        <ButtonPrimary className={style['nav-button']} onClick={goToDonate}>
                                            {mobileXS ? slice(getText.header.donation) : getText.header.donation}
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={style.lang}>
                            <div className={style.option}>
                                <input type="radio" className={style['option-language']} name="language-option" id="language-id" value="id" checked={lang === 'id'} onChange={handleLangChange} />
                                <label className={lang === 'id' ? style.bold : ''} htmlFor="language-id">ID</label>
                            </div>
                            <span></span>
                            <div className={style.option}>
                                <input type="radio" className={style['option-language']} name="language-option" id="language-en" value="en" checked={lang === 'en'} onChange={handleLangChange} />
                                <label className={lang === 'en' ? style.bold : ''} htmlFor="language-en">EN</label>
                            </div>
                        </div>

                    </div>

                    <svg className={`${style.menu} ${isToggled ? style.active : ''}`} viewBox="0 0 100 100" width="40" onClick={menuToggle}>
                        <path
                            className={`${style.line} ${style['bar-top']}`}
                            d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                        <path
                            className={`${style.line} ${style['bar-middle']}`}
                            d="m 30,50 h 40" />
                        <path
                            className={`${style.line} ${style['bar-bottom']}`}
                            d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                    </svg>
                </div>
            </ScrollShadow>

            <BackToTopButton closeMenu={closeMenu}></BackToTopButton>
        </header>
    )
}

export default Header;