import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { accentSVG, contentTranslate, useAuth } from '../../../../services';
import { getProfile, updateReferral } from '../../../users';
import { ButtonGrey, ButtonPrimary, Input } from '..';
import Modal from 'react-bootstrap/Modal';
import style from './modal.module.scss';

const ReferralModal = (props) => {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const user = auth ? getProfile() : null;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const text = getText.dashboard.modal.referral;
    const [isLoading, setIsLoading] = useState(false);
    const getSVG = accentSVG();
    const link = `${baseUrl}/donation/monthly?ref=`;
    const [newReferral, setNewReferral] = useState(user ? user.referral_code : '');

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        }
    }, []);

    const shorten = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        } else {
            return text.slice(0, maxLength) + '...';
        }
    };

    const dispatchEvent = () => {
        const referralChangeEvent = new Event('referral-change');
        window.dispatchEvent(referralChangeEvent);
    };

    const handleCancel = () => {
        setNewReferral(user ? user.referral_code : '');
        props.onHide();
    };

    const handleReferral = (event) => {
        const ref = event.target.value;
        setNewReferral(ref);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newReferral === '' || newReferral === null) {
            console.log('invalid');
            return;
        }
        setIsLoading(true);
        try {
            const success = await updateReferral(newReferral);
            if (success === 'locked') {
                alert('Not allowed to change referral code more than once.');
                return;
            }
            if (success) {
                dispatchEvent();
                props.onHide(true);
                return;
            }
            props.onHide(true);
        } catch (error) {
            navigate('/oops/500', { replace: true });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={`${style.modal} ${style.referral}`}>
                <Modal.Header className={style.header} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {text.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={style.body}>
                    <p>{text.subtitle}</p>

                    <div className={style.edit}>
                        <div className={style.link}>
                            <span>{shorten(link, 25)}</span>
                            <Input type="text" id="referral" name="referral_code" className={`form-control`} value={newReferral} onChange={handleReferral} />
                        </div>
                        <p className={style.warning}>
                            <span className={style['warning-icon']}>{getSVG.donor.warning}</span>
                            <span className={style.text}>{text.warning}</span>
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className={style.footer}>
                    <ButtonGrey onClick={handleCancel}>{text.cancel}</ButtonGrey>
                    <ButtonPrimary onClick={handleSubmit} className={`${isLoading ? 'temp-disabled' : ''}`}>
                        {isLoading ? (
                            <div className={style.load}>
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            text.confirm
                        )}
                    </ButtonPrimary>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default ReferralModal;