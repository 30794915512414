import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCampaigns, accentSVG, contentTranslate, useAuth } from '../../services';
import { DonationCard, Contact } from '../../features';
import style from './campaign.module.scss';

const CampaignPage = () => {
    const publicContent = process.env.REACT_APP_PUBLIC_CONTENT;
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const { auth } = useAuth();
    const [mobile, setMobile] = useState(window.innerWidth <= 992);
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    const [hero, setHero] = useState([]);
    const [campaignImg, setCampaignImg] = useState('');
    const [campaignBody, setCampaignBody] = useState('');
    const [campaignBodyImg, setCampaignBodyImg] = useState('');
    const [helplist, setHelplist] = useState([]);
    const [wScroll, setWScroll] = useState(0);
    const getAccent = accentSVG();
    const getText = contentTranslate(lang);
    const url = useParams();
    const slug = url.param;

    const handleLangChangeEvent = useCallback(() => {
        const newLang = sessionStorage.getItem('gfdp-lang');
        setLang(newLang);
    }, []);

    const fetchContent = useCallback((signal) => {
        getCampaigns(lang, slug, signal).then(data => {
            if (data) {
                setHero(data[0]);
                setCampaignImg(publicContent + data[0].image);
                setCampaignBody(data[0].contents.body);
                setCampaignBodyImg(publicContent + data[0].contents.bodyImage);
                setHelplist(data[0].contents.helpList);
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [lang, publicContent, slug]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const loader = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        if (!isLoading) {
            setTimeout(() => {
                setIsVisible(true);
            }, 100);
        }

        fetchContent(signal);

        const handleWindowResize = () => {
            setMobile(window.innerWidth <= 992);
        };

        const handleScroll = () => {
            setWScroll(window.scrollY);
        };

        window.scrollTo(0, 0);
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(loader);
            controller.abort();
        };
    }, [isLoading, handleLangChangeEvent, fetchContent]);

    const bgCampaign = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${campaignImg}')`,
        transform: mobile ? '' : `translateY(${wScroll * 0.5}px)`
    };

    const parallax = {
        position: 'relative',
        backgroundColor: 'white'
    };

    const wrapper = {
        position: 'relative',
        overflow: 'hidden'
    };

    const stick = {
        transform: `translateY(${wScroll * -0.5}px)`
    };

    const delay = {
        transform: `translateY(${wScroll * -0.4}px)`
    };

    const firstLetter = campaignBody[0];
    const body = campaignBody.slice(1);
    const bodyWithBreaks = body.split('\n').map((line, index) => (
        <span key={index} className={style.paragraph}>
            {line}
        </span>
    ));

    return (
        <div className={`base-gap ${style.campaign}`}>
            {isLoading && (
                <div className='spinner-wrapper'>
                    <div className='loading-spinner'></div>
                </div>
            )}

            <section style={mobile ? {} : parallax}>
                <div style={mobile ? {} : wrapper}>
                    <div className={style['hero-wrapper']} style={bgCampaign}>
                        <span className={style['accent-hero-lg']} style={mobile ? {} : stick}>{getAccent.landing.hero.accent}</span>
                        <div className='wide-wrapper'>
                            <div className={style['inner-wrapper']} style={mobile ? {} : delay}>
                                <div className={`${style.left} ${auth ? 'w-100' : ''}`} style={mobile ? bgCampaign : {}}>
                                    <span className={style['accent-hero-sm']}>{getAccent.landing.hero.accent}</span>
                                    {mobile ? (
                                        <span className={style['title-wrapper']}>
                                            <h1>{hero.title}</h1>
                                            <p>{hero.subtitle}</p>
                                        </span>
                                    ) : (
                                        <div className={`${style['fade-up']} ${isVisible ? style.active : ''}`}>
                                            {!isLoading && isVisible && (
                                                <span className={style['title-wrapper']}>
                                                    <h1>{hero.title}</h1>
                                                    <p>{hero.subtitle}</p>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {!auth && (
                                    <div className={`${style.right}`}>
                                        <div className={`${style['fade-up']} ${isVisible ? style.active : ''}`}>
                                            {!isLoading && isVisible && (
                                                <DonationCard className={style.custom} />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={style['campaign-body']}>
                <div className='wide-wrapper'>
                    <div className={style['campaign-content']}>
                        {campaignBodyImg ? (
                            <img className={style.image} src={campaignBodyImg} alt='campaign' loading='lazy' />
                        ) : null}
                        <article className={style.article}><span className={style['first-letter']}>{firstLetter}</span>{bodyWithBreaks}</article>
                    </div>
                </div>
            </section>

            <section className={style['campaign-help']}>
                <div className={style['help-wrapper']}>
                    <h2>{getText.campaign.title}</h2>
                    <ul className={style['help-list']}>
                        {helplist.map((help, key) => (
                            <li className={style.help} key={key}>
                                <span className={style['check-icon']}>{getAccent.campaign.check}</span>
                                {help}
                            </li>
                        ))}
                    </ul>
                    <p>{getText.campaign.desc1} <a href="mailto:donorcare@greeneration.org">donorcare@greeneration.org</a> {getText.campaign.desc2} <a href="https://wa.me/6285173310223">+6285173310223</a></p>
                </div>
            </section>

            <section id='contacts' className={style['bg-contacts']}>
                <Contact />
            </section>
        </div>
    );
}

export default CampaignPage;