import style from './button.module.scss';

const Green = ({ type, children, onClick, className }) => {
  return (
    <button type={type} className={`${style.green} ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Green;