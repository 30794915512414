import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { contentTranslate, accentSVG, getBadges, useAuth, getCurrentDate, capitalize } from '../../services';
import { getFirst, getProfile } from '../../features';
import style from './badges.module.scss';

const BadgesPage = () => {
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [menu, setMenu] = useState(sessionStorage.getItem('menu'));
    const [tablet, setTablet] = useState(window.innerWidth <= 992);
    const { auth } = useAuth();
    const user = auth ? getProfile() : null;
    const fullname = user ? capitalize(user.fullname) : '';
    const getText = contentTranslate(lang);
    const text = getText.dashboard;
    const achievement = getText.dashboard.achievement;
    const currentDate = getCurrentDate(lang);
    const getSVG = accentSVG();
    const [badges, setBadges] = useState([]);
    const [badgesLeft, setBadgesLeft] = useState([]);
    const [nextPoint, setNextPoint] = useState(0);
    const first = getFirst();

    const fetchBadges = useCallback((signal) => {
        getBadges(lang, signal).then(data => {
            if (data) {
                setBadgesLeft(data);
                setBadges(data.badges);
                setNextPoint(data.nextBadges.pointMin - data.userPoint?.toFixed(2));
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [lang]);

    useLayoutEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchBadges(signal);

        const handlePasswordUpdateEvent = () => {
            fetchBadges(signal);
        };

        window.addEventListener('password-update', handlePasswordUpdateEvent);
        return () => {
            window.removeEventListener('password-update', handlePasswordUpdateEvent);
            controller.abort();
        };
    }, [fetchBadges])

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        const handleMenuChangeEvent = () => {
            setMenu(sessionStorage.getItem('menu'));
        };

        const handleWindowResize = () => {
            setTablet(window.innerWidth <= 992);
        };

        window.scrollTo(0, 0);
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('menu-change', handleMenuChangeEvent);
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('menu-change', handleMenuChangeEvent);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className={`side-gap ${tablet ? style.overlay : ''} ${menu === 'close' ? style.close : ''}`}>
            <div className={style.logo}>{getSVG.logo.gfdp}</div>
            <div className={`${style.badges} ${menu === 'close' ? style.wider : ''}`}>
                <div className={style['wide-wrapper']}>
                    <div className={style.header}>
                        <p>{currentDate}</p>
                        <h3>{text.title} {fullname}</h3>
                    </div>

                    <div className={style.body}>
                        <div className={style.achievement}>
                            {first ? (
                                <>
                                    <h6>{achievement.title}</h6>
                                    {badgesLeft.nextBadges ? (
                                        <p>{achievement.desc_1} <strong>{badgesLeft.badgesCollected} {achievement.badge}</strong>. {achievement.desc_2} <strong>{isNaN(nextPoint) ? 0 : nextPoint.toFixed(2)} {achievement.point}</strong> {achievement.desc_3} <strong>{badgesLeft.nextBadges.title}</strong></p>
                                    ) : (
                                        <p>{achievement.desc_completed}</p>
                                    )}
                                </>
                            ) : (
                                <h6>{achievement.locked}</h6>
                            )}
                        </div>
                        {first && (
                            <div className={style['badges-wrapper']}>
                                {badges.map((badge, index) => (
                                    <div className={`${style.badge} ${badge.isOpen ? style.unlocked : ''} ${style[`bg-${index + 1}`]}`} key={index}>
                                        <span className={`page-badge-icon ${badge.color} ${badge.isOpen ? 'unlocked' : ''}`}>{badge.page}</span>
                                        <h6>{badge.title}</h6>
                                        <p className={style.desc}>{badge.description}</p>
                                        <p className={style.point}>{badge.pointMin} {achievement.point}</p>
                                        <span className={`${style.accent} ${style[`accent-${index + 1}`]} ${badge.isOpen ? '' : style.locked}`}>{badge.accent}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BadgesPage;