import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { accentSVG, contentTranslate, useAuth, useRole } from '../../services';
import { Check, ButtonPrimary, login, authDispatchEvents } from '../../features';
import style from './login.module.scss';

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname;
    const { auth, setAuth } = useAuth();
    const { setRole } = useRole();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [errMsg, setErrorMsg] = useState('');
    const [invalidEmailMsg, setInvalidEmailMsg] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [isPassValid, setIsPassValid] = useState(null);
    const [remember, setRemember] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const getText = contentTranslate(lang);
    const getSVG = accentSVG();
    const [mobile, setMobile] = useState(window.innerWidth <= 992);
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.size !== 0 ? searchParams.get('email') : '';
    const passParam = searchParams.size !== 0 ? searchParams.get('password') : '';
    const [email, setEmail] = useState(emailParam);
    const [password, setPassword] = useState(passParam);
    const [visible, setVisible] = useState(false);

    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const submitRef = useRef(null);
    const [focusedField, setFocusedField] = useState('email');

    useEffect(() => {
        const loader = setTimeout(() => {
            setLang(lang);
        }, 1000);

        if (auth) {
            navigate('/dashboard', { replace: true });
        }
        if (emailParam) {
            setIsEmailValid(validateEmail(emailParam));
        }
        if (passParam) {
            setIsPassValid(validatePassword(passParam));
        }

        const getWindowSize = () => {
            setMobile(window.innerWidth <= 992);
        };

        window.addEventListener('resize', getWindowSize);
        return () => {
            window.removeEventListener('resize', getWindowSize);
            clearTimeout(loader);
        };
    }, [lang, auth, emailParam, passParam, navigate]);

    if (mobile) {
        sessionStorage.setItem('menu', 'close');
        const menuChangeEvent = new Event('menu-change');
        window.dispatchEvent(menuChangeEvent);
    } else {
        sessionStorage.setItem('menu', 'open');
        const menuChangeEvent = new Event('menu-change');
        window.dispatchEvent(menuChangeEvent);
    }

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 1;
    };

    const handleEmail = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        if (newEmail === '') {
            setIsEmailValid(false);
            setInvalidEmailMsg(getText.login.form.emptyEmail);
            return;
        }
        setIsEmailValid(validateEmail(newEmail) || newEmail === emailParam);
    };

    const handlePassword = (event) => {
        const newPass = event.target.value;
        setPassword(newPass);
        if (newPass === '') {
            setIsPassValid(false);
            return;
        }
        setIsPassValid(validatePassword(newPass) || newPass === passParam);
    };

    const handleCheck = () => {
        setRemember(!remember);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isEmailValid || !isPassValid) {
            handleInvalidInputs();
            return;
        }
        setIsLoading(true);
        try {
            const { authenticated, role } = await login(email, password, remember);
            if (authenticated) {
                handleSuccessfulLogin(role);
                return;
            }
            handleFailedLogin();
        } catch (error) {
            navigate('/oops/500', { replace: true });
        } finally {
            setIsLoading(false);
        }
    };

    const handleInvalidInputs = () => {
        if (isEmailValid === null) {
            setIsEmailValid(false);
            setInvalidEmailMsg(getText.login.form.emptyEmail);
        } else if (isEmailValid === false) {
            setIsEmailValid(false);
            setInvalidEmailMsg(getText.login.form.invalidEmail);
        }
        if (isPassValid === null || isPassValid === false) {
            setIsPassValid(false);
        }
    };

    const handleSuccessfulLogin = (role) => {
        setAuth(true);
        setRole(role);
        authDispatchEvents();
        navigate(from, { replace: true });
    };

    const handleFailedLogin = () => {
        setEmail('');
        setPassword('');
        setErrorMsg(getText.login.form.error);
    };

    const handleVisibility = () => {
        setVisible(!visible);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();

            if (focusedField === 'email') {
                passwordRef.current.focus();
                setFocusedField('password');
            } else if (focusedField === 'password') {
                submitRef.current && submitRef.current.focus();
                setFocusedField('submit');
            } else if (focusedField === 'submit') {
                emailRef.current.focus();
                setFocusedField('email');
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();

            if (focusedField === 'submit') {
                passwordRef.current.focus();
                setFocusedField('password');
            } else if (focusedField === 'password') {
                emailRef.current.focus();
                setFocusedField('email');
            } else if (focusedField === 'email') {
                submitRef.current && submitRef.current.focus();
                setFocusedField('submit');
            }
        }
    };

    return (
        <div className={`vph ${style.login}`}>
            <div className={`d-flex d-md-none ${style.logo}`}>{getSVG.logo.gfdp}</div>

            <div className={`${style['form-wrapper']}`}>
                <div className={style.header}>
                    <h5>{getText.login.title}</h5>
                    <p>{getText.login.subtitle}</p>
                </div>

                {errMsg !== '' && (
                    <div className="alert alert-danger d-flex align-items-center" role="alert">
                        {getSVG.login.error}
                        <span>{errMsg} <Link to="/">{getText.login.onboard}</Link></span>
                    </div>
                )}

                <form id={style['login-form']} noValidate onSubmit={handleSubmit}>
                    <div className={style['form-section']}>
                        <label className="form-label" htmlFor="email">{getText.login.form.emailLB}</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            className={`form-control custom-input ${isEmailValid === false ? 'is-invalid' : ''}`}
                            placeholder={getText.login.form.emailPH}
                            onChange={handleEmail}
                            ref={emailRef}
                            onKeyDown={handleKeyDown}
                            tabIndex={focusedField === 'email' ? 0 : -1}
                            autoFocus
                        />
                        <span className="invalid-feedback">{invalidEmailMsg}</span>
                    </div>
                    <div className={style['form-section']}>
                        <label className="form-label" htmlFor="password">Password</label>
                        <input
                            id="password"
                            name="password"
                            type={visible ? 'text' : 'password'}
                            value={password}
                            className={`form-control custom-input ${isPassValid === false ? 'is-invalid' : ''}`}
                            placeholder={getText.login.form.passPH}
                            onChange={handlePassword}
                            ref={passwordRef}
                            onKeyDown={handleKeyDown}
                            tabIndex={focusedField === 'password' ? 0 : -1}
                        />
                        <span className={`${style.visibility} ${isPassValid || isPassValid === null ? '' : style.hidden}`} onClick={handleVisibility}>
                            {visible ? getSVG.login.show : getSVG.login.hide}
                        </span>
                        <span className="invalid-feedback">{getText.login.form.emptyPass}</span>
                    </div>
                    <div className={`${style['form-section']} ${style.middle}`}>
                        <div className={`form-check ${style.remember}`}>
                            <Check id="remember" name="remember" type="checkbox" className="form-check-input" value={remember} onChange={handleCheck} checked={remember} />
                            <label className="form-check-label" htmlFor="remember">{getText.login.form.checkLB}</label>
                        </div>

                        <div>
                            <Link to='/forgot'>{getText.login.form.forgot}</Link>
                        </div>
                    </div>
                    <div className={style['form-section']}>
                        <ButtonPrimary type="submit" className={`${isLoading ? 'temp-disabled' : ''}`} ref={submitRef} onKeyDown={handleKeyDown} tabIndex={focusedField === 'submit' ? 0 : -1}>
                            {isLoading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                getText.login.form.button
                            )}
                        </ButtonPrimary>
                    </div>
                </form>
            </div>

            <div className={`d-flex d-md-none ${style.onboard}`}>
                <p>{getText.login.p1}</p>
                <p className={style.plink}>{getText.login.p2} <Link to="/donation/monthly">{getText.login.onboard}</Link></p>
            </div>
        </div>
    );
}

export default LoginPage;