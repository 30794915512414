import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, ButtonPrimary, submitForgot } from '../../features';
import { accentSVG, contentTranslate } from '../../services';
import style from './forgot.module.scss';

const ForgotPage = () => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const getSVG = accentSVG();
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [invalidEmailMsg, setInvalidEmailMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        const loader = setTimeout(() => {
            setLang(lang);
        }, 1000);

        return () => {
            clearTimeout(loader);
        };
    }, [lang]);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmail = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setIsEmailValid(validateEmail(newEmail));
    };

    const handleInvalidInputs = () => {
        if (isEmailValid === null) {
            setIsEmailValid(false);
            setInvalidEmailMsg(getText.login.form.emptyEmail);
        } else if (isEmailValid === false) {
            setIsEmailValid(false);
            setInvalidEmailMsg(getText.login.form.invalidEmail);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isEmailValid) {
            handleInvalidInputs();
            return;
        }
        setIsLoading(true);
        try {
            const success = await submitForgot(email);
            if (success) {
                setEmail('');
                setIsSuccess(true);
                setShowLogin(true);
            } else {
                setIsSuccess(false);
            }
            callToast();
        } catch (error) {
            navigate('/oops/500', { replace: true });
        } finally {
            setIsLoading(false);
        }
    };

    const callToast = () => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    return (
        <div className={`vph ${style.forgot}`}>
            <div className={`d-flex d-md-none ${style.logo}`}>{getSVG.logo.gfdp}</div>

            <div className={`${style['form-wrapper']}`}>
                <div className={`${style.toast} ${showToast ? style.show : ''} ${isSuccess ? style.success : style.error}`}>
                    <div className={style['toast-icon']}>{isSuccess ? getSVG.dashboard.success : getSVG.dashboard.error}</div>
                    <div className={`${style.message} `}>
                        <h6>{isSuccess ? getText.dashboard.profile.toast.success : getText.dashboard.profile.toast.error}</h6>
                        <p>{isSuccess ? getText.forgot.toast.success : getText.forgot.toast.failed}</p>
                    </div>
                </div>

                <div className={style.header}>
                    <p>{getText.forgot.title}</p>
                </div>

                <form id={style['forgot-form']} noValidate onSubmit={handleSubmit}>
                    <div className={style['form-section']}>
                        <label className="form-label" htmlFor="email">{getText.forgot.form.emailLB}</label>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            className={`form-control custom-input ${isEmailValid === false ? 'is-invalid' : ''}`}
                            placeholder={getText.forgot.form.emailPH}
                            onChange={handleEmail}
                        />
                        <span className="invalid-feedback">{invalidEmailMsg}</span>
                    </div>
                    <div className={style['form-section']}>
                        <ButtonPrimary type="submit" className={`${isLoading ? 'temp-disabled' : ''}`}>
                            {isLoading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                getText.forgot.form.button
                            )}
                        </ButtonPrimary>
                    </div>
                </form>

                <Link to='/login' className={`${style.login} ${showLogin ? style.show : ''}`}>{getText.forgot.back}</Link>
            </div>

            <div className={`d-flex d-md-none ${style.onboard}`}>
                <p>{getText.forgot.p1}</p>
                <p className={style.plink}>{getText.forgot.p2} <Link to="/">{getText.forgot.onboard}</Link></p>
            </div>
        </div>
    );
}

export default ForgotPage;