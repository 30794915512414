import getbca from './bca.webp';
import getbni from './bni.webp';
import getbri from './bri.webp';
import getbri_dd from './bri_dd.webp';
import getdana from './dana.webp';
import getgopay from './gopay.webp';
import getjcb from './jcb.webp';
import getlinkaja from './linkaja.webp';
import getmandiri from './mandiri.webp';
import getmastercard from './mastercard.webp';
import getovo from './ovo.webp';
import getpaypal from './paypal.webp';
import getpermata from './permata.webp';
import getqris from './qris.webp';
import getshopee from './shopeepay.webp';
import getvisa from './visa.webp';

export const bca = getbca;
export const bni = getbni;
export const bri = getbri;
export const bri_dd = getbri_dd;
export const dana = getdana;
export const gopay = getgopay;
export const jcb = getjcb;
export const linkaja = getlinkaja;
export const mandiri = getmandiri;
export const mastercard = getmastercard;
export const ovo = getovo;
export const paypal = getpaypal;
export const permata = getpermata;
export const qris = getqris;
export const shopeepay = getshopee;
export const visa = getvisa;