import { checkJwt } from "./api-helper";

export async function downloadReport(form) {
    const token = await checkJwt();
    const formRequest = {
        name: form.name,
        phone: form.phone,
        email: form.email,
        lang: form.lang
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/publics/report`, {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: new URLSearchParams(formRequest)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.ok;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export async function getReportPDF(lang) {
    const pdfUrl = `${process.env.PUBLIC_URL}/pdf/${lang.toUpperCase()}-Impact-Report-GFDP-2023.pdf`;
    await fetch(pdfUrl).then((response) => {
        response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = pdfUrl;
            alink.click();
        });
    });
}