import { checkJwt } from "../../../services";

export async function signup(form) {
    const token = await checkJwt();

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/users/volunteer`, {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: new URLSearchParams(form)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.ok;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}