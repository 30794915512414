import { useLocation, Navigate } from 'react-router-dom';
import { useAuth, useRole } from '../../services';

const DashboardRole = () => {
    const { role } = useRole();
    const { auth } = useAuth();
    const location = useLocation();

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!role) {
        return <Navigate to="/oops/401" state={{ from: location }} replace />;
    }

    switch (role) {
        case 'donor':
            return <Navigate to="/dashboard/donor" state={{ from: location }} replace />;
        case 'volunteer':
            return <Navigate to="/dashboard/volunteer" state={{ from: location }} replace />;
        default:
            return <Navigate to="/oops/401" state={{ from: location }} replace />;
    }
};

export default DashboardRole;
