export function termsConditions(lang) {
    const content = {
        id: <>
            <h3 className='fw-bold'>SYARAT DAN KETENTUAN</h3>
            <p>Greeneration Foundation merupakan yayasan yang didirikan dan beroperasi secara sah berdasarkan hukum Republik Indonesia dengan domisili di Bandung, Indonesia. Yayasan ini mengelola Green Fund Digital Philanthropy (GFDP) yaitu sebuah platform donasi bagi donatur yang ingin menyalurkan donasi untuk lingkungan yang berkelanjutan. Greeneration Foundation selaku penyelenggara Green Fund Digital Philanthropy (GFDP) telah mendapatkan izin berkala dari Kementerian Sosial Republik Indonesia.</p>

            <p>Mengacu pada Peraturan Pemerintah Nomor 71 Tahun 2019 tentang Penyelenggaraan Sistem dan Transaksi Elektronik khususnya Pasal 47 sampai Pasal 50, maka Syarat dan Ketentuan ini merupakan suatu kontrak elektronik antara Donatur dan Greeneration Foundation. Oleh karena itu para pihak menyatakan, menyepakati dan terikat atas setiap ketentuan yang terdapat dalam Syarat dan Ketentuan ini selayaknya suatu perjanjian yang sah.</p>

            <p>Greeneration Foundation berhak atas kebijakan untuk mengubah atau memodifikasi sebagian atau keseluruhan dari Syarat dan Ketentuan ini setiap saat atau secara sepihak. Apabila hal tersebut terjadi maka kami akan melakukan pemberitahuan kepada pengguna melalui email <a href='mailto:donasigf@greeneration.org'>donasigf@greeneration.org</a>.</p>

            <p>Donatur sangat dianjurkan untuk membaca dengan seksama segala ketentuan dibawah ini karena akan berdampak pada hak dan kewajiban sebagai Donatur. Jika Donatur tidak menyetujui salah satu, sebagian, atau seluruh isi Syarat dan Ketentuan, maka Donatur tidak diperkenankan menggunakan layanan di Green Fund Digital Philanthropy (GFDP).</p>

            <ol className='custom-list'>
                <li>
                    <strong>DEFINISI</strong>
                    <ol className='subtitle-list'>
                        <li>Syarat dan Ketentuan adalah perjanjian antara Donatur dan Greeneration Foundation yang mengatur hak, kewajiban dan tanggung jawab serta tata cara penggunaan platform Green Fund Digital Philanthropy (GFDP) melalui situs website <a href='https://donation.greeneration.org/'>donation.greeneration.org</a>.</li>
                        <li>Donatur adalah  individu secara Perorangan atau Institusi yang memberikan donasi secara sukarela kepada Greeneration Foundation untuk mendukung upaya-upaya percepatan solusi lingkungan dalam hal ini melalui situs website <a href='https://donation.greeneration.org/'>donation.greeneration.org</a>.</li>
                        <li>Donasi adalah suatu aktivitas pemberian yang memiliki sifat sukarela (dalam hal ini dalam bentuk dana atau uang) dengan tanpa adanya imbalan bersifat keuntungan kepada orang lain.</li>
                        <li>Program adalah suatu gerakan yang dilakukan oleh Greeneration Foundation untuk menyelesaikan masalah lingkungan seperti Advokasi, Edukasi dan Pemberdayaan Masyarakat.</li>
                    </ol>
                </li>
                <li>
                    <strong>KEBIJAKAN DONASI DAN DONATUR</strong>
                    <ol className='subtitle-list'>
                        <li>
                            <strong>Komunikasi</strong>: Donatur dan Greeneration Foundation dapat dilakukan melalui kontak email <a href='mailto:donasigf@greeneration.org'>donasigf@greeneration.org</a> dan Whatsapp official <a href='tel:6281223330373'>(+62) 812-2333-0373</a> Greeneration Foundation yang tertera pada situs, segala bentuk komunikasi di luar kontak diatas merupakan tanggung jawab Donatur.
                        </li>
                        <li>
                            <strong>Informasi</strong>: Bentuk informasi yang terjadi dilindungi kerahasiaannya oleh pihak Greeneration Foundation, kami tidak menyimpan informasi kartu debit/kredit donatur. Data tersebut tersimpan secara aman melalui jasa pembayaran pihak ketiga yang bekerjasama dengan kami yaitu Xendit. Informasi yang diberikan Donatur berupa:
                            <ul className='point-list'>
                                <li>Nama, Nomor Handphone, dan Alamat email; dan</li>
                                <li>detail kartu debit/kredit yang digunakan untuk memberikan donasi rutin, hanya akan dipergunakan untuk keperluan Administrasi, Retensi  dan/atau Pelaporan atas donasi yang diberikan.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Transaksi</strong>: Bentuk transaksi yang terjadi di dalam situs donasi Greeneration Foundation dilindungi kerahasiaannya dan menjadi tanggung jawab pihak Greeneration Foundation, dengan catatan segala bentuk transaksi sesuai dengan ketentuan yang ada di dalam situs. Adapun proses transaksi meliputi sebagai berikut:
                            <ul className='point-list'>
                                <li>Proses transaksi donasi hanya dapat dilakukan melalui situs donasi Greeneration Foundation, dalam hal ini melalui situs <a href='https://donation.greeneration.org/'>donation.greeneration.org</a> dengan menggunakan beberapa metode donasi yang sudah tersedia di dalamnya.</li>
                                <li>Donatur dapat menghubungi pihak Greeneration Foundation jika menemui kesulitan dalam melakukan donasi di situs donasi Greeneration Foundation.</li>
                                <li>Donatur akan menerima konfirmasi donasi melalui email dan Whatsapp</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Donasi Berlangganan</strong>: Proses transaksi donasi bulanan akan dilakukan dalam dua skema yaitu:
                            <ul className='point-list'>
                                <li>Penarikan secara otomatis yang donasinya dilakukan menggunakan kartu debit/kredit diawali dengan pengiriman invoice melalui email,</li>
                                <li>Donasi yang menggunakan virtual account,  e-wallet dan Qris proses donasinya akan diawali dengan proses <em>remainder</em> lalu dilanjutkan pengiriman invoice kepada email donatur.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>KEBIJAKAN PEMBATALAN</strong>
                    <p>Jika Donatur ingin melakukan pembatalan terhadap donasi rutin yang dilakukan, donatur dapat melakukan langkah sebagai berikut:</p>
                    <ol className='subtitle-list'>
                        <li>Mengisi formulir yang dikirimkan beserta invoice setiap bulannya melalui email masing-masing.</li>
                        <li>Proses pembatalan akan dilakukan dalam 7-14 hari kerja. Jika dalam rentang waktu tersebut donatur belum menerima konfirmasi pembatalan, donatur dapat segera menghubungi kami melalui email <a href='mailto:donasigf@greeneration.org'>donasigf@greeneration.org</a>.</li>
                        <li>Donatur dapat menghubungi pihak Greeneration Foundation jika menemui kesulitan dalam melakukan donasi di situs donasi Greeneration Foundation.</li>
                        <li>Donatur akan menerima konfirmasi pembatalan melalui email dan Whatsapp</li>
                    </ol>
                </li>
                <li>
                    <strong>PEMBATASAN TANGGUNG JAWAB</strong>
                    <ol className='subtitle-list'>
                        <li>Platform pembayaran dalam website ini terhubung ke Penyedia Layanan Pembayaran dalam hal ini Xendit yang telah terdaftar dan diawasi oleh Otoritas Jasa Keuangan.</li>
                        <li>Greeneration Foundation bertanggung jawab penuh atas semua segala informasi dan proses transaksi yang ada di situs donasi Greeneration Foundation, jika ada terdapat informasi dan transaksi diluar situs <a href='https://donation.greeneration.org/'>donation.greeneration.org</a> menjadi tanggung jawab Donatur.</li>
                    </ol>
                </li>
                <li>
                    <strong>KEADAAN MEMAKSA <em>(Force Majeure)</em></strong>
                    <ol className='subtitle-list'>
                        <li>Jika ada keadaan yang tak terduga diluar kemampuan manusia dan/atau tidak dapat dihindari seperti peperangan, kerusuhan, kebakaran, bencana alam, wabah/pandemi dan lainnya yang dinyatakan oleh instansi berwenang, maka Donatur bersedia memahami dan mengerti bahwa Platform dapat berhenti beroperasi.</li>
                        <li>Jika terdapat perselisihan maka Greeneration Foundation akan mengupayakan untuk menyelesaikan perselisihan melalui musyawarah untuk mufakat dengan tetap taat terhadap hukum yang berlaku di Republik Indonesia.</li>
                    </ol>
                </li>
                <li>
                    <strong>LAIN-LAIN</strong>
                    <ol className='subtitle-list'>
                        <li>Kami sebagai platform penghimpun donasi untuk lingkungan berkelanjutan selalu ingin meningkatkan kualitas situs kami, maka dari itu kami mengharapkan feedback dari Donatur kepada kami agar dapat terus berkembang. Segala bentuk komplain, masukan, kritik, dan saran dari Donatur dapat disampaikan melalui email kami <a href='mailto:donasigf@greeneration.org'>donasigf@greeneration.org</a>, kami akan membantu segala keluhan yang disampaikan dengan baik.</li>
                        <li>Segala bentuk pemberitahuan dari situs donasi Greeneration Foundation akan kami sampaikan melalui email pengguna secara langsung.</li>
                        <li>Laporan pertanggungjawaban akan dilakukan oleh Greeneration Foundation kepada Kementerian Sosial Republik Indonesia dan kepada donatur/publik yang akan diunggah dalam website Greeneration Foundation secara berkala di setiap tahunnya.</li>
                    </ol>
                </li>
                <li>
                    <strong>PENUTUP</strong>
                    <p>Demikian syarat dan ketentuan yang berlaku pada platform Green Fund Digital Philanthropy (GFDP), dengan ini maka Donatur dianggap telah membaca, mengerti, memahami dan menyetujui dengan teliti dan seksama, secara sadar tanpa paksaan dan tekanan dari pihak manapun untuk terikat secara legal oleh semua ketentuan ini. Atas perhatian dan kesepakatannya, kami sangat berterima kasih.</p>
                </li>
            </ol>

            <strong className='mt-4'>Greeneration Foundation</strong>
        </>,
        en: <>
            <h3 className='fw-bold'>TERMS AND CONDITIONS</h3>
            <p>Greeneration Foundation is a foundation that is legally established and operates under the laws of the Republic of Indonesia and domiciled in Bandung, Indonesia. The foundation manages the Green Fund Digital Philanthropy (GFDP), which is a donation platform for donors who wish to make donations for a sustainable environment. As the organizer of the Green Fund Digital Philanthropy (GFDP), Greeneration Foundation has received periodic permits from the Ministry of Social Affairs of the Republic of Indonesia.</p>

            <p>Referring to Indonesia Government Regulation Number 71 of 2019 concerning the Implementation of Electronic Systems and Transactions particularly Article 47 to Article 50, these Terms and Conditions constitute an electronic contract between the Donor and the Greeneration Foundation. Therefore, the parties agreed and are bound by every provision contained in these Terms and Conditions as a valid agreement.</p>

            <p>Greeneration Foundation reserves the right at its discretion to change or modify part or all of these Terms and Conditions at any time or unilaterally. If this happens, we will notify users via email <a href='mailto:donasigf@greeneration.org'>donasigf@greeneration.org</a>.</p>

            <p>Donors are encouraged to read carefully all the provisions below because they will impact on the rights and obligations as a donor. If the Donor does not agree to one, part, or all of the contents of the Terms and Conditions, the Donor is not allowed to use the services at Green Fund Digital Philanthropy (GFDP).</p>

            <ol className='custom-list'>
                <li>
                    <strong>DEFINITIONS</strong>
                    <ol className='subtitle-list'>
                        <li>Terms and Conditions is an agreement between the Donor and the Greeneration Foundation which regulates the rights, obligations and responsibilities as well as the procedures for using the Green Fund Digital Philanthropy (GFDP) platform through <a href='https://donation.greeneration.org/'>donation.greeneration.org</a>.</li>
                        <li>Donors are individuals or institutions which give voluntary donations to the Greeneration Foundation through its programs.</li>
                        <li>The program is carried out by Greeneration Foundation to solve environmental problems such as Advocacy, Education and Community Empowerment.</li>
                    </ol>
                </li>
                <li>
                    <strong>DONATION POLICY</strong>
                    <ol className='subtitle-list'>
                        <li>
                            <strong>Communication</strong>: Donors can contact Greeneration Foundation through email <a href='mailto:donasigf@greeneration.org'>donasigf@greeneration.org</a> and the official Whatsapp <a href='tel:6281223330373'>(+62) 812-2333-0373</a> Greeneration Foundation listed on the website, all forms of communication outside the contact above are the responsibility of the Donor.
                        </li>
                        <li>
                            <strong>Information</strong>: The form of information that occurred is protected by confidentiality by the Greeneration Foundation, and we don’t store donor debit/credit card information. The information provided by the Donor is in the form of;
                            <ul className='point-list'>
                                <li>Name, Mobile Number, and Email Address;</li>
                                <li>details of the debit/credit card used to make regular donations, will only be used for the purposes of Administration, Retention and/or Reporting on donations given.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Transaction</strong>: All transactions that occur on the Greeneration Foundation donation site are kept confidential and are managed by the Greeneration Foundation, which provided that all forms of transactions comply with the provisions contained on the site. The transaction process includes the following:
                            <ul className='point-list'>
                                <li>The donation transaction process can only be done through the Greeneration Foundation donation through the <a href='https://donation.greeneration.org/'>donation.greeneration.org</a> site with various payment methods using several donation methods that are already available in it.</li>
                                <li>Donors can contact the Greeneration Foundation if they encounter difficulties in making donations on the Greeneration Foundation donation site.</li>
                                <li>Donors will receive donation confirmation via email and Whatsapp.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Subscription Policy</strong>: The process of monthly donation transaction will be carried out in two schemes;
                            <ul className='point-list'>
                                <li>Automatic withdrawals where donations are made using a debit/credit card starting with sending an invoice via email.</li>
                                <li>Donations using a virtual account, e-wallet and Qris. The donation process will begin with the reminder process then proceed with sending the invoice to the donor's email.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>CANCELLATION POLICY</strong>
                    <p>If the Donor wants to cancel the routine donation, the donor is responsible to take the following steps:</p>
                    <ol className='subtitle-list'>
                        <li>Fill out the form that is sent along with the invoice every month via email respectively.</li>
                        <li>The cancellation process will be carried out in 7-14 working days. Prior that time in case the donor has not been notified, the donor can immediately contact us via email at <a href='mailto:donasigf@greeneration.org'>donasigf@greeneration.org</a>.</li>
                        <li>Donors can contact Greeneration Foundation if they encounter any difficulties while making the donation on Greeneration Foundation donation site.</li>
                        <li>Donors will receive cancellation confirmation through email and/or Whatsapp number.</li>
                    </ol>
                </li>
                <li>
                    <strong>LIMITATION OF LIABILITY</strong>
                    <ol className='subtitle-list'>
                        <li>The payment platforms on this website are linked to Payment Service Provider which has been registered and supervised by the Financial Services Authority.</li>
                        <li>Greeneration Foundation is fully responsible for all information and transaction processes on the Greeneration Foundation donation site, if there is information and transactions outside from the Greeneration Foundation donation site should be the responsibility of the donor.</li>
                    </ol>
                </li>
                <li>
                    <strong>FORCE MAJEURE</strong>
                    <ol className='subtitle-list'>
                        <li>If there are unforeseen circumstances beyond human capabilities and/or unavoidable such as war, riots, fires, natural disasters, epidemics/pandemics and others declared by the authorized agency, the Donor is willing to understand that the Platform may stop operating.</li>
                        <li>If there any kind of dispute, the Greeneration Foundation will endeavor to resolve the dispute through deliberation to reach a consensus while still complying with the laws in force in the Republic of Indonesia.</li>
                    </ol>
                </li>
                <li>
                    <strong>OTHERS</strong>
                    <ol className='subtitle-list'>
                        <li>We as a platform for collecting donations for a sustainable environment always want to improve the quality of our site, therefore we expect feedback from donors so that we can continue to grow. All forms of complaints, input, criticism, and suggestions can be submitted via our email <a href='mailto:donasigf@greeneration.org'>donasigf@greeneration.org</a>.</li>
                        <li>All forms of notifications from the Greeneration Foundation donation site will be conveyed via email to users directly.</li>
                        <li>An accountability report will be made by the Greeneration Foundation to the Ministry of Social Affairs of the Republic of Indonesia and to donors/public which will be uploaded on the Greeneration Foundation website periodically every year.</li>
                    </ol>
                </li>
                <li>
                    <strong>CLOSING</strong>
                    <p>Thus the terms and conditions applied in the Green Fund Digital Philanthropy (GFDP) platform. Hereby the Donor is deemed to have read, understood, and agreed carefully and thoroughly, consciously without coercion and pressure from any party to be legally bound by all provisions.</p>
                </li>
            </ol>

            <strong className='mt-4'>Greeneration Foundation</strong>
        </>,
    };

    return content[lang];
}