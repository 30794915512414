import { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Input, ButtonPrimary, checkToken, resetPassword } from '../../features';
import { accentSVG, contentTranslate } from '../../services';
import style from './reset.module.scss';

const ResetPage = () => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const text = getText.reset;
    const getSVG = accentSVG();
    const [isLoading, setIsLoading] = useState(false);
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [isPassValid, setIsPassValid] = useState(null);
    const [isConfirmValid, setIsConfirmValid] = useState(null);
    const [invalidPassMsg, setInvalidPassMsg] = useState('');
    const [invalidConfirmMsg, setInvalidConfirmMsg] = useState('');

    const [passVisible, setPassVisible] = useState(false);
    const [confVisible, setConfVisible] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [toastMsg, setToastMsg] = useState('');

    const [showLogin, setShowLogin] = useState(false);
    const [expired, setExpired] = useState(false);
    const [reqToken, setReqToken] = useState('');

    const url = useParams();
    const urlToken = url.param;

    const fetchToken = useCallback((signal) => {
        checkToken(urlToken, signal).then(data => {
            if (data?.length > 0) {
                setExpired(false);
                setReqToken(data[0]?.token);
                return;
            }
            setExpired(true);
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [urlToken]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const loader = setTimeout(() => {
            setLang(lang);
        }, 1000);

        fetchToken(signal);

        return () => {
            controller.abort();
            clearTimeout(loader);
        };
    }, [lang, fetchToken]);

    const validatePassword = (password) => {
        if (password.length < 1) {
            setInvalidPassMsg(getText.dashboard.profile.invalid.emptyPass);
            return false;
        }
        setInvalidPassMsg('');
        return true;
    };

    const validateConfirm = (password) => {
        if (password.length < 1) {
            setInvalidConfirmMsg(getText.dashboard.profile.invalid.emptyPass);
            return false;
        } else if (password !== newPass) {
            setInvalidConfirmMsg(getText.dashboard.profile.invalid.unmatchedPass);
            return false;
        }
        return true;
    };

    const handleUpdatePass = (e) => {
        const pass = e.target.value;
        setNewPass(pass);
        if (pass === '') {
            setIsPassValid(false);
            return;
        }
        setIsPassValid(validatePassword(pass));
    };

    const handleConfirmPass = (e) => {
        const confirm = e.target.value;
        setConfirmPass(confirm);
        if (confirm === '') {
            setIsConfirmValid(false);
            return;
        }
        setIsConfirmValid(validateConfirm(confirm));
    };

    const handleInvalidPasswords = () => {
        if (isPassValid === null) {
            setIsPassValid(false)
            setInvalidPassMsg(getText.dashboard.profile.invalid.emptyPass);
        }
        if (isConfirmValid === null) {
            setIsConfirmValid(false)
            setInvalidConfirmMsg(getText.dashboard.profile.invalid.emptyPass);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isPassValid || !isConfirmValid) {
            handleInvalidPasswords();
            return;
        }
        setIsLoading(true);
        try {
            const success = await resetPassword(newPass, reqToken);
            if (success) {
                setNewPass('');
                setConfirmPass('');
                setIsSuccess(true);
                setShowLogin(true);
                setToastMsg(getText.dashboard.profile.toast.passwordSuccess);
            } else {
                setIsSuccess(false);
            }
            callToast();
        } catch (error) {
            navigate('/oops/500', { replace: true });
        } finally {
            setIsLoading(false);
        }
    };

    const callToast = () => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handlePassVisibility = () => {
        setPassVisible(!passVisible);
    };

    const handleConfVisibility = () => {
        setConfVisible(!confVisible);
    };

    return (
        <div className={`vph ${style.reset}`}>
            <div className={`d-flex d-md-none ${style.logo}`}>{getSVG.logo.gfdp}</div>

            {expired ? (
                <div className={style.expired}>
                    <div className="alert alert-danger d-flex align-items-center" role="alert">
                        {getSVG.login.error}
                        <span>Expired Link</span>
                    </div>
                    <Link to="/">{text.back}</Link>
                </div>
            ) : (
                <div className={`${style['form-wrapper']}`}>
                    <div className={`${style.toast} ${showToast ? style.show : ''} ${isSuccess ? style.success : style.error}`}>
                        <div className={style['toast-icon']}>{isSuccess ? getSVG.dashboard.success : getSVG.dashboard.error}</div>
                        <div className={`${style.message} `}>
                            <h6>{isSuccess ? getText.dashboard.profile.toast.success : getText.dashboard.profile.toast.error}</h6>
                            <p>{isSuccess ? toastMsg : getText.dashboard.profile.toast.errorMsg}</p>
                        </div>
                    </div>

                    <div className={style.header}>
                        <p>{text.title}</p>
                    </div>

                    <form id={style['reset-form']} noValidate onSubmit={handleSubmit}>
                        <div className={style['form-section']}>
                            <label className="form-label" htmlFor="password">{text.form.label.password}</label>
                            <Input type={passVisible ? 'text' : 'password'} id="password" name="password" value={newPass} onChange={handleUpdatePass} className={`form-control ${isPassValid === false ? 'is-invalid' : ''}`} placeholder={text.form.placeholder.password} />
                            <span className={`${style.visibility} ${isPassValid || isPassValid === null ? '' : style.hidden}`} onClick={handlePassVisibility}>
                                {passVisible ? getSVG.login.show : getSVG.login.hide}
                            </span>
                            <span className="invalid-feedback">{invalidPassMsg}</span>
                        </div>

                        <div className={style['form-section']}>
                            <label className="form-label" htmlFor="confirm">{text.form.label.confirm}</label>
                            <Input type={confVisible ? 'text' : 'password'} id="confirm" name="confirm" value={confirmPass} onChange={handleConfirmPass} className={`form-control ${isConfirmValid === false ? 'is-invalid' : ''}`} placeholder={text.form.placeholder.confirm} />
                            <span className={`${style.visibility} ${isConfirmValid || isConfirmValid === null ? '' : style.hidden}`} onClick={handleConfVisibility}>
                                {confVisible ? getSVG.login.show : getSVG.login.hide}
                            </span>
                            <span className="invalid-feedback">{invalidConfirmMsg}</span>
                        </div>

                        <div className={style['form-section']}>
                            <ButtonPrimary type="submit" className={`${isLoading ? 'temp-disabled' : ''}`}>
                                {isLoading ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    text.form.button
                                )}
                            </ButtonPrimary>
                        </div>
                    </form>

                    <Link to='/login' className={`${style.login} ${showLogin ? style.show : ''}`}>Login</Link>
                </div>
            )}

            <div className={`d-flex d-md-none ${style.onboard}`}>
                <p>{text.p1}</p>
                <p className={style.plink}>{text.p2} <Link to="/">{text.onboard}</Link></p>
            </div>
        </div>
    );
}

export default ResetPage;