import { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { accentSVG, contentTranslate, navDashboard, getRecurring, useAuth, capitalize } from '../../../../services';
import { getFirst, getProfile, getUserType } from '../../../users';
import { BadgeModal, ButtonSecondary, PasswordModal, RecurringModal, ShareModal, WelcomeModal } from '..';
import clipboardCopy from 'clipboard-copy';
import style from './sidebar.module.scss';
import avatarDefault from '../../../../assets/img/user.png';

const Sidebar = ({ id, className }) => {
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const publicContent = process.env.REACT_APP_PUBLIC_CONTENT;
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const { auth } = useAuth();
    const [user, setUser] = useState(auth ? getProfile() : null);
    const { fullname, email, avatar } = user
        ? { fullname: capitalize(user.fullname), email: user.email, avatar: user.avatar ? publicContent + user.avatar : avatarDefault }
        : { fullname: '', email: null, avatar: avatarDefault };
    const userType = getUserType();
    const refValue = user ? user.referral_code : '';
    const getText = contentTranslate(lang);
    const getSVG = accentSVG();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const donationIndex = pathSegments.indexOf('donation');
    const type = pathSegments.slice(donationIndex + 1).join('/');
    const activeNav = location.pathname;
    const sidebarDonation = getText.sidebar.donation;
    const sidebarDashboard = getText.sidebar.dashboard;
    const [recurring, setRecurring] = useState([]);
    const [isOpen, setIsOpen] = useState(sessionStorage.getItem('menu') === 'open' || sessionStorage.getItem('menu') === null);
    const [tablet, setTablet] = useState(window.innerWidth <= 992);
    const [mobile, setMobile] = useState(window.innerWidth <= 768);
    const menuRef = useRef(null);
    const first = getFirst();
    const [recurringShow, setRecurringShow] = useState(false);
    const [passwordShow, setPasswordShow] = useState(first ? false : true);
    const [badgeShow, setBadgeShow] = useState(false);
    const [welcomeShow, setWelcomeShow] = useState(true);
    const link = `${baseUrl}/donation/monthly?ref=${user ? user.referral_code : ''}`;
    const [isCopied, setIsCopied] = useState(false);
    const [shareShow, setShareShow] = useState(false);

    const showRecurring = useCallback((signal) => {
        getRecurring(email, signal).then(data => {
            if (data) {
                setRecurring(data.amount);
            }
        }).catch(error => {
            console.error('An error occurred:', error);
        });
    }, [email]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (type === '') {
            navigate('/oops/404');
        }

        if (auth && email) {
            showRecurring(signal);
        }

        const handleAmountChangeEvent = () => {
            showRecurring(signal);
        };

        const handleProfileChangeEvent = () => {
            setUser(getProfile());
        };

        const handlePasswordUpdateEvent = () => {
            setBadgeShow(true);
        };

        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        const handleMenuChangeEvent = () => {
            setIsOpen(sessionStorage.getItem('menu') === 'open');
        };

        const handleWindowResize = () => {
            setTablet(window.innerWidth <= 992);
            setMobile(window.innerWidth <= 768);
            sessionStorage.setItem('menu', tablet ? 'close' : 'open');
            const menuChangeEvent = new Event('menu-change');
            window.dispatchEvent(menuChangeEvent);
        };

        const handleDocumentClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        const handleDocumentScroll = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('menu-change', handleMenuChangeEvent);
        window.addEventListener('amount-change', handleAmountChangeEvent);
        window.addEventListener('profile-change', handleProfileChangeEvent);
        window.addEventListener('password-update', handlePasswordUpdateEvent);
        window.addEventListener('resize', handleWindowResize);
        if (tablet) {
            document.addEventListener('click', handleDocumentClick);
            document.addEventListener('scroll', handleDocumentScroll);
        }
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('menu-change', handleMenuChangeEvent);
            window.removeEventListener('amount-change', handleAmountChangeEvent);
            window.removeEventListener('profile-change', handleProfileChangeEvent);
            window.removeEventListener('password-update', handlePasswordUpdateEvent);
            window.removeEventListener('resize', handleWindowResize);
            if (tablet) {
                document.removeEventListener('click', handleDocumentClick);
                document.removeEventListener('scroll', handleDocumentScroll);
            }
            controller.abort();
        };
    }, [type, tablet, auth, email, navigate, showRecurring]);

    const handleBack = () => {
        localStorage.removeItem('donation');
    }

    const menuToggle = () => {
        setIsOpen(!isOpen);
        sessionStorage.setItem('menu', isOpen ? 'close' : 'open');
        const menuChangeEvent = new Event('menu-change');
        window.dispatchEvent(menuChangeEvent);
    };

    const closeMenu = () => {
        setIsOpen(false);
        sessionStorage.setItem('menu', 'close');
        const menuChangeEvent = new Event('menu-change');
        window.dispatchEvent(menuChangeEvent);
    };

    const copyToClipboard = (string) => {
        clipboardCopy(string).then(() => {
            setIsCopied(true);
        }).catch((err) => {
            setIsCopied(false);
            console.error('Copy failed: ', err);
        });
    };

    const handleCopy = () => {
        copyToClipboard(link);
        setTimeout(() => setIsCopied(false), 1500);
    };

    return (
        <aside ref={menuRef} className={`vph ${style.sidebar} ${style[id]} ${className} ${isOpen ? '' : style.close}`}>
            {id === 'donation' ? (
                mobile ? (
                    <WelcomeModal show={welcomeShow} onHide={() => setWelcomeShow(false)} type={type} />
                ) : (
                    <>
                        <div className={style.top}>{getSVG.logo.gfdp}</div>
                        <div className={style.middle}>
                            {type === 'monthly' || type === 'monthly/form/recurring' || type === 'monthly/form/reminder' ? (
                                <>
                                    <h4>{sidebarDonation.monthly.title}</h4>
                                    <p>{sidebarDonation.monthly.subtitle}</p>
                                </>
                            ) : type === 'single/form' ? (
                                <>
                                    <h4>{sidebarDonation.single.title}</h4>
                                    <p>{sidebarDonation.single.subtitle}</p>
                                    <Link to='/donation/monthly'>{sidebarDonation.single.button}</Link>
                                </>
                            ) : type === 'boost/form' ? (
                                <>
                                    <h4>{sidebarDonation.boost.title}</h4>
                                    <p>{sidebarDonation.boost.subtitle}</p>
                                </>
                            ) : null}
                        </div>
                        <div className={style.bottom}>
                            {type === 'boost/form' ? (
                                <a onClick={handleBack} href='/dashboard'><span>{getSVG.navigation.back}</span>{sidebarDonation.navigation.dashboard}</a>
                            ) : (
                                <a onClick={handleBack} href='/'><span>{getSVG.navigation.back}</span>{sidebarDonation.navigation.home}</a>
                            )}
                            {/* <Link onClick={handleBack} to='/'><span>{getSVG.navigation.back}</span>{sidebarDonation.navigation.home}</Link> */}
                        </div>
                    </>
                )
            ) : (
                <>
                    {!first && (
                        <PasswordModal show={passwordShow} onHide={() => setPasswordShow(false)} />
                    )}
                    <BadgeModal show={badgeShow} onHide={() => setBadgeShow(false)} />
                    {!mobile ? (
                        <>
                            <div className={style.top}>
                                <span className={style.logo}>{getSVG.logo.gfdp}</span>
                                <span className={style.menu} onClick={menuToggle}>{getSVG.sidebar.menu}</span>
                            </div>
                            <div className={style.middle}>
                                <ul className={style['dashboard-nav']}>
                                    {navDashboard(lang).map((nav, index) => (
                                        userType === nav.user && (
                                            <li className={style['nav-item']} key={index}>
                                                <Link to={nav.link} onClick={tablet ? closeMenu : ''} className={`${style['link-nav']} ${activeNav === nav.link ? style.active : ''}`}>
                                                    <span className={style.icon}>{nav.icon}</span>
                                                    <span className={style['link-title']}>{nav.title}</span>
                                                </Link>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            </div>
                            <div className={style.bottom}>
                                {userType === 'donor' ? (
                                    <div className={style.card}>
                                        <span className='flex-shrink-0'>{getSVG.sidebar.card}</span>
                                        <div className={style.content}>
                                            <p className={style.header}>{getText.sidebar.donation.recurring}</p>
                                            <div className={style.body}>
                                                <h4>Rp. {parseInt(recurring).toLocaleString('id-ID')}</h4>
                                                <span className={style.edit} onClick={() => setRecurringShow(true)}>{getSVG.sidebar.pen}</span>
                                            </div>
                                        </div>
                                        <RecurringModal show={recurringShow} onHide={() => setRecurringShow(false)} />
                                    </div>
                                ) : (
                                    <div className={`${style.card} ${style.volunteer}`}>
                                        <span className={`flex-shrink-0 ${style['vol-icon']}`}>{getSVG.sidebar.link}</span>
                                        <div className={style.content}>
                                            <p className={style.header}><span>{getSVG.sidebar.link}</span>Referral Link</p>
                                            <div className={style.body}>
                                                <input id='referral' name='referral' className={`form-control ${style['small-input']}`} value={refValue} disabled />
                                                <span className={style.copy} onClick={handleCopy}>
                                                    {isCopied ? (
                                                        <div className={style.container}>
                                                            <figure className={style.checkmark}></figure>
                                                        </div>
                                                    ) : (
                                                        getSVG.sidebar.copy
                                                    )}
                                                </span>
                                            </div>
                                            <ButtonSecondary className={style.share} onClick={() => setShareShow(true)}>{sidebarDashboard.share} {getSVG.sidebar.arrow}</ButtonSecondary>
                                            <ShareModal show={shareShow} onHide={() => setShareShow(false)} />
                                        </div>
                                    </div>
                                )}

                                <Link to='/dashboard/profile' onClick={tablet ? closeMenu : ''} className={`${style.profile} ${activeNav === '/dashboard/profile' ? style.active : ''}`}>
                                    <span className={style.accent}></span>
                                    <span className={style.username}>{fullname}</span>
                                    <div className='flex-shrink-0'>
                                        <span className={style['closed-avatar']}><img className={style.avatar} src={avatar} alt='profile' loading='lazy' /></span>
                                        <span className={style.arrow}>{getSVG.sidebar.arrow}</span>
                                    </div>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <ul className={style['bottom-nav']}>
                            {navDashboard(lang).map((nav, index) => (
                                userType === nav.user && nav.link !== '/' && (
                                    <li className={style['bottom-nav-item']} key={index}>
                                        <Link to={nav.link} className={`${style['bottom-link-nav']} ${activeNav === nav.link ? style.active : ''}`}>
                                            <span className={style.icon}>{nav.icon}</span>
                                            <span className={style['bottom-link-title']}>{nav.title}</span>
                                        </Link>
                                    </li>
                                )
                            ))}

                            <li className={style['bottom-nav-item']}>
                                <Link to='/dashboard/profile' className={`${style['bottom-link-nav']} ${activeNav === '/dashboard/profile' ? style.active : ''}`}>
                                    {/* <span className={style.icon}>{getSVG.dashboard.profile}</span> */}
                                    <span className={style.icon}>
                                        <img src={avatar} alt='avatar' loading='lazy' />
                                    </span>
                                    <span className={style['bottom-link-title']}>{sidebarDashboard.profile}</span>
                                </Link>
                            </li>

                            <div className={`d-none ${style['floating-menu']}`}>
                                <div className={style['float-profile']}></div>
                                <div className={style['float-recurring']}></div>
                                <div className={style['float-language']}></div>
                            </div>
                        </ul>
                    )}
                </>
            )}

        </aside>
    );
};

export default Sidebar;