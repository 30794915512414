import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { contentTranslate, accentSVG } from '../../services';
import style from './onboarding.module.scss';

const OnboardingPage = () => {
    const location = useLocation();
    const thisUrl = location.pathname;
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const onboard = getText.onboarding;
    const getSVG = accentSVG();
    const searchParams = new URLSearchParams(location.search);
    const referral = searchParams.size !== 0 ? searchParams.get('ref') : null;

    if (referral) {
        const existingData = localStorage.getItem('donation');
        const donationObject = existingData ? JSON.parse(existingData) : {};
        donationObject.ref = referral;
        localStorage.setItem('donation', JSON.stringify(donationObject));
    }

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        };
    }, []);

    const handleBack = () => {
        localStorage.removeItem('donation');
    };

    return (
        <div className='side-gap'>
            <div className={`vph ${style.onboarding}`}>
                <div className={style.logo}>{getSVG.logo.gfdp}</div>
                <div className={style['option-wrapper']}>
                    <Link to={`${thisUrl}/form/recurring`}>
                        <div className={style.option}>
                            <div className={style.icon}>{getSVG.onboarding.recurring}</div>
                            <div className={style.description}>
                                <h4>{onboard.recurring.title}</h4>
                                <p>{onboard.recurring.subtitle}</p>
                            </div>
                            <div className={style.arrow}>{getSVG.onboarding.arrow}</div>
                        </div>
                    </Link>
                    <Link to={`${thisUrl}/form/reminder`}>
                        <div className={style.option}>
                            <div className={style.icon}>{getSVG.onboarding.reminder}</div>
                            <div className={style.description}>
                                <h4>{onboard.reminder.title}</h4>
                                <p>{onboard.reminder.subtitle}</p>
                            </div>
                            <div className={style.arrow}>{getSVG.onboarding.arrow}</div>
                        </div>
                    </Link>
                </div>
                <Link onClick={handleBack} to='/' className={style.navigation}><span>{getSVG.navigation.back}</span>{getText.sidebar.donation.navigation.home}</Link>
            </div>
        </div>
    );
}

export default OnboardingPage;