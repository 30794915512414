import { checkJwt } from "./api-helper";
import { getProfile } from "../../features";
import { accentSVG } from "./accent-helper";

export async function getBadges(lang, signal) {
    const badges = await badgesGamification(lang, signal);
    return badges;
}

async function getPoint(signal) {
    const token = await checkJwt(signal);
    const email = getProfile().email;
    const ref = getProfile().referral_code;

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/donations/point?email=${email}&refCode=${ref}&refType=DONOR`, {
            method: 'GET',
            headers: {
                'Authorization': token
            },
            signal: signal
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();

        return responseData.result.point;
    } catch (error) {
        if (error.name === 'AbortError') {
            return;
        }

        console.error('An error occurred:', error.message);
        // if (process.env.REACT_APP_ENV === 'development') {
        //     console.error('An error occurred:', error.message);
        // } else {
        //     window.location.href = '/oops/500';
        // }
    }
}

async function badgesGamification(lang, signal) {
    const userPoint = await getPoint(signal);
    const getBadge = accentSVG().badges;
    const rules = {
        id: [
            {
                title: 'Pahlawan Baru',
                description: 'Pencapaian ini akan didapatkan oleh user yang telah melengkapi data diri.',
                pointMin: 10,
                pointMax: 99,
                isOpen: userPoint >= 10,
                color: 'yellow',
                accent: getBadge.one.accent,
                display: getBadge.one.display,
                page: getBadge.one.page
            },
            {
                title: 'Pahlawan Hijau',
                description: 'Pencapaian ini akan didapatkan oleh user yang mendapatkan point 100',
                pointMin: 100,
                pointMax: 999,
                isOpen: userPoint >= 100,
                color: 'light-green',
                accent: getBadge.two.accent,
                display: getBadge.two.display,
                page: getBadge.two.page
            },
            {
                title: 'Pahlawan Laut',
                description: 'Pencapaian ini akan didapatkan oleh user yang mendapatkan point 1000',
                pointMin: 1000,
                pointMax: 4999,
                isOpen: userPoint >= 1000,
                color: 'blue',
                accent: getBadge.three.accent,
                display: getBadge.three.display,
                page: getBadge.three.page
            },
            {
                title: 'Pahlawan Hutan',
                description: 'Pencapaian ini akan didapatkan oleh user yang mendapatkan point 5000',
                pointMin: 5000,
                pointMax: 14999,
                isOpen: userPoint >= 5000,
                color: 'dark-green',
                accent: getBadge.four.accent,
                display: getBadge.four.display,
                page: getBadge.four.page
            },
            {
                title: 'Pahlawan Bumi',
                description: 'Pencapaian ini akan didapatkan oleh user yang mendapatkan point 15000',
                pointMin: 15000,
                pointMax: 200000,
                isOpen: userPoint >= 15000,
                color: 'brown',
                accent: getBadge.five.accent,
                display: getBadge.five.display,
                page: getBadge.five.page
            }
        ],
        en: [
            {
                title: 'New Hero',
                description: 'This achievement will be obtained by users who have completed their personal data.',
                pointMin: 10,
                pointMax: 99,
                isOpen: userPoint >= 10,
                color: 'yellow',
                accent: getBadge.one.accent,
                display: getBadge.one.display,
                page: getBadge.one.page
            },
            {
                title: 'Green Hero',
                description: 'This achievement will be obtained by users who have reached 100 points.',
                pointMin: 100,
                pointMax: 999,
                isOpen: userPoint >= 100,
                color: 'light-green',
                accent: getBadge.two.accent,
                display: getBadge.two.display,
                page: getBadge.two.page
            },
            {
                title: 'Sea Hero',
                description: 'This achievement will be obtained by users who have reached 1000 points.',
                pointMin: 1000,
                pointMax: 4999,
                isOpen: userPoint >= 1000,
                color: 'blue',
                accent: getBadge.three.accent,
                display: getBadge.three.display,
                page: getBadge.three.page
            },
            {
                title: 'Forest Hero',
                description: 'This achievement will be obtained by users who have reached 5000 points.',
                pointMin: 5000,
                pointMax: 14999,
                isOpen: userPoint >= 5000,
                color: 'dark-green',
                accent: getBadge.four.accent,
                display: getBadge.four.display,
                page: getBadge.four.page
            },
            {
                title: 'Earth Hero',
                description: 'This achievement will be obtained by users who have reached 15000 points.',
                pointMin: 15000,
                pointMax: 200000,
                isOpen: userPoint >= 15000,
                color: 'brown',
                accent: getBadge.five.accent,
                display: getBadge.five.display,
                page: getBadge.five.page
            }
        ]
    };

    const badgeRules = rules[lang];

    let badgesCollected = 0;
    let currentBadges = [];

    for (let i = 0; i < badgeRules.length; i++) {
        if (badgeRules[i].isOpen) {
            badgesCollected += 1;
            currentBadges = badgeRules[i];
        }
    }

    let start = 0;
    if (badgesCollected > 3) {
        start = badgesCollected - 2;
    }

    let displayBadges = [];
    let pointMax = 4999;

    for (let i = start; i < badgeRules.length; i++) {
        if (displayBadges.length < 3) {
            displayBadges.push(badgeRules[i]);
            pointMax = badgeRules[i].pointMax;
        }
    }

    let currentPoint = userPoint;
    if (userPoint <= displayBadges[0].pointMin) {
        let pointPercent = pointMax * 0.1;
        currentPoint = (userPoint * pointPercent) / displayBadges[0].pointMin;
    } else if (userPoint <= displayBadges[1].pointMin) {
        let checkPoint = pointMax * 0.1;
        let pointPercent = pointMax * 0.5;
        let range1 = displayBadges[1].pointMin - displayBadges[0].pointMin;
        let range2 = pointPercent - checkPoint;
        currentPoint =
            checkPoint +
            ((userPoint - displayBadges[0].pointMin) * (range2 / range1));
    } else if (userPoint <= displayBadges[2].pointMin) {
        let checkPoint = pointMax * 0.5;
        let pointPercent = pointMax * 0.9;
        let range1 = displayBadges[2].pointMin - displayBadges[1].pointMin;
        let range2 = pointPercent - checkPoint;
        currentPoint =
            checkPoint +
            ((userPoint - displayBadges[1].pointMin) * (range2 / range1));
    } else {
        let checkPoint = pointMax * 0.9;
        let pointPercent = pointMax;
        let range1 = displayBadges[2].pointMax - displayBadges[2].pointMin;
        let range2 = pointPercent - checkPoint;
        currentPoint =
            checkPoint +
            ((userPoint - displayBadges[2].pointMin) * (range2 / range1));
    }

    const progressBar = {
        pointMax: pointMax,
        currentPoint: currentPoint
    };

    const result = {
        badgesCollected: badgesCollected,
        badges: badgeRules,
        displayBadges: displayBadges,
        progressBar: progressBar,
        userPoint: userPoint,
        nextBadges:
            badgesCollected === badgeRules.length ? [] : badgeRules[badgesCollected],
        currentBadges: currentBadges
    };

    return result;
};