import { useState, useEffect } from 'react';
import style from './button.module.scss';

const BackToTopButton = ({ closeMenu }) => {
    const [showBackToTop, setShowBackToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        closeMenu();
    };

    return (
        <div className={`${style['back-to-top']} ${showBackToTop ? style.active : ''}`} onClick={backToTop}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z" fill="#FFFFFF" /></svg>
        </div>
    );
}

export default BackToTopButton;
