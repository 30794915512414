import { visa, mastercard, jcb, mandiri, bri_dd, bni, permata, ovo, dana, shopeepay, linkaja, gopay, qris } from "../../assets/img";
import { checkJwt } from "./api-helper";

export function nominalAvailable(type) {
    let nominal = [
        // {
        //     nominal: 10000,
        //     nominalText: 'Rp 10.000',
        //     gaClass: 'gfdp-nominal-10k'
        // },
        // {
        //     nominal: 50000,
        //     nominalText: 'Rp 50.000',
        //     gaClass: 'gfdp-nominal50k'
        // },
        {
            nominal: 150000,
            nominalText: 'Rp 150.000',
            gaClass: 'gfdp-nominal150k'
        },
        {
            nominal: 200000,
            nominalText: 'Rp 200.000',
            gaClass: 'gfdp-nominal200k'
        },
        {
            nominal: 300000,
            nominalText: 'Rp 300.000',
            gaClass: 'gfdp-nominal300k'
        },
        {
            nominal: 500000,
            nominalText: 'Rp 500.000',
            gaClass: 'gfdp-nominal500k'
        }
    ];

    if (type === "single") {
        nominal = nominal.filter(item => item.nominal !== 150000);
        // nominal = nominal.filter(item => item.nominal !== 10000);
        // nominal.push({ nominal: 300000, nominalText: 'Rp 300.000', gaClass: 'gfdp-nominal300k' });
    }

    nominal.sort((a, b) => a.nominal - b.nominal);

    return nominal;
}

export function equivalency(nominal) {
    const equivalent = [
        {
            nominal: 150000,
            icons: 1,
            monthly: '882 kg',
            single: ''
        },
        {
            nominal: 200000,
            icons: 2,
            monthly: '1176 kg',
            single: '98 kg'
        },
        {
            nominal: 300000,
            icons: 3,
            monthly: '2772 kg',
            single: '231 kg'
        },
        {
            nominal: 500000,
            icons: 5,
            monthly: '4620 kg',
            single: '385 kg'
        }
    ];

    // const selectedObject = equivalent.find(item => item.nominal === nominal);

    // return selectedObject;

    const selectedObject = equivalent.find(item => {
        return nominal >= item.nominal && (equivalent[equivalent.indexOf(item) + 1]?.nominal > nominal || !equivalent[equivalent.indexOf(item) + 1]);
    });

    return selectedObject;
}

export function paymentMethods(mainType, monthlyType) {
    const methods = {
        creditCard: [
            {
                channelCode: 'VISA',
                name: 'Visa',
                currency: 'IDR',
                logo: visa,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'recurring'
            },
            {
                channelCode: 'MASTERCARD',
                name: 'Mastercard',
                currency: 'IDR',
                logo: mastercard,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'recurring'
            },
            {
                channelCode: 'JCB',
                name: 'JCB',
                currency: 'IDR',
                logo: jcb,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'recurring'
            },
        ],
        directDebit: [
            {
                channelCode: 'BRI',
                name: 'BRI',
                currency: 'IDR',
                logo: bri_dd,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'recurring'
            },
            // {
            //     channelCode: 'MANDIRI',
            //     name: 'Mandiri',
            //     currency: 'IDR',
            //     logo: mandiri,
            //     activeAt: ['monthly']
            // },
        ],
        virtualAccount: [
            // {
            //     channelCode: 'BCA',
            //     name: 'BCA',
            //     currency: 'IDR',
            //     logo: bca,
            //     activeAt: ['single', 'boost', 'monthly'],
            //     type: 'reminder'
            // },
            // {
            //     channelCode: 'BRI',
            //     name: 'BRI',
            //     currency: 'IDR',
            //     logo: bri,
            //     activeAt: ['single', 'boost', 'monthly'],
            //     type: 'reminder'
            // },
            {
                channelCode: 'BNI',
                name: 'BNI',
                currency: 'IDR',
                logo: bni,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'reminder'
            },
            {
                channelCode: 'MANDIRI',
                name: 'Mandiri',
                currency: 'IDR',
                logo: mandiri,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'reminder'
            },
            {
                channelCode: 'PERMATA',
                name: 'Permata',
                currency: 'IDR',
                logo: permata,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'reminder'
            }
        ],
        // 'ewallRecurring' => [
        //     [
        //         'channelCode' => 'OVO',
        //         'name' => 'OVO',
        //         'currency' => 'IDR',
        //         'logo' => base_url() . 'public/assets/img/payment/ovo.webp',
        //         'activeAt' => ['recurring']
        //     ],
        //     [
        //         'channelCode' => 'SHOPEEPAY',
        //         'name' => 'SHOPEEPAY',
        //         'currency' => 'IDR',
        //         'logo' => base_url() . 'public/assets/img/payment/shopeepay.webp',
        //         'activeAt' => ['recurring']
        //     ],
        // ],
        // retailOutlet: [
        //     {
        //         'channelCode' => 'ALFAMART',
        //         'name' => 'Alfamart',
        //         'currency' => 'IDR',
        //         'logo' => base_url() . 'public/assets/img/payment/alfamart.webp',
        //         'activeAt' => ['single', 'booster', 'recurring']
        //     },
        //     {
        //         'channelCode' => 'INDOMARET',
        //         'name' => 'Indomaret',
        //         'currency' => 'IDR',
        //         'logo' => base_url() . 'public/assets/img/payment/indomaret.webp',
        //         'activeAt' => ['single', 'booster', 'recurring']
        //     }
        // ],
        eWallet: [
            {
                channelCode: 'OVO',
                name: 'OVO',
                currency: 'IDR',
                logo: ovo,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'recurring'
            },
            {
                channelCode: 'DANA',
                name: 'DANA',
                currency: 'IDR',
                logo: dana,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'reminder'
            },
            {
                channelCode: 'SHOPEEPAY',
                name: 'SHOPEEPAY',
                currency: 'IDR',
                logo: shopeepay,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'recurring'
            },
            {
                channelCode: 'LINKAJA',
                name: 'LinkAja',
                currency: 'IDR',
                logo: linkaja,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'reminder'
            },
            {
                channelCode: 'GOPAY',
                name: 'GOPAY',
                currency: 'IDR',
                logo: gopay,
                activeAt: ['single', 'boost'],
                type: 'reminder'
            }
        ],
        others: [
            {
                channelCode: 'QRIS',
                name: 'QR Code',
                currency: 'IDR',
                logo: qris,
                activeAt: ['single', 'boost', 'monthly'],
                type: 'reminder'
            }
        ]
    };

    const filteredMethods = Object.keys(methods).reduce((filtered, category) => {
        const mainTypeFilter = method => method.activeAt.includes(mainType);
        const monthlyTypeFilter = method => (monthlyType === null) || ((monthlyType === 'reminder' && method.type === 'reminder') || (monthlyType === 'recurring' && method.type === 'recurring'));

        filtered[category] = methods[category].filter(method => mainTypeFilter(method) && monthlyTypeFilter(method));
        return filtered;
    }, {});

    return filteredMethods;
}

export async function donationProcess(type, form) {
    const token = await checkJwt();
    const donationData = new FormData();

    donationData.append('amount', form.amount);
    donationData.append('email', form.email);
    donationData.append('fullname', form.fullname);
    donationData.append('payment_methods', form.payment_methods);
    donationData.append('phone', form.phone);
    donationData.append('referral', form.referral);
    donationData.append('description', `Donasi ${form.amount} - ${type}`);

    if (type === 'monthly') {
        donationData.append('interval', 'MONTH');
        donationData.append('interval_count', 1);
        if (form.referral.length > 0) {
            const ref = await checkReferral(form.referral);
            if (ref !== null) {
                donationData.append('referral_code', ref.referral_code);
                donationData.append('referral_type', ref.referral_type);
            }
        }
    }

    let donation_type = type;
    if (type === 'monthly') {
        donation_type = 'recurring';
    }

    const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/payments/${donation_type}`, {
        method: 'POST',
        headers: {
            'Authorization': token
        },
        body: donationData
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    const result = {
        user: data.customer.surname,
        donation: type
    };
    localStorage.setItem('user-donation', JSON.stringify(result));

    window.location.href = data.invoice_url;
    return response.ok;
}

async function checkReferral(code) {
    const token = await checkJwt();

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/referral?search=${code}&searchBy=referral_code`, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.result;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export async function stopDonation(id, channel, signal) {
    const token = await checkJwt(signal);
    let translatedChannel = 'XENDIT';

    switch (channel) {
        case 'x':
            translatedChannel = 'XENDIT';
            break;
        case 'm':
            translatedChannel = 'MIDTRANS';
            break;
        default:
            translatedChannel = 'XENDIT';
            break;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/payments/recurring`, {
            method: 'PUT',
            headers: {
                'Authorization': token,
            },
            body: JSON.stringify({
                pgid: decodeURIComponent(id),
                channel: translatedChannel,
            })
        });

        if (response.status === 404) {
            return 'empty';
        } else if (response.ok) {
            return 'success';
        } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}