import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary, Check, Input } from '..';
import { accentSVG, contentTranslate, downloadReport } from '../../../../services';
import Modal from 'react-bootstrap/Modal';
import style from './modal.module.scss';

const ReportModal = (props) => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        lang: lang
    });
    const [isNameValid, setIsNameValid] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [isPhoneValid, setIsPhoneValid] = useState(null);

    const [invalidEmailMsg, setInvalidEmailMsg] = useState('');
    const [invalidNameMsg, setInvalidNameMsg] = useState('');
    const [invalidPhoneMsg, setInvalidPhoneMsg] = useState('');

    const [isAgreeChecked, setIsAgreeChecked] = useState(null);
    const [uncheckedMsg, setUncheckedMsg] = useState('');
    const [check, setCheck] = useState(false);

    const getText = contentTranslate(lang);
    const text = getText.report.form;
    const getSVG = accentSVG();

    const [showToast, setShowToast] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        }
    }, []);

    const handleFullname = (event) => {
        const fullname = event.target.value;
        setForm({ ...form, name: fullname });
        if (fullname === '') {
            setIsNameValid(false);
            setInvalidNameMsg(text.invalid.name);
            return;
        }
        setIsNameValid(true);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmail = (event) => {
        const email = event.target.value;
        setForm({ ...form, email: email });
        if (email === '') {
            setIsEmailValid(false);
            setInvalidEmailMsg(text.invalid.emptyEmail);
            return;
        }
        if (validateEmail(email)) {
            setIsEmailValid(true);
            return;
        }
        setIsEmailValid(false);
        setInvalidEmailMsg(text.invalid.invalidEmail);
    };

    const handlePhone = (event) => {
        const phone = event.target.value;
        setForm({ ...form, phone: phone });
        if (phone === '') {
            setIsPhoneValid(false);
            setInvalidPhoneMsg(text.invalid.phone);
            return;
        }
        setIsPhoneValid(true);
    };

    const handleCheck = () => {
        setCheck(!check);
        const agree = !check;
        if (agree) {
            setIsAgreeChecked(true);
            return;
        }
        setIsAgreeChecked(false);
        setUncheckedMsg(text.invalid.agree);
    };

    const resetForm = () => {
        setForm({
            name: '',
            email: '',
            phone: '',
        });
        setCheck(false);
        setIsAgreeChecked(null);
        setIsNameValid(null);
        setIsEmailValid(null);
        setIsPhoneValid(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isNameValid && isEmailValid && isPhoneValid) {
            if (!isAgreeChecked) {
                if (isAgreeChecked === null) {
                    setIsAgreeChecked(false);
                    setUncheckedMsg(text.invalid.agree);
                }
                return;
            }
            setIsLoading(true);
            try {
                const success = await downloadReport(form);
                if (success) {
                    // getReportPDF(lang);
                    resetForm();
                    // props.onHide(true);
                    setIsSuccess(true);
                } else {
                    setIsSuccess(false);
                }
                callToast();
                setTimeout(() => {
                    props.onHide();
                }, 3500);
            } catch (error) {
                navigate('/oops/500', { replace: true });
            } finally {
                setIsLoading(false);
            }
            return;
        }
        if (isNameValid === null) {
            setIsNameValid(false);
            setInvalidNameMsg(text.invalid.name)
        }
        if (isEmailValid === null) {
            setIsEmailValid(false);
            setInvalidEmailMsg(text.invalid.emptyEmail);
        }
        if (isPhoneValid === null) {
            setIsPhoneValid(false);
            setInvalidPhoneMsg(text.invalid.phone);
        }
    };

    const callToast = () => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    return (
        <Modal
            {...props}
            size="lg"
            dialogClassName={style['report-dialog']}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={`${style.modal} ${style.report}`}>
                <div className={`${style.toast} ${showToast ? style.show : ''} ${isSuccess ? style.success : style.error}`}>
                    <div className={style['toast-icon']}>{isSuccess ? getSVG.dashboard.success : getSVG.dashboard.error}</div>
                    <div className={`${style.message} `}>
                        <h6>{isSuccess ? getText.dashboard.profile.toast.success : getText.dashboard.profile.toast.error}</h6>
                        <p>{isSuccess ? getText.report.toast.success : getText.report.toast.failed}</p>
                    </div>
                </div>

                <Modal.Header className={style.header}>
                    <Modal.Title id="contained-modal-title-vcenter" className={style.title}>
                        GFDP Impact Report 2022 - 2023
                    </Modal.Title>
                    <p>{text.subtitle}</p>
                </Modal.Header>
                <Modal.Body className={style.body}>
                    <form className={style['report-form']} onSubmit={handleSubmit} noValidate>
                        <div className={style['input-group']}>
                            <label className="form-label" htmlFor="fullname">{text.label.name}</label>
                            <Input
                                id='fullname'
                                name='name'
                                type='text'
                                value={form.name}
                                className={`form-control custom-input ${isNameValid === false ? 'is-invalid' : ''}`}
                                placeholder={text.placeholder.name}
                                onChange={handleFullname}
                            />
                            <span className="invalid-feedback">{invalidNameMsg}</span>
                        </div>
                        <div className={style['input-group']}>
                            <label className="form-label" htmlFor="phone">{text.label.phone}</label>
                            <div className="input-group">
                                <span className={`input-group-text ${style.custom}`}>+62</span>
                                <Input type="number" id="phone" name="phone" value={form.phone} className={`form-control ${style['input-other']} ${isPhoneValid === false ? 'is-invalid' : ''}`} placeholder={text.placeholder.phone} onChange={handlePhone} />
                                <span className="invalid-feedback">{invalidPhoneMsg}</span>
                            </div>
                        </div>
                        <div className={style['input-group']}>
                            <label className="form-label" htmlFor="email">{text.label.email}</label>
                            <Input type="email" id="email" name="email" value={form.email} className={`form-control ${isEmailValid === false ? 'is-invalid' : ''}`} placeholder={text.placeholder.email} onChange={handleEmail} />
                            <span className="invalid-feedback">{invalidEmailMsg}</span>
                        </div>

                        <div className={`form-check ${style.agree}`}>
                            <Check id="agree" name="agree" type="checkbox" className={`form-check-input ${style.checkInput} ${isAgreeChecked === false ? 'is-invalid' : ''}`} onChange={handleCheck} checked={check} />
                            <label className="form-check-label" htmlFor="agree">
                                {text.check}
                            </label>
                            <span className="invalid-feedback">{uncheckedMsg}</span>
                        </div>

                        <ButtonPrimary type="submit" className={`${isLoading ? 'temp-disabled' : ''}`}>
                            {isLoading ? (
                                <div className={style.load}>
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                getText.report.button
                            )}
                        </ButtonPrimary>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default ReportModal;