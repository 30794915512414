import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { contentTranslate, nominalAvailable } from '../../../../services';
import { updateRecurring } from '../../../users';
import { ButtonGrey, ButtonPrimary } from '..';
import Modal from 'react-bootstrap/Modal';
import style from './modal.module.scss';


const RecurringModal = (props) => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const text = getText.dashboard.modal.recurring;
    const [nominals, setNominals] = useState([]);
    const [otherAmount, setOtherAmount] = useState(0);
    const [selectedNominal, setSelectedNominal] = useState(0);
    const [otherPass, setOtherPass] = useState('other');
    const [other, setOther] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAmountValid, setIsAmountValid] = useState(null);

    const parsing = useCallback(() => {
        setNominals(nominalAvailable('monthly'));
        if (otherAmount === 0) {
            setOtherPass('other');
        }
    }, [otherAmount]);

    useEffect(() => {
        parsing();

        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        }
    }, [parsing]);

    const validateAmount = (amount) => {
        return amount >= 10000;
    };

    const handleNominal = (event) => {
        const amount = event.target.value;
        if (amount === 'other') {
            setOther(true);
            setSelectedNominal(0);
        } else {
            setOther(false);
            setSelectedNominal(parseInt(amount));
        }
        // setOtherAmount('other');
        setIsAmountValid(true);
        setOtherAmount(selectedNominal);
    };

    const handleInputOther = (event) => {
        const amountOther = event.target.value;
        setOtherAmount(amountOther);
        setIsAmountValid(validateAmount(amountOther));
        setOther(true);
    };

    const getAmount = () => {
        const value = other ? (isAmountValid ? otherAmount.toString() : null) : selectedNominal.toString();
        return value;
    };

    const dispatchEvent = () => {
        const amountChangeEvent = new Event('amount-change');
        window.dispatchEvent(amountChangeEvent);
    };

    const handleCancel = () => {
        setSelectedNominal(0);
        setOtherAmount(0);
        setIsAmountValid(null);
        setOther(false);
        props.onHide();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const amount = getAmount();
        if (amount === '0' || amount === null) {
            return;
        }
        setIsAmountValid(null);
        setIsLoading(true);
        try {
            const success = await updateRecurring(amount);
            if (success) {
                dispatchEvent();
                props.onHide(true);
                return;
            }
            props.onHide(true);
        } catch (error) {
            navigate('/oops/500', { replace: true });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={`${style.modal} ${style.recurring}`}>
                <Modal.Header className={style.header} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {text.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={style.body}>
                    <p>{text.subtitle}</p>

                    <div className={style.option}>
                        <div className={style['nominal-wrapper']}>
                            {nominals.map(item => (
                                <div className={`
                                    ${style['btn-monthly']}
                                    ${selectedNominal === item.nominal ? style.checked : ''} 
                                    ${style['nominal-button-wrapper']}`}
                                    key={item.nominal}>
                                    <input
                                        type="radio"
                                        className={`${style.nominal} ${item.gaClass}`}
                                        name="amount_option"
                                        id={`donate${item.nominal}`}
                                        value={item.nominal}
                                        autoComplete="off"
                                        checked={selectedNominal === item.nominal}
                                        onChange={handleNominal}
                                    />
                                    <label className={style['button-nominal']} htmlFor={`donate${item.nominal}`}>{item.nominalText}</label>
                                </div>
                            ))}

                            <div className={`
                                ${style['btn-monthly']} 
                                ${other === true ? style.checked : ''} 
                                ${style['nominal-button-wrapper']}`}>
                                <input
                                    type="radio"
                                    className={`${style.nominal}`}
                                    name="amount_option"
                                    id="donateOther"
                                    value={otherPass}
                                    autoComplete="off"
                                    checked={other === true}
                                    onChange={handleNominal}
                                />
                                <label className={style['button-nominal']} htmlFor="donateOther">{getText.donationCard.other}</label>
                            </div>
                        </div>

                        {other && (
                            <div className={style['other-amount']}>
                                <label htmlFor="final-amount">{getText.donationCard.other}</label>
                                <div className={`input-group ${style['input-group']}`}>
                                    <span className={`input-group-text ${style.custom}`}>Rp.</span>
                                    <input type="number" min="10000" pattern="[0-9]+" className={`form-control gfdp-nominal-jumlah_lainnya ${style['input-other']} ${isAmountValid === false ? 'is-invalid' : ''}`} name="other_amount" id="final-amount" value={otherAmount} onChange={handleInputOther} />
                                    <span className="invalid-feedback">{text.invalid}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className={style.footer}>
                    <ButtonGrey onClick={handleCancel}>{text.cancel}</ButtonGrey>
                    <ButtonPrimary onClick={handleSubmit} className={`${isLoading ? 'temp-disabled' : ''}`}>
                        {isLoading ? (
                            <div className={style.load}>
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            text.update
                        )}
                    </ButtonPrimary>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default RecurringModal;