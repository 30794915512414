import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header, Footer, MetaHelmet } from '../../components';
import { metaSEO } from '../../../../services';

const Base = () => {
    const location = useLocation();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getMeta = metaSEO(lang);
    const path = location.pathname;
    const meta = getMeta.find(item => path === item.url) || getMeta[0];

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        };
    }, []);

    return (
        <>
            <MetaHelmet
                url={meta.url}
                title={meta.title}
                description={meta.description}
                keywords={meta.keywords}
                image={meta.image}
            />
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export default Base;