import { useState, useEffect } from 'react';
import { accentSVG, contentTranslate, useAuth } from '../../../../services';
import { getProfile } from '../../../users';
import Modal from 'react-bootstrap/Modal';
import style from './modal.module.scss';

const ShareModal = (props) => {
    const { auth } = useAuth();
    const user = auth ? getProfile() : null;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const text = getText.dashboard.modal.social;
    const getSVG = accentSVG();
    const link = `${baseUrl}/donation/monthly?ref=${user ? user.referral_code : ''}`;

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        }
    }, []);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={`${style.modal} ${style.share}`}>
                <Modal.Header className={style.header} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {text.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={style.body}>
                    <p>{text.subtitle}</p>

                    <div className={style.social}>
                        <a href={`https://twitter.com/intent/tweet?text=${link}`} target='_blank' rel='noreferrer' className={style.media}>{getSVG.donor.twitter}</a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} target='_blank' rel='noreferrer' className={style.media}>{getSVG.donor.facebook}</a>
                        <a href={`whatsapp://send?text=${link}`} target='_blank' rel='noreferrer' className={style.media}>{getSVG.donor.whatsapp}</a>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default ShareModal;