import heroVolunteer from '../../assets/img/headervolunteer.webp';
import sectionVolunteer from '../../assets/img/volunteer2.webp';
import listVolunteer from '../../assets/img/list.webp';
import heroReport from '../../assets/img/headerreport.webp';

export function contentTranslate(lang) {
    const translate = {
        id: {
            header: {
                donation: "Donasi Sekarang",
                login: "Masuk",
                dashboard: "Dasbor",
            },
            footer: "Greeneration Foundation (GF) adalah yayasan lingkungan yang berfokus pada isu konsumsi dan produksi berkelanjutan di Indonesia. GF berdiri pada 2014 dengan nama Yayasan Greeneration Indonesia dan diresmikan oleh Keputusan Menteri Hukum dan HAM No. AHU-0000260.AH.01.05 Tahun 2021",
            login: {
                title: "Selamat datang kembali Generasi Hijau!",
                subtitle: "Sempurnakan niat baikmu dengan donasi untuk lingkungan Indonesia!",
                p1: "Kamu belum memiliki akun?",
                p2: "Yuk",
                onboard: "Donasi Bulanan Sekarang",
                form: {
                    emailLB: "Alamat email",
                    emailPH: "Masukan email kamu",
                    passPH: "Masukan password kamu",
                    checkLB: "Ingat Saya",
                    forgot: "Lupa Password?",
                    button: "Masuk",
                    error: "Email atau Password kamu salah. Jika kamu belum memiliki akun yuk",
                    emptyEmail: "Email tidak boleh kosong.",
                    invalidEmail: "Masukkan email dengan format yang benar.",
                    emptyPass: "Password tidak boleh kosong."
                },
            },
            forgot: {
                title: "Silahkan masukkan email kamu",
                p1: "Kamu belum memiliki akun?",
                p2: "Yuk",
                onboard: "Donasi Bulanan Sekarang",
                form: {
                    emailLB: "Alamat email",
                    emailPH: "Masukan email kamu",
                    button: "Kirim",
                },
                toast: {
                    success: "Link reset password telah kami kirim.",
                    failed: "Email tidak terdaftar."
                },
                back: "Kembali"
            },
            reset: {
                title: "Silahkan masukkan password baru",
                p1: "Kamu belum memiliki akun?",
                p2: "Yuk",
                onboard: "Donasi Bulanan Sekarang",
                form: {
                    label: {
                        password: "Password Baru",
                        confirm: "Konfirmasi Password"
                    },
                    placeholder: {
                        password: "Masukkan password baru",
                        confirm: "Konfirmasi password baru"
                    },
                    button: "Kirim"
                },
                back: "Kembali"
            },
            donationCard: {
                monthly: "Bulanan",
                single: "Satu Kali",
                subtitle: "Jaga terus masa depan Bumi, mulai dari Rp. 10.000 per bulan.",
                other: "Jumlah Lain",
                note1: "Kamu akan berdonasi sebesar Rp.",
                note2: "per bulan. Ayo jadi bagian restorasi lingkungan dengan berdonasi setiap bulan.",
                terms: "Saya menyetujui",
                termsLink: "Kebijakan Privasi dan Syarat Ketentuan",
                button: "Donasi Sekarang",
                help: "Bantuan",
                agree: "Cek untuk melanjutkan.",
                amount10: "Nominal harus lebih besar atau sama dengan 10.000",
                amount50: "Nominal harus lebih besar atau sama dengan 50.000",
                invalid: "Nominal harus dipilih atau diisi.",
                equivalency: {
                    manage: "Kelola",
                    wasteMonthly: "sampah organik",
                    wasteSingle: "sampah anorganik",
                    monthly: "tahun",
                    single: "bulan",
                    descMonthly1: "Setara kapasitas sampah",
                    descSingle1: "Setara",
                    descMonthly2: "triseda",
                    descSingle2: "karung sampah"
                }
            },
            landing: {
                tripleten: {
                    tahun: {
                        title: "10 Tahun",
                        subtitle: "Paling Kritis Untuk Lingkungan",
                    },
                    juta: {
                        title: "10 Juta",
                        subtitle: "Dukungan Masyarakat untuk Perubahan",
                    },
                    ribu: {
                        title: "10 Ribu",
                        subtitle: "per Bulan Untuk Pembiayaan Restorasi Lingkungan",
                    },
                    description: "Kita mulai kehabisan waktu! Berbagai aksi restorasi lingkungan telah dilakukan. Namun, laju perubahan iklim terlalu cepat untuk diimbangi. Ini dekade penentu. Apa yang bisa kita lakukan selama 10 tahun ini? Rangkul orang-orang di sekitarmu! Dibutuhkan bantuan 10 juta orang yang ingin mulai beraksi dengan langkah sederhana lewat Rp. 10.000 yang didonasikan untuk menjalankan aksi lingkungan."
                },
                campaigns: {
                    title: "Ini yang bisa kamu lakukan untuk Bumi!",
                    subtitle: "Rp. 10.000 dari kamu ternyata bisa membantu 4 gerakan ini yang telah berdampak nyata pada upaya restorasi lingkungan."
                },
                testiImpact: {
                    title: "Terima Kasih & Selamat! Kamu telah Pulihkan Bumi",
                    subtitle: "Penasaran bagaimana donasi kamu berdampak pada lingkungan dan kesejahteraan pejuangnya? Ini kata mereka."
                },
                steps: {
                    title: "Langkah Donasi",
                    content: [
                        {
                            title: "Masuk Ke Laman Donasi",
                            subtitle: "Pada laman donasi, kamu akan disajikan 2 pilihan donasi yaitu Donasi Bulanan dan Donasi Sekali. Cari tahu juga dampak kampanye kami untuk meyakinkanmu."
                        },
                        {
                            title: "Pilih Donasi Bulanan",
                            subtitle: "Donasi bulanan memudahkan kamu karena menggunakan sistem auto-debet. Pilih nominal yang ingin kamu donasikan. Setujui kebijakan privasi. Klik Donasi sekarang."
                        },
                        {
                            title: "Lengkapi Data Diri",
                            subtitle: "Isi seluruh form data diri dengan lengkap. Pastikan data yang kamu isi sesuai."
                        },
                        {
                            title: "Pilih Metode Pembayaran",
                            subtitle: "Pilih metode pembayaran yang kamu sukai. Metode yang tersedia antara lain: kartu kredit atau debit, e-wallet, virtual account, serta QRIS."
                        },
                        {
                            title: "Klik Donasi sekarang",
                            subtitle: "Setelah kamu memastikan metode pembayaran yang kamu pilih telah sesuai, klik Donasi Sekarang!"
                        },
                        {
                            title: "Lakukan Pembayaran",
                            subtitle: "Selanjutnya, kamu akan masuk ke laman pembayaran. Lakukan pembayaran sesuai metode yang kamu pilih."
                        },
                        {
                            title: "Selamat Donasi Berhasil",
                            subtitle: "Jika kamu telah berhasil donasi, kamu akan diarahkan ke laman konfirmasi pembayaran donasi berhasil. Cek e-mail dan WhatsApp juga!"
                        }
                    ]
                },
                benefits: {
                    title: "Kenapa Harus Donasi Bulanan?",
                    subtitle: "Bumi butuh aksi yang ambisius! Berikan dampak yang lebih nyata dengan donasi bulanan!",
                    content_a: "Dengan donasi bulanan, kamu bisa nikmati fitur lengkap donatur Green Fund Digital Philanthropy!",
                    content_b: "Bumi butuh aksi yang ambisius! Berikan dampak yang lebih nyata dengan donasi bulanan!"
                },
                testiDonor: {
                    title: "Apa Kata Mereka?",
                    subtitle: "Kamu ragu untuk berdonasi? Cek dulu pengalaman Pahlawan Lingkungan yang sudah berkontribusi lewat GFDP!"
                },
                mitra: {
                    title: "Mitra dan Inisiator Awal"
                },
                faq: {
                    title: "Pertanyaan Umum (FAQ)",
                    content: [
                        {
                            question: 'Apakah GFDP sudah terlegalisasi untuk menggalang dana?',
                            answer: <p>GFDP sudah memiliki izin Penyelenggaraan Pengumpulan Uang dan Barang (PUB) dari Kementerian Sosial dengan nomor SK 270/HUK-PS/2022</p>
                        },
                        {
                            question: 'Bagaimana alokasi uang yang didonasikan ke GFDP?',
                            answer: <p>Sesuai peraturan pemerintah, kami mengalokasikan 10% untuk operasional dan sisanya untuk kebutuhan pelaksanaan program.</p>
                        },
                        {
                            question: 'Apakah saya bisa menerima laporan donasi?',
                            answer: <p>Kamu akan menerima laporan progress dampak, yang kami kirimkan melalui newsletter bulanan yang dialamatkan ke email yang kamu daftarkan saat berdonasi. Di luar itu, kamu juga bisa mengakses laporan keuangan teraudit yang kami publikasikan secara terbuka di website kami.</p>
                        },
                        {
                            question: 'Bagaimana proses akuntabilitas GFDP?',
                            answer: <p>Kami memiliki:<br />
                                1. Laporan dampak setiap program (khususnya untuk diberikan ke lembaga pemberi dana/sponsor) dan ke publik melalui website organisasi<br />
                                2. Laporan audit keuangan organisasi yang diaudit oleh pihak auditor eksternal secara profesional</p>
                        },
                        {
                            question: 'Bagaimana jika saya menghadapi kesulitan saat donasi?',
                            answer: <p>Mohon maaf atas kesulitan yang kamu alami. Kamu bisa langsung menghubungi tim kami di nomor WhatsApp <a href="https://wa.me/6285173310223">085173310223</a> dan email <a href="mailto:donorcare@greeneration.org">donorcare@greeneration.org</a></p>
                        },
                        {
                            question: 'Bagaimana jika saya masih memiliki pertanyaan lain?',
                            answer: <p>Untuk pertanyaan terkait donasi, silakan hubungi tim kami di nomor WhatsApp <a href="https://wa.me/6285173310223">085173310223</a> dan email <a href="mailto:donorcare@greeneration.org">donorcare@greeneration.org</a>. Untuk pertanyaan lainnya, silakan hubungi <a href="mailto:info@greeneration.org">info@greeneration.org</a></p>
                        }
                    ]
                },
                contacts: {
                    title: "Hubungi Kami",
                    subtitle: "Terima kasih atas ketertarikanmu akan GFDP. Jika ada informasi lebih lanjut yang ingin kamu ketahui, silahkan hubungi kami di:"
                }
            },
            campaign: {
                title: "Donasi Anda Akan Membantu:",
                desc1: "Di luar itu, hasil penggalangan dana dari program ini juga akan dialokasikan untuk program lainnya yang bertujuan untuk membantu gerakan lingkungan serta membantu kesejahteraan para pahlawan lingkungan. Untuk informasi lebih lanjut, harap hubungi Email",
                desc2: "atau WhatsApp"
            },
            volunteer: {
                title: "Jadi Fundraiser Buddies",
                subtitle: "Perluas manfaat kontribusimu dengan jadi Fundraiser Buddies, dan dapatkan insentif menarik.",
                backgroundImage: heroVolunteer,
                sectionTitle: "Apa Itu Fundraiser Buddies? 🤔",
                sectionDescription: "Spirit Generasi Hijau yang selalu ingin berkontribusi lebih di GFDP selalu menginspirasi kami. Fundraiser Buddies hadir untuk mengakomodasi spirit tersebut, sebagai program relawan penggalangan dana individu di setiap kota/kabupaten di Indonesia. Dengan semangat gotong royong, Buddies akan meneruskan inisiatif dan nilai Green Fund Digital Philanthropy, mengajak masyarakat terdekat mereka untuk menggabungkan kekuatan Rp10.000 per bulan dalam menciptakan solusi lingkungan berkelanjutan.",
                sectionImage: sectionVolunteer,
                listTitle: "Manfaat yang kamu berikan, akan berbalik ke dirimu sendiri. Green Fund Digital Philanthropy mengapresiasi kontribusimu dengan memberikan:",
                list: [
                    "Pelatihan dari tim profesional",
                    "Kesempatan terlibat langsung dalam berbagai program Greeneration Foundation",
                    "Kesempatan mendapat insentif relawan tiap bulan",
                    "Pengalaman dan relasi untuk dimasukkan dalam portfolio dan CV",
                    "Kesempatan pengembangan karir"
                ],
                listImage: listVolunteer,
                listFooter: "Jadi bagian dalam sejarah untuk memberi kebaikan untuk lingkungan. Gabung Fundraiser Buddies, sekarang!",
                button: "Bergabung Sekarang",
                form: {
                    title: "Registrasi Akun Relawan",
                    sectionTitle_1: "Akun Relawan",
                    sectionTitle_2: "Data Diri",
                    sectionTitle_3: "Media Sosial",
                    label: {
                        firstname: "Nama Depan",
                        lastname: "Nama Belakang",
                        email: "Alamat Email",
                        phone: "Nomor Telepon",
                        sex: "Jenis Kelamin",
                        birth: "Tanggal Lahir",
                        province: "Provinsi",
                        city: "Kota",
                        occupation: "Pekerjaan",
                        institution: "Institusi",
                        hobby: "Hobi",
                        source: "Darimana anda tahu tentang ini?",
                        motivation: "Tulis motivasi kamu di sini",
                        confirm: "Dengan ini saya menyetujui akan mengikuti pelatihan sebagai Fundraiser Buddies selama kurang lebih 3 bulan",
                        instagram: "Instagram"
                    },
                    placeholder: {
                        firstname: "Masukan nama depan",
                        lastname: "Masukan nama belakang",
                        email: "Masukan alamat email",
                        phone: "Masukan nomor telepon",
                        sex: "Pilih jenis kelamin",
                        birth: "Pilih tanggal lahir",
                        province: "Masukan provinsi",
                        city: "Masukan kota",
                        occupation: "Masukan pekerjaan",
                        institution: "Masukan institusi",
                        hobby: "Masukan hobi",
                        source: "Masukan afiliasi",
                        motivation: "Masukan motivasi",
                        instagram: "Masukan instagram",
                        f: "Perempuan",
                        m: "Laki-laki"
                    },
                    note: "*) Nomor yang terhubung dengan Whatsapp",
                    button: {
                        signup: "Daftar",
                        cancel: "Kembali"
                    },
                    invalid: {
                        firstname: "Nama depan tidak boleh kosong.",
                        lastname: "Nama belakang tidak boleh kosong.",
                        emptyEmail: "Email tidak boleh kosong.",
                        invalidEmail: "Masukkan email dengan format yang benar.",
                        phone: "Nomor telepon tidak boleh kosong.",
                        sex: "Jenis kelamin harus dipilih.",
                        agree: "Cek untuk melanjutkan."
                    }
                }
            },
            sidebar: {
                donation: {
                    navigation: {
                        home: "Kembali ke Halaman Utama",
                        onboarding: "Kembali ke Onboarding",
                        dashboard: "Kembali ke Dasbor",
                        monthly: "Donasi Bulanan Sekarang"
                    },
                    monthly: {
                        title: "Selamat datang, Pahlawan!",
                        subtitle: "Kamu sedang menciptakan dampak dengan melakukan donasi bulanan. Jangan lupa untuk mengecek dasbor dari waktu ke waktu untuk memantau kemajuanmu."
                    },
                    single: {
                        title: "Yakin, Sekali Aja?",
                        subtitle: "Jangan salah, bersama 10 juta masyarakat Indonesia lainnya, setiap rupiah darimu sungguhlah berarti. Tapi, coba tengok Donasi Bulanan untuk memaksimalkan kontribusimu, agar kamu bisa donasi setiap bulan secara rutin hanya dalam satu kali klik. No lupa lupa club!",
                        button: "Donasi Bulanan Sekarang"
                    },
                    boost: {
                        title: "Wah, Poinmu Nambah!",
                        subtitle: "Dengan menaikkan nominal donasimu, kamu juga akan mendapatkan poin tambahan. Lebih fun untukmu, lebih bermanfaat juga untuk bumi!"
                    },
                    recurring: "Nominal Donasi Bulanan Kamu"
                },
                dashboard: {
                    profile: "Profil",
                    share: "Bagikan Sekarang"
                }
            },
            onboarding: {
                recurring: {
                    title: "Sistem Recurring",
                    subtitle: "Sistem recurring memudahkan kamu berdonasi dengan penarikan otomatis setiap bulannya."
                },
                reminder: {
                    title: "Sistem Pengingat",
                    subtitle: "Kami akan mengingatkan kamu berdonasi tiap bulan melalui notifikasi email dan Whatsapp."
                }
            },
            donation: {
                one: {
                    title: "Pilih Nominal Donasi",
                    amount: "Jumlah Lainnya",
                    note_1: "Kamu akan berdonasi senilai Rp",
                    note_2: ",- untuk menyelesaikan masalah lingkungan Indonesia. Yuk isi data diri kamu sebelum berdonasi!"
                },
                two: {
                    title: "Informasi Data Diri",
                    label: {
                        name: "Nama Lengkap",
                        phone: "Nomor Telepon",
                        email: "Alamat Email"
                    },
                    placeholder: {
                        name: "Masukan Nama Lengkapmu",
                        phone: "Masukan Nomor Telepon Kamu",
                        email: "Masukan Alamat Email Kamu"
                    },
                    invalid: {
                        fullname: "Nama lengkap tidak boleh kosong.",
                        emptyEmail: "Email tidak boleh kosong.",
                        invalidEmail: "Masukkan email dengan format yang benar.",
                        phone: "Nomor telepon tidak boleh kosong.",
                        payment: "Pembayaran harus dipilih."
                    }
                },
                three: {
                    title: "Metode Pembayaran"
                },
                four: {
                    title: "Kode Referral",
                    note: "Opsional",
                    placeholder: "Masukan Kode Referral Kamu"
                },
                button: "Donasi Sekarang"
            },
            dashboard: {
                title: "Hai, ",
                logout: "Keluar",
                modal: {
                    recurring: {
                        title: "Ganti Nominal Donasi Bulanan",
                        subtitle: "Sesuaikan kontribusimu dengan kebutuhanmu. Setiap rupiah tetap berarti 😊",
                        cancel: "Batal",
                        update: "Ubah",
                        invalid: "Nominal harus lebih besar atau sama dengan 10.000"
                    },
                    referral: {
                        title: "Ubah Referral Code",
                        subtitle: "Ubah referral code agar lebih personal dan gampang diingat!",
                        warning: "Kamu hanya bisa mengganti referral code 1 kali.",
                        cancel: "Kembali",
                        confirm: "Konfirmasi"
                    },
                    social: {
                        title: "Bagikan ke Media Sosial Kamu",
                        subtitle: "Karena setiap kebaikan harus disebarkan 💙"
                    },
                    password: {
                        title: "Perbarui password kamu",
                        placeholder: "Masukan password kamu",
                        confirm: "Konfirmasi",
                        cancel: "Kembali"
                    },
                    badge: {
                        title: "Hore! Kamu dapat lencana baru!",
                        subtitle: "Kebaikanmu perlu diapresiasi. Selamat, kamu sudah menjadi",
                        view: "Lihat Lencana"
                    }
                },
                achievement: {
                    title: "Ini Pencapaianmu",
                    locked: "Kamu belum membuka badge pertama, silahkan memperbarui password kamu untuk mulai mengumpulkan poin.",
                    desc_1: "Kamu sudah mengumpulkan",
                    desc_2: "Kumpulkan",
                    desc_3: "lagi untuk jadi",
                    desc_completed: "Kamu sudah mengumpulkan semua badges yang tersedia saat ini.",
                    badge: "Lencana",
                    point: "Poin",
                    view: "Lihat semua lencana"
                },
                profile: {
                    title: "Ubah Profil",
                    section: {
                        title_1: "Informasi Personal",
                        title_2: "Informasi Pribadi",
                        title_3: "Media Sosial",
                        title_4: "Informasi Lainnya",
                        title_5: "Informasi Bank"
                    },
                    label: {
                        fullname: "Nama Lengkap",
                        firstname: "Nama Depan",
                        lastname: "Nama Belakang",
                        phone: "Nomor Telepon",
                        email: "Alamat Email",
                        address: "Alamat Lengkap",
                        note: "*) Kosongkan jika tidak ingin diubah",
                        password: "Password Baru",
                        confirm: "Konfirmasi Password",
                        bank: "Nama Bank",
                        account: "Nomor Rekening",
                        holder: "Nama Pemegang Akun",
                        branch: "Kantor Cabang Pembuka"
                    },
                    placeholder: {
                        fullname: "Masukan nama lengkap",
                        firstname: "Masukan nama depan",
                        lastname: "Masukan nama belakang",
                        phone: "Masukan nomor telepon",
                        email: "Masukan elamat email",
                        address: "Masukan alamat lengkapmu",
                        password: "Masukan password",
                        confirm: "Masukan konfirmasi password",
                        bank: "Masukan nama bank",
                        account: "Masukan nomor rekening",
                        holder: "Masukan nama pemegang akun",
                        branch: "Masukan kantor cabang pembuka"
                    },
                    button: {
                        edit: "Ubah",
                        cancel: "Batal",
                        save: "Simpan Perubahan"
                    },
                    invalid: {
                        emptyPass: "Password tidak boleh kosong.",
                        unmatchedPass: "Password tidak cocok."
                    },
                    toast: {
                        success: "Sukses!",
                        profileSuccess: "Profil berhasil diubah.",
                        passwordSuccess: "Password berhasil diubah.",
                        bankSuccess: "Informasi bank berhasil diubah.",
                        error: "Oops!",
                        errorMsg: "Kesalahan server internal."
                    }
                },
                donor: {
                    boost: {
                        title: "Tambah point sebelum waktu donasi? Bisa dong!",
                        subtitle: "Kamu bisa boost point kamu dengan cara donasi 1x tanpa menunggu donasi bulan depan.",
                        button: "Boost Sekarang"
                    },
                    share: {
                        title: "Maksimalkan kontribusimu dengan berbagi!",
                        subtitle: "Ajak temanmu untuk ikut donasi. Copy link berikut:",
                        edit: "Ubah Link",
                        copy: "Salin Link",
                        social: "Bagikan ke Media Sosial"
                    }
                }
            },
            success: {
                modal: {
                    title: "Bagikan Kontribusimu",
                    subtitle1: "Karena setiap kebaikan harus disebarkan 💙",
                    subtitle2: "Langsung share di sosmed? Bisa!",
                    copy: "Salin Link"
                }
            },
            report: {
                title: "Jejak Kebaikan Green Fund Digital Philanthropy",
                subtitle: "1 Tahun Kontribusi Untuk Bumi, Apa Saja yang Sudah Kita Raih Bersama?",
                backgroundImage: heroReport,
                LP1: "Krisis lingkungan masih menemani hari-hari kita. Cuaca yang sangat panas, polusi udara, kebakaran, pencemaran sampah, dan banjir selalu menyertai. GFDP tidak ingin kamu, kita, dan seluruh makhluh hidup terus terjebak dalam masalah ini. Selama 1 tahun ini, kami mengajak kamu untuk berjalan bersama pulihkan bumi. Sekarang, saatnya kita rayakan hasil dari kerja keras kita di sini!",
                LP2: "Silakan simpan & unduh GFDP Impact Report 2022-2023 di sini. Abadikan, kenang, dan sebarkan catatan perjalanan kita pada benih-benih pahlawan lingkungan lainnya.",
                button: "Unduh Report",
                form: {
                    subtitle: "Lengkapi data diri berikut untu mengunduh GFDP Impact Report 2022-2023!",
                    label: {
                        name: "Nama Lengkap",
                        phone: "Nomor Telepon",
                        email: "Alamat Email"
                    },
                    placeholder: {
                        name: "Masukan Nama Lengkapmu",
                        phone: "Masukan Nomor Telepon Kamu",
                        email: "Masukan Alamat Email Kamu"
                    },
                    invalid: {
                        name: "Nama lengkap tidak boleh kosong.",
                        emptyEmail: "Email tidak boleh kosong.",
                        invalidEmail: "Masukkan email dengan format yang benar.",
                        phone: "Nomor telepon tidak boleh kosong.",
                        agree: "Cek untuk melanjutkan."
                    },
                    error: "Terjadi kesalahan. Coba lagi.",
                    check: "Dengan mencentang kotak ini, saya setuju untuk menerima newsletter dari GFDP."
                },
                toast: {
                    success: "Link download telah kami kirim ke email kamu.",
                    failed: "Terjadi kesalahan."
                },
            }
        },
        en: {
            header: {
                donation: "Donate Now",
                login: "Login",
                dashboard: "Dashboard",
            },
            footer: "Greeneration Foundation (GF) is an environmental foundation that focuses on sustainable consumption and production issues in Indonesia. GF was established legally in 2014 with name Yayasan Greeneration Indonesia, as authorized by the Indonesia's Minister of Law and Human Rights Decree No. AHU-0000260.AH.01.05/2021",
            login: {
                title: "Welcome back, Green Generation!",
                subtitle: "Fulfill your good intentions with a donation for the environment of Indonesia!",
                p1: "Don't have an account yet?",
                p2: "Let's",
                onboard: "Donate Monthly Now",
                form: {
                    emailLB: "Email Address",
                    emailPH: "Input your email",
                    passPH: "Input your password",
                    checkLB: "Remember Me",
                    forgot: "Forgot Password?",
                    button: "Login",
                    error: "Your email or password is incorrect. If you don't have an account yet, let's",
                    emptyEmail: "Email cannot be empty.",
                    invalidEmail: "Please enter the email in the correct format.",
                    emptyPass: "Password cannot be empty.",
                },
            },
            forgot: {
                title: "Please input your email",
                p1: "Don't have an account yet?",
                p2: "Let's",
                onboard: "Donate Monthly Now",
                form: {
                    emailLB: "Email Address",
                    emailPH: "Input your email",
                    button: "Submit",
                },
                toast: {
                    success: "We have sent the password reset link.",
                    failed: "Email not registered."
                },
                back: "Back"
            },
            reset: {
                title: "Please input new password",
                p1: "Don't have an account yet?",
                p2: "Let's",
                onboard: "Donate Monthly Now",
                form: {
                    label: {
                        password: "New Password",
                        confirm: "Confirm Password"
                    },
                    placeholder: {
                        password: "Input new password",
                        confirm: "Confirm new password"
                    },
                    button: "Submit"
                },
                back: "Back"
            },
            donationCard: {
                monthly: "Monthly",
                single: "One Time",
                subtitle: "Take care of the future of the Earth, starting from IDR 10,000 per month.",
                other: "Other Amounts",
                note1: "You will donate as much as",
                note2: "per month. Come on, be part of environmental restoration by donating every month.",
                terms: "I agree",
                termsLink: "Privacy Policy and Terms of Conditions",
                button: "Donate Now",
                help: "Help",
                agree: "Check if you want to proceed.",
                amount10: "Amount must be greater than or equal to 10000",
                amount50: "Amount must be greater than or equal to 50000",
                invalid: "Amount must be selected or filled.",
                equivalency: {
                    manage: "Manage",
                    wasteMonthly: "organic waste",
                    wasteSingle: "inorganic waste",
                    monthly: "year",
                    single: "month",
                    descMonthly1: "Equivalence to",
                    descSingle1: "Equivalence to",
                    descMonthly2: "tricycle waste capacity",
                    descSingle2: "sacks of waste"
                }
            },
            landing: {
                tripleten: {
                    tahun: {
                        title: "10 Years",
                        subtitle: "Most Critical for the Environment",
                    },
                    juta: {
                        title: "10 Million",
                        subtitle: "Contribution from Communities",
                    },
                    ribu: {
                        title: "10 Thousands",
                        subtitle: "per Month to Fund Restoration",
                    },
                    description: "We are in a race against time! Despite numerous environmental efforts, the pace of climate change is outstripping our ability to respond. This decade is absolutely crucial. What can we possibly achieve in these next 10 years? Embrace the people around you! We need 10 million people to take simple action by donating IDR 10,000 to carry out environmental actions."
                },
                campaigns: {
                    title: "This is your action for our earth!",
                    subtitle: "Every IDR 10.000 you give become a catalyst for four awe-inspiring and impactful environment restoration actions."
                },
                testiImpact: {
                    title: "Thank You & Congrats! You Restore the Planet!",
                    subtitle: "Wondering on how your donation bring impact on environment and warriors welfare? Hear their voice!"
                },
                steps: {
                    title: "Donation with Ease",
                    content: [
                        {
                            title: "Enter the donation page",
                            subtitle: "You will be served 2 choices of donation on our landing page: Recurring and One-Time Donation. Explore our campaign to witness the impact."
                        },
                        {
                            title: "Choose Recurring Donation",
                            subtitle: "Recurring Donation is more convenient for you since it will auto-debit your bank account. Choose the donation amount. Agree to the privacy policy. Finally, click Donate Now."
                        },
                        {
                            title: "Fill Registration Form",
                            subtitle: "Fill out the registration form completely. Make sure the data you provide is correct."
                        },
                        {
                            title: "Choose Payment Method",
                            subtitle: "Select a payment method. We offer payment options including credit or debit card, e-wallet, virtual account, and QRIS."
                        },
                        {
                            title: "Click Donate Now",
                            subtitle: "After choosing the payment method, click Donate Now!"
                        },
                        {
                            title: "Finish Payment",
                            subtitle: "Next, you will be directed to the payment page. Complete your payment!"
                        },
                        {
                            title: "Congratulations! Donation Success!",
                            subtitle: "If your donation is successful, you will be directed to the payment confirmation page. Check your email and WhatsApp as well!"
                        }
                    ]
                },
                benefits: {
                    title: "Why Recurring Donation is Better for You?",
                    subtitle: "The Earth urgently need your ambitious action to make a real impact. Commit to a recurring donation now!",
                    content_a: "With recurring donation, you can enjoy the full features of Green Fund Digital Philanthropy donations!",
                    content_b: "Earth needs ambitious action! Make a more real impact with a monthly donation!"
                },
                testiDonor: {
                    title: "Hear What They Say!",
                    subtitle: "What's stopping you? Check these experiences shared by Pahlawan Lingkungan who made their contributions to GFDP!"
                },
                mitra: {
                    title: "Initial Partners and Initiators"
                },
                faq: {
                    title: "Frequently Asked Questions (FAQs)",
                    content: [
                        {
                            question: 'Is GFDP authorized to conduct fundraising activities?',
                            answer: <p>GFDP has the authorization of Penyelenggaraan Pengumpulan Uang dan Barang (PUB), the license issued by Indonesia's Ministry of Social Affairs to conduct fundraising activities. Our license number is SK 270/HUK-PS/2022</p>
                        },
                        {
                            question: 'How will you disburse the money that I donate to GFDP?',
                            answer: <p>In line with Indonesia's government regulation, we will allocate 10% of the money for operational activities and donate the rest 90% to program implementation.</p>
                        },
                        {
                            question: 'Will I get the impact report from my donation?',
                            answer: <p>You will receive an impact progress report through a monthly newsletter, addressed to the email that you registered when you made your donation. Furthermore, you can also access the audited financial report that we open for public on our website.</p>
                        },
                        {
                            question: "How is GFDP's accountability process?",
                            answer: <p>We have<br />
                                1. Impact report from each program, sent to our sponsors in a timely manner, and can be accessed on our website<br />
                                2. Annual financial report, audited by our external auditors</p>
                        },
                        {
                            question: 'I have difficulties when donating',
                            answer: <p>We are sorry for the difficulties you are facing! Please contact our team on WhatsApp <a href="https://wa.me/6285173310223">085173310223</a> and/or email <a href="mailto:donorcare@greeneration.org">donorcare@greeneration.org</a></p>
                        },
                        {
                            question: 'I still have other questions',
                            answer: <p>For other questions regarding donation, please contact our team on WhatsApp <a href="https://wa.me/6285173310223">085173310223</a> and email <a href="mailto:donorcare@greeneration.org">donorcare@greeneration.org</a>. For other questions, please contact <a href="mailto:info@greeneration.org">info@greeneration.org</a></p>
                        }
                    ]
                },
                contacts: {
                    title: "Contact Us",
                    subtitle: "Thank you for your interest in GFDP. Should you have further question, please contact us on:"
                }
            },
            campaign: {
                title: "Your Donation Will Provide:",
                desc1: "Beyond this cost, every penny we collect from this program's fundraising will be allocated to other programs that aim to support environmental movements and help boost the welfare of our sustainability heroes. For more information, please contact Email",
                desc2: "or WhatsApp"
            },
            volunteer: {
                title: "Be Fundraiser Buddies",
                subtitle: "Expand the benefits of your contribution by joining Fundraiser Buddies, and save the incentives!",
                backgroundImage: heroVolunteer,
                sectionTitle: "What is Fundraiser Buddies? 🤔",
                sectionDescription: "Your spirit to contribute always inspires us. That's why we establish Fundraiser Buddies, as an individual fundraising volunteering program in every city/regency in Indonesia. With the spirit of togetherness, Buddies will channel the initiatives and values of Green Fund Digital Philanthropy, invite their relatives to adjoin the power of IDR 10.000 per month in creating sustainable solutions for our planet.",
                sectionImage: sectionVolunteer,
                listTitle: "Your kindness shall return to you. Green Fund Digital Philanthropy appreciates your contribution by giving you:",
                list: [
                    "Professional training",
                    "Opportunity to be involved in various programs at GF",
                    "Opportunity to receive incentives every month",
                    "Experience and network that benefits your portfolio and CV",
                    "Opportunity for career development"
                ],
                listImage: listVolunteer,
                listFooter: "Be part of a historical movement for the environment. Join Fundraiser Buddies now!",
                button: "Join Now",
                form: {
                    title: "Volunteer Account Registration",
                    sectionTitle_1: "Volunteer Account",
                    sectionTitle_2: "Personal Data",
                    sectionTitle_3: "Social Media",
                    label: {
                        firstname: "First Name",
                        lastname: "Last Name",
                        email: "Email Address",
                        phone: "Phone Number",
                        sex: "Sex",
                        birth: "Birth Date",
                        province: "Province",
                        city: "City",
                        occupation: "Occupation",
                        institution: "Institution",
                        hobby: "Hobby",
                        source: "How do you know about this?",
                        motivation: "Write your motivation here",
                        confirm: "I agree to take part in training as Fundraiser Buddies for approximately 3 months",
                        instagram: "Instagram"
                    },
                    placeholder: {
                        firstname: "Input your first name",
                        lastname: "Input your last name",
                        email: "Input your email address",
                        phone: "Input your phone number",
                        sex: "Choose your sex",
                        birth: "Choose your birth date",
                        province: "Input your province",
                        city: "Input your city",
                        occupation: "Input your occupation",
                        institution: "Input your institution",
                        hobby: "Input your hobby",
                        source: "Input your affiliation",
                        motivation: "Input your motivation",
                        instagram: "Input your instagram",
                        f: "Female",
                        m: "Male"
                    },
                    note: "*) Whatsapp linked number",
                    button: {
                        signup: "Sign Up",
                        cancel: "Cancel"
                    },
                    invalid: {
                        firstname: "First name cannot be empty.",
                        lastname: "Last name cannot be empty.",
                        emptyEmail: "Email cannot be empty.",
                        invalidEmail: "Please enter the email in the correct format.",
                        phone: "Phone number cannot be empty.",
                        sex: "Please choose your sex.",
                        agree: "Check if you want to proceed."
                    }
                }
            },
            sidebar: {
                donation: {
                    navigation: {
                        home: "Back to Main Page",
                        onboarding: "Back to Onboarding",
                        dashboard: "Back to Dashboard",
                        monthly: "Start Monthly Now"
                    },
                    monthly: {
                        title: "Welcome On Board, Hero!",
                        subtitle: "You're creating impacts by doing recurring donation. Make sure to check this dashboard from time to time to track your progress."
                    },
                    single: {
                        title: "Is Once Enough for You?",
                        subtitle: "Make no mistake! With other 10 millions of Indonesian contributors, every cent from you matters. However, consider to check our Recurring Donation to optimize your contribution, so you can make multiple donation automatically every month by just one click. Forget-it-not!",
                        button: "Start Monthly Now"
                    },
                    boost: {
                        title: "Points Boosted!",
                        subtitle: "By adding your donation, you will also gain additional points. Double the fun, double the impacts for our mother earth!"
                    },
                    recurring: "Your Monthly Donation Amount"
                },
                dashboard: {
                    profile: "Profil",
                    share: "Share Now"
                }
            },
            onboarding: {
                recurring: {
                    title: "Recurring System",
                    subtitle: "Automatically charge donation every month to smooth your experience."
                },
                reminder: {
                    title: "Reminder System",
                    subtitle: "Get monthly donation reminder through email notification and Whatsapp."
                }
            },
            donation: {
                one: {
                    title: "Choose a Donation Amount",
                    amount: "Others",
                    note_1: "You will donate IDR",
                    note_2: ",- to solve Indonesia's environmental problems. Fill your data before donating!"
                },
                two: {
                    title: "Personal Data",
                    label: {
                        name: "Full Name",
                        phone: "Phone Number",
                        email: "Email Address"
                    },
                    placeholder: {
                        name: "Please Input Your Full Name",
                        phone: "Please Input Your Phone Number",
                        email: "Please Input Your Email Address"
                    },
                    invalid: {
                        fullname: "Full name cannot be empty.",
                        emptyEmail: "Email cannot be empty.",
                        invalidEmail: "Please enter the email in the correct format.",
                        phone: "Phone number cannot be empty.",
                        payment: "Payment must be selected."
                    }
                },
                three: {
                    title: "Payment Method"
                },
                four: {
                    title: "Referral Code",
                    note: "Optional",
                    placeholder: "Please Input Your Referral Code"
                },
                button: "Donate Now"
            },
            dashboard: {
                title: "Hi, ",
                logout: "Log out",
                modal: {
                    recurring: {
                        title: "Change the Amount of Monthly Donation",
                        subtitle: "Adjust your contributions according to your needs. Every rupiah matters 😊",
                        cancel: "Cancel",
                        update: "Update",
                        invalid: "Amount must be greater than or equal to 10000"
                    },
                    referral: {
                        title: "Edit Referral Code",
                        subtitle: "Edit the referral code to make it more personal and easy to remember!",
                        warning: "You can only change your referral code once.",
                        cancel: "Cancel",
                        confirm: "Confirm"
                    },
                    social: {
                        title: "Share on your Social Media",
                        subtitle: "Because every act of kindness must be spread 💙"
                    },
                    password: {
                        title: "Update your password",
                        placeholder: "Input your password",
                        confirm: "Confirm",
                        cancel: "Cancel"
                    },
                    badge: {
                        title: "Yay! You got a new badge!",
                        subtitle: "Your kindness needs to be appreciated. Congratulations, you have become",
                        view: "View Badges"
                    }
                },
                achievement: {
                    title: "This Is Your Achievement",
                    locked: "You haven't unlocked the first badge, please update your password to start collecting points.",
                    desc_1: "You have collected",
                    desc_2: "Collect another",
                    desc_3: "to become",
                    desc_completed: "You have collected all of the currently available badges.",
                    badge: "Badges",
                    point: "Points",
                    view: "View all badges"
                },
                profile: {
                    title: "Edit Profile",
                    section: {
                        title_1: "Personal Information",
                        title_2: "Private Information",
                        title_3: "Social Media",
                        title_4: "Other Information",
                        title_5: "Bank Information"
                    },
                    label: {
                        fullname: "Full Name",
                        firstname: "First Name",
                        lastname: "Last Name",
                        phone: "Phone Number",
                        email: "Email Address",
                        address: "Full Address",
                        note: "*) Leave it empty if you do not want to change",
                        password: "New Password",
                        confirm: "Confirm Password",
                        bank: "Bank Name",
                        account: "Account Number",
                        holder: "Account Holder",
                        branch: "Branch Office"
                    },
                    placeholder: {
                        fullname: "Input your full name",
                        firstname: "Input your first name",
                        lastname: "Input your last name",
                        phone: "Input your phone number",
                        email: "Input your email address",
                        address: "Input your full address",
                        password: "Input your new password",
                        confirm: "Confirm your new password",
                        bank: "Input bank name",
                        account: "Input account number",
                        holder: "Input account holder",
                        branch: "Input branch office"
                    },
                    button: {
                        edit: "Edit",
                        cancel: "Cancel",
                        save: "Save Changes"
                    },
                    invalid: {
                        emptyPass: "Password cannot be empty.",
                        unmatchedPass: "Passwords do not match."
                    },
                    toast: {
                        success: "Success!",
                        profileSuccess: "Profile updated.",
                        passwordSuccess: "Password updated.",
                        bankSuccess: "Bank information updated.",
                        error: "Oops!",
                        errorMsg: "Internal server error."
                    }
                },
                donor: {
                    boost: {
                        title: "Add points before the time of donation? Of course you can!",
                        subtitle: "You can boost your points by donating once without waiting for next month's donation.",
                        button: "Boost Now"
                    },
                    share: {
                        title: "Maximize your contribution by sharing!",
                        subtitle: "Invite your friends to join in donating. Copy the following link:",
                        edit: "Edit Link",
                        copy: "Copy Link",
                        social: "Share to Social Media"
                    }
                }
            },
            success: {
                modal: {
                    title: "Share Your Contribution",
                    subtitle1: "We will delight in your kindness 💙",
                    subtitle2: "Share directly to social media? Yes please!",
                    copy: "Copy Link"
                }
            },
            report: {
                title: "Jejak Kebaikan Green Fund Digital Philanthropy",
                subtitle: "1 Tahun Kontribusi Untuk Bumi, Apa Saja yang Sudah Kita Raih Bersama?",
                backgroundImage: heroReport,
                LP1: "Every day, we face the relentless challenges of the environmental crisis: scorching heat, air pollution, wildfires, waste pollution, and floods. But, GFDP refuse to let you, us, or any living being be ensnared in these issues. For the past year, we've rallied together, inviting you to join us in the mission to heal our Earth. Now, let's celebrate the hard work we've put in!",
                LP2: "Please save & download the GFDP Impact Report 2022-2023 here. Preserve, cherish, and share our journey with the other future environmental heroes.",
                button: "Download Report",
                form: {
                    subtitle: "Fill the form to download GFDP Impact Report 2022-2023!",
                    label: {
                        name: "Full Name",
                        phone: "Phone Number",
                        email: "Email Address"
                    },
                    placeholder: {
                        name: "Please Input Your Full Name",
                        phone: "Please Input Your Phone Number",
                        email: "Please Input Your Email Address"
                    },
                    invalid: {
                        name: "Full name cannot be empty.",
                        emptyEmail: "Email cannot be empty.",
                        invalidEmail: "Please enter the email in the correct format.",
                        phone: "Phone number cannot be empty.",
                        agree: "Check if you want to proceed."
                    },
                    error: "Something went wrong. Try again.",
                    check: "By checking this box, I agree to receive newsletter from GFDP."
                },
                toast: {
                    success: "We have sent the download link to your email.",
                    failed: "Something went wrong."
                },
            }
        },
    };

    return translate[lang];
}