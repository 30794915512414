import { useCallback, useEffect, useState } from 'react';
import { contentTranslate, accentSVG } from '../../services';
import style from './volunteer.module.scss';

const VolunteerPage = () => {
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const [mobile, setMobile] = useState(window.innerWidth <= 992);
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    const [wScroll, setWScroll] = useState(0);
    const getContent = contentTranslate(lang);
    const volunteer = getContent.volunteer;
    const getAccent = accentSVG();

    const handleLangChangeEvent = useCallback(() => {
        const newLang = sessionStorage.getItem('gfdp-lang');
        setLang(newLang);
    }, []);

    useEffect(() => {
        const loader = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        if (!isLoading) {
            setTimeout(() => {
                setIsVisible(true);
            }, 100);
        }

        const handleWindowResize = () => {
            setMobile(window.innerWidth <= 992);
        };

        const handleScroll = () => {
            setWScroll(window.scrollY);
        };

        window.scrollTo(0, 0);
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(loader);
        };
    }, [isLoading, handleLangChangeEvent]);

    const bgVolunteer = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${volunteer.backgroundImage}')`,
        transform: mobile ? '' : `translateY(${wScroll * 0.5}px)`
    };

    const parallax = {
        position: 'relative',
        paddingBottom: '24px',
        backgroundColor: 'white'
    };

    const wrapper = {
        position: 'relative',
        overflow: 'hidden'
    };

    const stick = {
        transform: `translateY(${wScroll * -0.5}px)`
    };

    const delay = {
        transform: `translateY(${wScroll * -0.4}px)`
    };

    return (
        <div className={`base-gap ${style.volunteer}`}>
            {isLoading && (
                <div className='spinner-wrapper'>
                    <div className='loading-spinner'></div>
                </div>
            )}

            <section style={mobile ? {} : parallax}>
                <div style={mobile ? {} : wrapper}>
                    <div className={style['hero-wrapper']} style={bgVolunteer}>
                        <span className={style['accent-hero-lg']} style={mobile ? {} : stick}>{getAccent.volunteer.accent}</span>
                        <div className='wide-wrapper'>
                            <div className={style['inner-wrapper']} style={mobile ? {} : delay}>
                                <div className={`w-100 ${style.left}`} style={mobile ? bgVolunteer : {}}>
                                    <span className={style['accent-hero-sm']}>{getAccent.volunteer.accent}</span>
                                    {mobile ? (
                                        <span className={style['title-wrapper']}>
                                            <h1>{volunteer.title}</h1>
                                            <p>{volunteer.subtitle}</p>
                                        </span>
                                    ) : (
                                        <div className={`${style['fade-up']} ${isVisible ? style.active : ''}`}>
                                            {!isLoading && isVisible && (
                                                <span className={style['title-wrapper']}>
                                                    <h1>{volunteer.title}</h1>
                                                    <p>{volunteer.subtitle}</p>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className={style['volunteer-body']}>
                    <div className={style['volunteer-content']}>
                        <div className={style.left}>
                            <span className={style['accent-volunteer']}>{getAccent.landing.impacts.accent}</span>
                            <img className={style.image} src={volunteer.sectionImage} alt='volunteer' loading='lazy' />
                        </div>
                        <div className={style.right}>
                            <div className={style.content}>
                                <h2>{volunteer.sectionTitle}</h2>
                                <p>{volunteer.sectionDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={style['volunteer-benefits']}>
                <div className={style['benefits-wrapper']}>
                    <h2>{volunteer.listTitle}</h2>
                    <ul className={style['benefits-list']}>
                        {volunteer.list.map((benefit, key) => (
                            <li className={style.benefits} key={key}>
                                <span className={style['check-icon']}>{getAccent.campaign.check}</span>
                                {benefit}
                            </li>
                        ))}
                    </ul>
                    <h2>{volunteer.listFooter}</h2>
                    <a href={`${process.env.REACT_APP_BASE_URL}/volunteer/signup`} className={style['button-lg']}>{volunteer.button}</a>
                </div>
            </section>
        </div>
    );
}

export default VolunteerPage;