import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../services';

const RequireAuth = ({ context }) => {
    const { auth } = useAuth();
    const location = useLocation();

    switch (context) {
        case 'dashboard':
            return auth ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
        case 'donation':
            return !auth ? <Outlet /> : <Navigate to="/donation/boost/form" state={{ from: location }} replace />;
        default:
            return null;
    }
}

export default RequireAuth;