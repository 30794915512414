import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { checkJwt } from '../helpers/api-helper';

const TokenContext = createContext();

export function TokenProvider({ children }) {
    const [token, setToken] = useState(null);

    const fetchToken = useCallback(async (signal) => {
        try {
            const jwtToken = await checkJwt(signal);
            setToken(jwtToken);
        } catch (error) {
            console.error('An error occurred:', error.message);
        }
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (token === null) {
            fetchToken(signal);
        }

        return () => {
            controller.abort();
        };
    }, [token, fetchToken]);

    return (
        <TokenContext.Provider value={token}>
            {children}
        </TokenContext.Provider>
    );
}

export function useToken() {
    return useContext(TokenContext);
}