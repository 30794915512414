import { useState, useEffect, useCallback } from 'react';
import { contentTranslate } from '../../../../services';
import style from './footer.module.scss';
import logoGF from '../../../../assets/img/GF-logo-white.png';

const Footer = () => {
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);

    const handleLangChangeEvent = useCallback(() => {
        const newLang = sessionStorage.getItem('gfdp-lang');
        setLang(newLang);
    }, []);

    useEffect(() => {
        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        };
    }, [handleLangChangeEvent]);

    return (
        <footer>
            <div className={style.footer}>
                <div className={style.content}>
                    <div><img src={logoGF} height="64" alt="GF Logo White" loading='lazy' /></div>
                    <p>{getText.footer}</p>
                    <div className={style.socmed}>
                        <a href="https://www.facebook.com/greenerationfoundation" target='_blank' rel='noreferrer' aria-label='Facebook'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.95703 16V8.8H8.14294L8.5 5.6H5.95703V4.04141C5.95703 3.21741 5.97808 2.4 7.12952 2.4H8.29575V0.112109C8.29575 0.0777094 7.29399 0 6.28054 0C4.164 0 2.83872 1.32576 2.83872 3.76016V5.6H0.5V8.8H2.83872V16H5.95703Z" fill="white" /></svg>
                        </a>
                        <a href="https://twitter.com/greenerationid" target='_blank' rel='noreferrer' aria-label='Twitter'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.2175 11.9998C10.8778 11.9998 13.9738 7.38252 13.9738 3.37887C13.9738 3.24744 13.9737 3.11699 13.9647 2.98703C14.567 2.55875 15.0868 2.02699 15.5 1.41855C14.939 1.6637 14.3427 1.82447 13.733 1.89535C14.375 1.51655 14.8558 0.921431 15.086 0.219206C14.4815 0.572164 13.8207 0.821267 13.1315 0.954924C11.966 -0.264928 10.0168 -0.32403 8.777 0.823454C7.97825 1.56334 7.6385 2.66649 7.88675 3.71872C5.4125 3.59615 3.107 2.44573 1.544 0.553191C0.72725 1.9377 1.145 3.70822 2.49725 4.59726C2.0075 4.58323 1.52825 4.45341 1.1 4.2186V4.25705C1.10075 5.69916 2.1335 6.9409 3.569 7.22665C3.116 7.34849 2.6405 7.36616 2.18 7.27829C2.58275 8.51291 3.7385 9.35854 5.05475 9.3829C3.965 10.2262 2.61875 10.6841 1.23275 10.6826C0.98825 10.6818 0.74375 10.6678 0.5 10.639C1.90775 11.528 3.545 11.9999 5.2175 11.9976" fill="white" /></svg>
                        </a>
                        <a href="https://www.youtube.com/greenerationfoundation" target='_blank' rel='noreferrer' aria-label='Youtube'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.48844 9.58612V3.9743C10.4806 4.91173 12.0236 5.81731 13.8485 6.79364C12.3433 7.62835 10.4806 8.56492 8.48844 9.58612ZM19.591 1.18328C19.2474 0.730524 18.6617 0.378089 18.0381 0.261409C16.2053 -0.0866411 4.77099 -0.0876311 2.93915 0.261409C2.43911 0.355149 1.99384 0.581732 1.61133 0.933772C-0.000362515 2.42967 0.504666 10.4517 0.893147 11.7512C1.05651 12.3136 1.26769 12.7193 1.53365 12.9855C1.87631 13.3376 2.34547 13.58 2.88436 13.6887C4.39345 14.0008 12.168 14.1753 18.0062 13.7355C18.5441 13.6418 19.0202 13.3916 19.3958 13.0246C20.8859 11.5347 20.7843 3.06213 19.591 1.18328Z" fill="white" /></svg>
                        </a>
                        <a href="https://www.instagram.com/greenerationid" target='_blank' rel='noreferrer' aria-label='Instagram'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.19572 0.0981833C3.89342 0.156943 2.76465 0.475341 1.8532 1.38295C0.938556 2.29532 0.624113 3.42856 0.565207 4.71732C0.528589 5.52171 0.314469 11.5986 0.935373 13.1923C1.35408 14.2675 2.17875 15.0941 3.26374 15.5142C3.77002 15.7111 4.34795 15.8444 5.19572 15.8833C12.2844 16.2041 14.9121 16.0295 16.0201 13.1923C16.2168 12.6873 16.3521 12.1099 16.3895 11.2642C16.7135 4.15737 16.337 2.61692 15.1015 1.38295C14.1216 0.405461 12.969 -0.259937 5.19572 0.0981833ZM5.26098 14.454C4.48485 14.4191 4.06376 14.2898 3.78276 14.181C3.07589 13.9062 2.54493 13.3775 2.2719 12.6747C1.79905 11.4638 1.95588 5.71242 1.99807 4.78178C2.03946 3.87019 2.22414 3.03707 2.86733 2.39389C3.66336 1.59982 4.69182 1.21066 11.6945 1.5267C12.6083 1.56799 13.4434 1.75229 14.0882 2.39389C14.8842 3.18794 15.279 4.22432 14.9574 11.2001C14.9224 11.9743 14.7927 12.3944 14.6836 12.6747C13.9632 14.5209 12.3059 14.7772 5.26098 14.454ZM11.7717 3.7517C11.7717 4.27737 12.1992 4.70482 12.727 4.70482C13.2547 4.70482 13.683 4.27737 13.683 3.7517C13.683 3.22603 13.2547 2.79896 12.727 2.79896C12.1992 2.79896 11.7717 3.22603 11.7717 3.7517ZM4.39013 7.99037C4.39013 10.2423 6.22021 12.0681 8.47776 12.0681C10.7353 12.0681 12.5654 10.2423 12.5654 7.99037C12.5654 5.73842 10.7353 3.91381 8.47776 3.91381C6.22021 3.91381 4.39013 5.73842 4.39013 7.99037ZM5.82458 7.99037C5.82458 6.52931 7.01226 5.34389 8.47776 5.34389C9.94325 5.34389 11.1309 6.52931 11.1309 7.99037C11.1309 9.45223 9.94325 10.638 8.47776 10.638C7.01226 10.638 5.82458 9.45223 5.82458 7.99037Z" fill="white" /></svg>
                        </a>
                        <a href="https://www.linkedin.com/company/greeneration-foundation" target='_blank' rel='noreferrer' aria-label='LinkedIn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M16.5 16H13.3V10.4008C13.3 8.86478 12.6224 8.00781 11.4072 8.00781C10.0848 8.00781 9.3 8.90078 9.3 10.4008V16H6.1V5.6H9.3V6.76953C9.3 6.76953 10.304 5.00781 12.5664 5.00781C14.8296 5.00781 16.5 6.38888 16.5 9.24648V16ZM2.4536 3.93672C1.3744 3.93672 0.5 3.05517 0.5 1.96797C0.5 0.881569 1.3744 0 2.4536 0C3.532 0 4.4064 0.881569 4.4064 1.96797C4.4072 3.05517 3.532 3.93672 2.4536 3.93672ZM0.5 16H4.5V5.6H0.5V16Z" fill="white" /></svg>
                        </a>
                    </div>
                </div>
                <span className={style.line}></span>
                <div className={style.copy}>Yayasan Greeneration Indonesia © 2023 | All Rights Reserved, Designed By Yayasan Greeneration Indonesia</div>
            </div>
        </footer>
    )
}

export default Footer;