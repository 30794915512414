import { useState } from 'react';
import { accentSVG, contentTranslate, shortenUrl } from '../../../../services';
import { ButtonPrimary, Input } from '..';
import clipboardCopy from 'clipboard-copy';
import Modal from 'react-bootstrap/Modal';
import style from './modal.module.scss';

const SuccessModal = (props) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const lang = sessionStorage.getItem('gfdp-lang');
    const getLocal = sessionStorage.getItem('user-donation');
    const getText = contentTranslate(lang);
    const text = getText.success.modal;
    const getSVG = accentSVG();
    const link = `${baseUrl}/donation/monthly?ref=${getLocal ? getLocal.referral_code : ''}`;
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = (string) => {
        clipboardCopy(string).then(() => {
            setIsCopied(true);
        }).catch((err) => {
            setIsCopied(false);
            console.error('Copy failed: ', err);
        });
    };

    const handleCopy = () => {
        copyToClipboard(link);
        setTimeout(() => setIsCopied(false), 1500);
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={`${style.modal} ${style.success}`}>
                <Modal.Header className={style.header} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {text.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={style.body}>
                    <p>{text.subtitle1}</p>

                    <div className={style['input-group']}>
                        <div className="input-group">
                            <Input type="text" id="referral-read" className={`form-control`} value={shortenUrl(link)} readOnly={true} disabled={true} />
                            <ButtonPrimary className={style.copy} onClick={handleCopy}>
                                {isCopied ? (
                                    <div className={style.container}>
                                        <figure className={style.checkmark}></figure>
                                    </div>
                                ) : (
                                    <>{text.copy}</>
                                )}
                            </ButtonPrimary>
                        </div>
                    </div>

                    <p>{text.subtitle2}</p>

                    <div className={style.social}>
                        <a href={`https://twitter.com/intent/tweet?text=${link}`} target='_blank' rel='noreferrer' className={style.media}>{getSVG.donor.twitter}</a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} target='_blank' rel='noreferrer' className={style.media}>{getSVG.donor.facebook}</a>
                        <a href={`whatsapp://send?text=${link}`} target='_blank' rel='noreferrer' className={style.media}>{getSVG.donor.whatsapp}</a>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default SuccessModal;