import style from './text-field.module.scss';

const Check = ({ id, name, value, onChange, className, checked }) => {
    return (
        <input
            id={id}
            name={name}
            type="checkbox"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={`${style['custom-check']} ${className}`}
            checked={checked}
        />
    );
};

export default Check;