import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { contentTranslate } from '../../../../services';
import Modal from 'react-bootstrap/Modal';
import style from './modal.module.scss';
import { ButtonPrimary } from '..';

const WelcomeModal = (props) => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const text = getText.sidebar.donation;

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        }
    }, []);

    const clean = (value) => {
        return value.split('/')[0];
    };

    const handleMonthly = () => {
        navigate('/donation/monthly');
    };

    return (
        <Modal
            {...props}
            fullscreen={true}
            dialogClassName={style['welcome-dialog']}
            aria-labelledby="contained-modal-fullscreen"
        >
            <div className={`${style.modal} ${style.welcome}`}>
                <Modal.Header className={style.header} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {text[clean(props.type)].title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={style.body}>
                    {text[clean(props.type)].subtitle}
                </Modal.Body>
                {clean(props.type) === 'single' && (
                    <Modal.Footer className={style.footer}>
                        <ButtonPrimary onClick={handleMonthly}>{text[clean(props.type)].button}</ButtonPrimary>
                    </Modal.Footer>
                )}
            </div>
        </Modal>
    );
};

export default WelcomeModal;