import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { accentSVG, contentTranslate } from '../../../../services';
import { updatePassword } from '../../../users';
import { ButtonGrey, ButtonPrimary, Input } from '..';
import Modal from 'react-bootstrap/Modal';
import style from './modal.module.scss';

const PasswordModal = (props) => {
    const navigate = useNavigate();
    const [lang, setLang] = useState(sessionStorage.getItem('gfdp-lang'));
    const getText = contentTranslate(lang);
    const getSVG = accentSVG();
    const text = getText.dashboard.modal.password;
    const [password, setPassword] = useState('');
    const [isPassValid, setIsPassValid] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleLangChangeEvent = () => {
            const newLang = sessionStorage.getItem('gfdp-lang');
            setLang(newLang);
        };

        window.addEventListener('gfdp-lang-change', handleLangChangeEvent);
        return () => {
            window.removeEventListener('gfdp-lang-change', handleLangChangeEvent);
        }
    }, []);

    const validatePassword = (password) => {
        return password.length >= 1;
    };

    const handlePassword = (event) => {
        const newPass = event.target.value;
        if (newPass === '') {
            setIsPassValid(false);
            return;
        }
        setPassword(newPass);
        setIsPassValid(validatePassword(newPass));
    };

    const handleInvalidInputs = () => {
        if (isPassValid === null || isPassValid === false) {
            setIsPassValid(false);
        }
    };

    const handleFailedUpdate = () => {
        setPassword('');
    };

    const handleSuccessfulUpdate = () => {
        setPassword('');
    };

    const dispatchEvent = () => {
        const passUpdateEvent = new Event('password-update');
        window.dispatchEvent(passUpdateEvent);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isPassValid) {
            handleInvalidInputs();
            return;
        }
        setIsLoading(true);
        try {
            const success = await updatePassword(password);
            if (success) {
                handleSuccessfulUpdate();
                dispatchEvent();
                props.onHide();
                return;
            }
            handleFailedUpdate();
        } catch (error) {
            navigate('/oops/500', { replace: true });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setPassword('');
        setIsPassValid(null);
        setVisible(false);
        props.onHide();
    };

    const handleVisibility = () => {
        setVisible(!visible);
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={`${style.modal} ${style.password}`}>
                <Modal.Header className={style.header} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {text.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={style.body}>
                    <div className={style['input-group']}>
                        <label className="form-label" htmlFor="password">Password</label>
                        <Input
                            id="password"
                            name="password"
                            type={visible ? 'text' : 'password'}
                            value={password}
                            className={`form-control custom-input ${isPassValid === false ? 'is-invalid' : ''}`}
                            placeholder={text.placeholder}
                            onChange={handlePassword}
                        />
                        <span className={`${style.visibility} ${isPassValid || isPassValid === null ? '' : style.hidden}`} onClick={handleVisibility}>
                            {visible ? getSVG.login.show : getSVG.login.hide}
                        </span>
                        <span className="invalid-feedback">{getText.login.form.emptyPass}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer className={style.footer}>
                    <ButtonGrey onClick={handleCancel}>{text.cancel}</ButtonGrey>
                    <ButtonPrimary onClick={handleSubmit} className={`${isLoading ? 'temp-disabled' : ''}`}>
                        {isLoading ? (
                            <div className={style.load}>
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            text.confirm
                        )}
                    </ButtonPrimary>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default PasswordModal;