import style from './button.module.scss';

const Grey = ({ type, children, onClick, className }) => {
    return (
        <button type={type} className={`${style.grey} ${className}`} onClick={onClick}>
            {children}
        </button>
    );
};

export default Grey;