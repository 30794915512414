import { checkJwt } from '../../../services';

export async function submitForgot(email) {
    const token = await checkJwt();
    const formRequest = {
        email: email
    };
    
    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/oauth/forgot`, {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: new URLSearchParams(formRequest)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const result = responseData.result.length > 0;
        
        return result;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export async function resetPassword(password, reqtoken) {
    const token = await checkJwt();
    const formRequest = {
        password: password,
        token: reqtoken
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/oauth/reset`, {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: new URLSearchParams(formRequest)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const result = responseData.result.length > 0;

        return result;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}

export async function checkToken(value) {
    const token = await checkJwt();
    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/v1/oauth/token?token=${value}`, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        
        return responseData.result;
    } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
            console.error('An error occurred:', error.message);
        } else {
            window.location.href = '/oops/500';
        }
    }
}